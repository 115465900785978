<template>
  <el-table
      :data="tableData"
      style="width: 100%;"
      :cell-style="cellStyle"
      :header-cell-style="headStyle"
      :height="height"
      v-loading="loading"
      @row-click="handleRowClick"
      :style="{'--colorVar': color}"
      class="table-wrapper"
      :show-header="showHeader"
      :row-class-name="tableRowClassName"
      ref="HlTable"
  >
      <el-table-column
          v-for="(item, index) in column"
          :key="index"
          :prop="item.prop"
          :label="item.label"
          :header-align="item.headerAlign"
          :align="item.align"
          :width="item.width"
          :sortable="item.sort"
      >
        <template slot-scope="{ row }">
          <slot :name="item.name" :row="row">
            {{ row[item.name] }}
          </slot>
        </template>
      </el-table-column>
  </el-table>
</template>

<script>
export default {
  name: "HlTable",
  props: {
    // 表格数据
    tableData:{
      type: Array,
      default: () => []
    },
    // 表格列
    column: {
      type: Array,
      default: () => []
    },
    // 表格主体样式
    cellStyle: {
      type: function (){},
    },
    // 表格表头样式
    headStyle:{
      type: function (){},
    },
    rowClass:{
      type: function (){},
    },
    // 表格loading状态
    loading: {
      type: Boolean,
    },
    // 设置表格部分区域颜色
    color: {
      type: String,
      default:'#862e2e'
    },
    // 设置表格高度
    height:{
      type:String,
    },
    // 显示表头
    showHeader:{
      type:Boolean,
      default: true
    },
    myRowClass:{
      type:Boolean,
      default: false
    }
  },
  data(){
    return{
    }
  },
  methods:{
     handleRowClick(row){
        this.$emit('row-click', row);
     },
    tableRowClassName({row, rowIndex}) {
       if (!this.myRowClass){
         return;
       }
      if (rowIndex === 1) {
        return 'warning-row';
      }
      return '';
    }
  },
  mounted() {
  }
}
</script>

<style  scoped>
/* 表格 */
.el-table{
  border-bottom-right-radius: 5px;
  background: transparent;
}
/* 过度效果 */
/deep/ .el-table--enable-row-transition .el-table__body td{
  transition: none;
}
/* 表头出现的块元素 */
/deep/ .gutter{
  background: var(--colorVar);
  /*background: rgba(23,38,76,0.89);*/
}


/* 滚动条 */
/deep/ .el-table__body-wrapper{
  /*background: var(--colorVar);*/
  /*background: transparent;*/
  /*overflow-y: scroll!important;*/
}
/* 滚动条xy交叉圆角 */
/deep/.el-table__body-wrapper::-webkit-scrollbar-corner{
  background: var(--colorVar);
}
/* 滚动条的宽高*/
 /deep/ .el-table__body-wrapper::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
/* 设置竖向滚动条隐藏 */
 /deep/ .el-table__body-wrapper::-webkit-scrollbar:vertical{
   display: none;
 }
/* 滚动条的颜色以及圆角*/
/deep/.el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #a1a3a9;
  border-radius: 2px;
}
/* 设置底部border不显示 */
.el-table--border::after, .el-table--group::after, .el-table::before{
  background: transparent;
}
/deep/.el-table__body tr td{
  cursor: pointer;
}
/deep/.el-table__body tr:hover{
  background: var(--colorVar)!important;
}


</style>
<style>
/**/
.el-table .warning-row {
  background: #f0f9eb4D!important;
}


</style>
