<template>
  <div class="voice">
    <!--  muted静音 google和firefox不支持浏览器自动播放音频视频   -->
    <!--  需要用户手动开启   -->
    <audio ref="audio" id="audio" muted>
      <source src="../../audio/4031.mp3" id="audio2" type="audio/mpeg">
    </audio>
    <img src="@/images/voice.png" alt="" v-show="isShow" @click="closeVoice">
    <img src="@/images/noVoice.png" alt="" v-show="!isShow"  @click="closeVoice">
  </div>

</template>

<script>
import bus from "@/utils/eventBus/bus";

export default {
  name: "AlarmTone",
  data(){
    return{
      isShow: false,
      // audio: '../audio/4031.mp3'
    }
  },

  methods:{
    play(){
      return this.$refs.audio.play();
    },
    pause(){
      return this.$refs.audio.pause();
    },
    // 静音
    closeVoice(){
      this.isShow = !this.isShow
    }
  },
  created() {

  },
  mounted() {
    console.log("==alarmTone==执行mounted")
    let promise = this.$refs.audio.play();
    this.pause();
    if (promise !== undefined){
      promise.then( () => {
        this.isShow = true;
        // console.log("浏览器禁止自动播放音频")
      }).catch(e => {
        this.isShow = true;
        console.log("发生错误", e)
      })
    }
    console.log(promise)
    bus.$on('alarmPlay', () => {
      // 非静音状态下播放
      if (this.isShow){
        this.play();
      }
    })
    bus.$on('openVideoDialog', () => {
      // 非静音状态下播放
      if (this.isShow){
        this.play();
      }
    })
    bus.$on('tonePlay', () => {
      // 非静音状态下播放
      if (this.isShow){
        this.play();
      }
    })
  }
}
</script>

<style scoped>
.voice{
  margin: 10px auto;
}
img{
  width: 32px;
  height: 32px;
  cursor: pointer;
}

</style>
