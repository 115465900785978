import * as echarts from 'echarts/core';
import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent,
    ToolboxComponent,
} from 'echarts/components';
import 'echarts/lib/component/dataZoom'
import {
    PieChart,
    BarChart,
    GaugeChart,
    LineChart
} from 'echarts/charts';
import {
    CanvasRenderer
} from 'echarts/renderers';

echarts.use(
    [
        GridComponent, TitleComponent, TooltipComponent, LegendComponent, ToolboxComponent,
        PieChart, BarChart, LineChart, GaugeChart,
        CanvasRenderer,
    ]
);
export default echarts;
