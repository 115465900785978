<template>
  <el-row class="device-detail comm-bg" v-show="this.deviceDetailsShow">

    <div class="comm-title-container" style="z-index:2;" :data-height="detailCardHeight">
      <div :class="['btn', selectedIndex == 1 ? 'btn-1' : 'btn-2']" @click="btnClick(1)">设备信息</div>
      <div :class="['btn', selectedIndex == 2 ? 'btn-1' : 'btn-2']" @click="btnClick(2)">实时数据</div>
      <div :class="['btn', selectedIndex == 3 ? 'btn-1' : 'btn-2']" @click="btnClick(3)">趋势图</div>
      <div :class="['btn', selectedIndex == 4 ? 'btn-1' : 'btn-2']" @click="btnClick(4)">告警记录</div>
      <div class="hidden-btn"  @click="hidden">
        <i class="el-icon-arrow-right"></i>
      </div>
    </div>

    <img src="../../../../../images/comm-shining.png" class="comm-shining-img">

    <keep-alive>
      <device-detail-info v-if="selectedIndex == 1" ></device-detail-info>

      <device-detail-realtime-data v-if="selectedIndex == 2"></device-detail-realtime-data>

      <device-detail-chart v-if="selectedIndex == 3" ></device-detail-chart>

      <device-detail-alarm v-if="selectedIndex == 4"></device-detail-alarm>
    </keep-alive>



  </el-row>


</template>

<script>
import DeviceDetailInfo from './DeviceDetailInfo'
import DeviceDetailRealtimeData from './DeviceDetailRealtimeData'
import DeviceDetailChart from './DeviceDetailChart'
import DeviceDetailAlarm from './DeviceDetailAlarm'
import {getDeviceById, getDeviceSensor, getDeviceSensorHistory} from '@/api/impl/device'
import {getAlarm} from '@/api/impl/alarm'
import {axiosServer} from "@/api/judge";
import {apis} from "@/api/urls";
import {parseClientState, parseDeviceStatus, parseSensorStatus, parseOneLine} from "@/utils/device/DetectorValueParser";

export default {
  name: 'DeviceDetail',
  components: {
    DeviceDetailInfo, DeviceDetailRealtimeData, DeviceDetailChart, DeviceDetailAlarm
  },
  props: {
    msg: String
  },
  data() {
    return {
      selectedIndex: 1,
      // // id
      // id: 11954,
      // 设备信息
      deviceInfo: {
        deviceName: ''
      },
      realtimeData: {},
      // 传感器信息
      sensorRtData: [],
      // 定时器 实时获取设备状态
      isStartAInterval: false,
      isNeedGetRealDataFlag: true,
      // 趋势图
      deviceSensor: [],
      sensorHistory: [],
      detailCardHeight: '126px'
    }
  },
  computed:{
    deviceDetailsShow(){
      return this.$store.getters.deviceDetailsShow;
    },
    deviceId(){
      return this.$store.getters.deviceId;
    }
  },
  created() {
  },
  mounted(){

  },
  watch:{
    deviceId: {
      handler(newVal){
        // this.deviceInfoSelect(newVal);
        // this.request(newVal);
      }
    }
  },
  methods: {
    btnClick(index) {
      this.selectedIndex = index
    },
    hidden(){
      this.$store.commit('SET_DEVICE_DETAILS_SHOW', false)
    }
  }
}
</script>

<style scoped>
.comm-bg{
  width: 24%!important;
}
.arrow-down {
  float: right;
  padding: 10px;
  border-radius: 10px;
  /*background: rgba(255, 255, 255, 0.05);*/
}

.device-detail-content-expend {
  width: 100%;
  height: 440px;
  padding: 16px 22px;
  font-size: 14px;
  color: #fff;
  opacity: 0.9;
  text-align: left;
  position: relative;
}
.device-detail-content-no-expend {
  width: 100%;
  height: 106px;
  padding: 16px 22px;
  font-size: 14px;
  color: #fff;
  opacity: 0.9;
  text-align: left;
  position: relative;
}
/*按钮*/
.btn{
  width: 88px;
  height: 32px;
  opacity: 1;
  background: #5699ff;
  border-radius: 4px;
  line-height: 32px;
  text-align: center;
  margin-right: 15px;
  cursor: pointer;
  margin-top: 16px;
  font-size: 16px;
  overflow:hidden;
  /*text-overflow:ellipsis;*/
  white-space: nowrap;
}
.btn-1 {
  letter-spacing: 1px;
  background: rgba(86, 153, 255, 0.7) !important;
  color: #ffffff !important;
  border: 0px;
}

.btn-2 {
  letter-spacing: 1px;
  background: rgba(255, 255, 255, 0.09) !important;
  border: 0px;
  color: #ffffff !important;
}

.device-detail {
  width: 500px;
  position: fixed;
  margin-left: 15px;
  left: 50%;
  top: 85px;
  z-index: 5;
}

.comm-title-container{
  display: flex;
  /*align-items: center;*/
  height: 56px;
  padding-left: 24px;

}
.comm-shining-img{
  /* 鼠标穿透属性 让按钮可点击*/
  margin-top: -112px;
  margin-left: -247px;
  pointer-events: none;
}

.hidden-btn{
  width: 18px;
  height: 11px;
  font-size: 16px;
  margin-top: 10px;
  cursor: pointer;
  color: #fff;
}
</style>
