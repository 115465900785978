const baseUrl = {
  BASE_API: '',
  BASE_API_old: '',
}

switch (process.env.NODE_ENV) {
  // case 'development':
  //   baseUrl.BASE_API = 'http://192.168.1.105:8100/iot-dd'
  //   baseUrl.WS_URL = 'ws://192.168.1.105:9090/WebSocket/User'
  //   break
  case 'development':
    baseUrl.BASE_API = 'http://localhost:8090/renren-fast'
    baseUrl.WS_URL = 'ws://localhost:8055/WebSocket/alarm'
    // baseUrl.WS_URL= 'wss://v2.jckjcn.com/WebSocket/alarm'
    break
  case 'production':
    baseUrl.BASE_API = 'https://v2.jckjcn.com/renren-fast'
    baseUrl.WS_URL= 'wss://v2.jckjcn.com/WebSocket/alarm'
    break
}

export default baseUrl
