import { render, staticRenderFns } from "./BaiduMapvglShow.vue?vue&type=template&id=671281c9&scoped=true&"
import script from "./BaiduMapvglShow.vue?vue&type=script&lang=js&"
export * from "./BaiduMapvglShow.vue?vue&type=script&lang=js&"
import style0 from "./BaiduMapvglShow.vue?vue&type=style&index=0&id=671281c9&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "671281c9",
  null
  
)

export default component.exports