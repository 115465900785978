<template>
    <div :class="[expand ? 'device-detail-content-expend': 'device-detail-content-no-expend']">
      <div v-show="!expand" style="font-size: 16px;">展开显示趋势图</div>
      <div id="mycharts" ref="myRef" class="chart-box" v-show="expand" />
      <div class="arrow">
        <img v-if="expand" src="../../../../../images/arrow-up.png" @click="arrowClick()" class="arrow-down"/>
        <img v-else src="../../../../../images/arrow-down.png" @click="arrowClick()" class="arrow-down"/>
      </div>
    </div>
</template>

<script>
// import { defineComponent, getCurrentInstance, ref, onMounted, inject } from 'vue';

const lineColors = [
    'red',
    'orange',
    '#00C78C',
    '#33A1C9',
    '#3D59AB',
    '#F4A460',
    '#D2B48C',
    '#F0E68C',
    '#D2691E',
    '#8A360F',
    '#FFD700',
    '#4169E1',
    '#6A5ACD',
    '#87CEEB',
    '#00FFFF',
    '#385E0F',
    '#00FF00',
    '#A020F0',
    '#DA70D6',
  ]

export default({
  name: 'DeviceDetailChart',
  props: {
    msg: String,
    // datas: Array,
    // deviceSensor: Array
  },
  data(){
    return{
      option: {
        backgroundColor: 'rgba(255,255,225,0.0)',
        toolbox: {
          show:false,
          feature: {
            dataZoom: {
              yAxisIndex: 'none',
            },
            restore: {},
            saveAsImage: {},
          },
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            textStyle: {
              color: '#fff',
            },

          },
        },
        grid: {
          borderWidth: 1,
          top: '40px',
          left: '0px',
          right: '0px',
          bottom: '0px',
          textStyle: {
            color: 'white',
          },
        },
        color: lineColors,
        legend: {
          textStyle: {
            color: '#fff',
            fontSize:10,
          },
          icon:'circle',
          data: [],
          // type: 'scroll',
          right: 0,
        },

        calculable: true,
        xAxis: [{
          type: 'category',
          axisLine: {
            lineStyle: {
              // color: 'rgba(204,187,225,1)',
            },
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          data: [],
        }],

        yAxis: [{
          type: 'value',
          scale: true,
          splitLine: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(0,0,0,0)',
            },
          },
        }],
        // dataZoom: [],
        dataZoom: [{
          type: 'inside',
          start: 0,
          end: 20000,
        }],
        series: [],
      },
      myChart: null
    }
  },
  computed:{
    datas(){
      return this.$store.getters.sensorHistory
    },
    deviceSensor(){
      return this.$store.getters.deviceSensor
    },
    expand(){
      return this.$store.getters.expand;
    }
  },
  methods:{
    init(domName, option){
      let myChart = this.$echarts.init(document.getElementById(domName));
      myChart.setOption(option, true);
      return myChart;
    },
    initData(){
      let seriesDatas = []

      this.deviceSensor.forEach((item) => {
        let oneSensorData = {sensorName:item.sensorName, unit:item.unit, looperNum:item.looperNum,data:[], time:""}
        seriesDatas.push(oneSensorData)
      });

      this.datas.forEach((item)=>{
        seriesDatas.forEach((seriesItem) => {
          if(!seriesDatas.time){
            seriesItem.time = item.statusTime
          }
          seriesItem.data.push(item["line" + seriesItem.looperNum+"Value"])
        })
      })

      seriesDatas.forEach((item,index) => {
        const seriesData = {}
        seriesData.data = item.data.map(a => a)
        seriesData.name = item.sensorName
        seriesData.type = 'line'
        seriesData.showSymbol = false
        seriesData.clip = true
        this.option.series[index] = seriesData
        this.option.series[index].smooth = true
        this.option.legend.data[index] = item.sensorName
        seriesData.itemStyle = {
          normal: {
            lineStyle: {
              color: lineColors[index],
              width: 1,
              type: 'solid',
              shadowColor: lineColors[index], // 默认透明
              shadowBlur: 5,
              shadowOffsetX: 5,
              shadowOffsetY: 5,
              normal: {
                color: lineColors[index],
              },
            },
          },
        }
      })

      this.option.xAxis[0].data = this.datas.map(a => a.statusTime)
    },
    arrowClick() {
      this.$store.commit('SET_EXPAND', !this.expand)
    },

  },
  mounted(){

  },
  watch:{
    // 监听趋势图最后返回的sensorHistory数据 如果监听id或者deviceSensor的话 数据可能还有延迟
    '$store.getters.sensorHistory': {
       handler(){
         console.log(this.$echarts)
         // 清空配置
         this.option.series = [];
         this.option.legend.data = [];
         this.option.xAxis[0].data = [];
         this.$echarts.dispose(this.myChart);
         this.initData();
         this.myChart = this.init('mycharts',this.option);
      }
    }
  },
   activated() {
    this.initData();
    this.myChart = this.init('mycharts',this.option);
    console.log('activated')
  },
  deactivated() {
    this.$echarts.dispose(this.myChart);
    console.log('deactivated')
  },
})
</script>

<style scoped>

.chart-box{
  width: 410px;
  height: 380px;
}
.arrow{
  width: calc(100% - 44px);
  height: 20px;
  display: flex;
  position: absolute;
  bottom: 10px;
  left: 22px;
  justify-content: flex-end;
}
.arrow img{
  width: 18px;
  height: 10px;
  opacity: 1;
  display: block; margin-top: 5px;
}
.device-detail-content-no-expend{
  display: flex;
  align-items: center;
}
</style>
