import {getSystemSettingDetails} from "@/api/impl/sys";
import logoImg from "@/images/companyIcon.png";
const user = {
    state: {
        // 控制台信息
        settingInfo:{
            logo: '',
            regionName: '',
            cmsName: '',
            lan: 0,
            lng: 0,
            phone: null,
        },
        commonSetting: {
            offlineShow: true
        }
    },
    mutations: {
        // 设置控制台信息
        SET_SETTING_INFO(state, payload){
            state.settingInfo = payload
        },
        SET_COMMON_SETTING(state, payload){
            state.commonSetting = payload
        },
    },
    actions: {
        GET_SYSTEM_SETTING(context, payload){
            getSystemSettingDetails(payload).then(res => {
                console.log('getSystemSettingDetails', res)
                if (res.code == 0){
                    if (res.page.list.length != 0){
                        localStorage.setItem('id', res.page.list[0].id)
                        context.commit('SET_SETTING_INFO', res.page.list[0])
                    }else {
                        let obj = {
                            logo: logoImg,
                            regionName: '将臣科技公司',
                            cmsName: '智慧消防管理云平台'
                        }
                        context.commit('SET_SETTING_INFO', obj)
                    }
                }

            })
        },
    }
}
export default user
