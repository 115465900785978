const normalColor = '#009ab3'
const faultColor = '#f57d45'
const alarmColor = 'red'
const dropline = 'grey'
const unknown = '#746550'
const sleep = '#2283e0'

/**
 * 设备状态颜色方法
 */
export function getDeviceStateColor(status) {
	let color = normalColor
	switch (status) {
		case 1:
			color = '#fff'
			break
		case 2:
			color = '#fff'
			break
		case 3:
			color = faultColor
			break
		case 4:
			color = alarmColor
			break
		case 5:
			color = sleep
			break
		default:
			color = unknown
			break
	}
	return color
}

export function getDeviceSbztColor(status){
	let color = '#fff';
	switch (status) {
		case 1:
			color = '#4FC164'
			break
		case 2:
			color = '#99B0EF'
			break
		case 3:
			color = '#FF8256'
			break
		case 4:
			color = '#D81E06'
			break
		case 5:
			color = '#5699FF'
			break
		default:
			color = '#949494'
			break
	}
	return color
}

/**
 * 传感器颜色方法
 */
export function getSensorColor(status) {
	switch (status) {
		case 0:
			return normalColor
		case 1:
			return alarmColor
		case 2:
			return faultColor
		case 3:
			return alarmColor
		case 4:
			return dropline
		case 5:
			return sleep
		default:
			return dropline
	}
}

export function getSensorColorNew(status) {
	switch (status) {
		case 0:
			return '#fff'
		case 1:
			return alarmColor
		case 2:
			return faultColor
		default:
			return dropline
	}
}

/**
 * 传感器的类型
 */
export const SensorType = {
	DL: 1,
	WD: 2,
	SYDL: 3,
	DY: 4
}

export function getSensorName(type) {
	switch (type) {
		case SensorType.DL:
			return '电流传感器'
		case SensorType.DY:
			return '电压传感器'
		case SensorType.SYDL:
			return '剩余电流传感器'
		case SensorType.WD:
			return '温度传感器'
	}
}

export const sensors = [
	{
		value: SensorType.DL,
		label: getSensorName(SensorType.DL)
	},
	{
		value: SensorType.DY,
		label: getSensorName(SensorType.DY)
	},
	{
		value: SensorType.SYDL,
		label: getSensorName(SensorType.SYDL)
	},
	{
		value: SensorType.WD,
		label: getSensorName(SensorType.WD)
	}
]

export const CommonUseStatus = [
	{
		value: 1,
		label: '在用'
	},
	{
		value: 2,
		label: '停用'
	}
]

/**
 * 状态
 */
export const SensorStatus = {
	ZY: 1,
	TY: 2
}

export function getSensorName2(status) {
	switch (status) {
		case SensorStatus.ZY:
			return '在用'
		case SensorStatus.TY:
			return '停用'
	}
}

/**
 * 警告等级
 */
export const warnLevel = {
	PTYI: 1,
	ZDGJ: 2,
	YCBD: 3
}

export function getwarnLevelName(Level) {
	switch (Level) {
		case warnLevel.PTYI:
			return '平台预警'
		case warnLevel.ZDGJ:
			return '主动告警'
		case warnLevel.YCBD:
			return '异常波动'
	}
}

export const CommonwarnLevel = [
	{
		value: 2,
		label: '主动告警'
	},
	{
		value: 1,
		label: '平台预警'
	},
	{
		value: 3,
		label: '异常波动'
	}
]
/**
 * 波动方式
 */
export const waveMode = {
	ASL: 1,
	ABL: 2
}

export function getwarnLevelMode(Mode) {
	switch (Mode) {
		case waveMode.ASL:
			return '按数量'
		case waveMode.ABL:
			return '按比例'
	}
}

export const CommonwarnMode = [
	{
		value: 1,
		label: '按数量'
	},
	{
		value: 2,
		label: '按比例'
	}
]

/**
 * 型号状态
 */
export const modelStatus = {
	ZY: 1,
	TY: 2
}

export function getmodelName(status) {
	switch (status) {
		case modelStatus.ZY:
			return '在用'
		case modelStatus.TY:
			return '停用'
	}
}

//////////////////////////////////////////////////////设备信息

/**
 * 设备的类型
 */
export const DeviceType = {
	JKTCQ: 1,
	JC: 2,
	ZTQC: 3,
	JKSB: 4,
	DY: 5
}

export function getDeviceType(type) {
	switch (type) {
		case DeviceType.JKTCQ:
			return '监控探测器'
		case DeviceType.JC:
			return 'JC-7689探测器'
		case DeviceType.ZTQC:
			return 'JC-ZTQC探测器'
		case DeviceType.JKSB:
			return '监控设备'
		case DeviceType.DY:
			return '测试探测器-电压'
	}
}

export const device = [
	{
		value: DeviceType.JKTCQ,
		label: getDeviceType(DeviceType.JKTCQ)
	},
	{
		value: DeviceType.JC,
		label: getDeviceType(DeviceType.JC)
	},
	{
		value: DeviceType.ZTQC,
		label: getDeviceType(DeviceType.ZTQC)
	},
	{
		value: DeviceType.JKSB,
		label: getDeviceType(DeviceType.JKSB)
	},
	{
		value: DeviceType.DY,
		label: getDeviceType(DeviceType.DY)
	}
]

/**
 * 设备状态
 */
export const deviceStatus = {
	ZY: 1,
	TY: 2
}

export function getDeviceName(status) {
	switch (status) {
		case deviceStatus.ZY:
			return '在用'
		case deviceStatus.TY:
			return '停用'
	}
}

/**
 * 设备工作模式
 */
export const WORK_MODE = {
	TWO: 1,
	FOUR: 2,
	FIVE: 3,
	WIFI: 4,
	LAN: 5,
	NB_IOT: 6
}

export const WORK_MODES = [
	{
		id: WORK_MODE.TWO,
		value: '2G'
	},
	{
		id: WORK_MODE.FOUR,
		value: '4G'
	},
	{
		id: WORK_MODE.FIVE,
		value: '5G'
	},
	{
		id: WORK_MODE.WIFI,
		value: 'WIFI'
	},
	{
		id: WORK_MODE.LAN,
		value: 'LAN'
	},
	{
		id: WORK_MODE.NB_IOT,
		value: 'NB_IOT'
	}
]

/**
 * 通讯模式
 */

export const COMM_MODE = [
	{
		id: 1,
		value: 'CAN总线'
	},
	{
		id: 2,
		value: 'RS485总线'
	},
	{
		id: 3,
		value: 'LORA'
	},
	{
		id: 4,
		value: '蓝牙'
	}
]

/*export const Manufacturer = [{
  value: Manufacturer.JKTCQ,
  label:getManufacturer(Manufacturer.JKTCQ)
}, {
  value: Manufacturer.JC,
  label: getManufacturer(Manufacturer.JC)
}, {
  value: Manufacturer.ZTQC,
  label: getManufacturer(Manufacturer.ZTQC)
}, {
  value: Manufacturer.JKSB,
  label: getManufacturer(Manufacturer.JKSB)
},{
  value: Manufacturer.DY,
  label: getDeviceType(Manufacturer.DY)
}]*/

/**
 * 获取设备状态信息
 */
export function getStatusValue(status) {
	switch (status) {
		case 1:
			return '正常'
		case 2:
			return '掉线'
		case 3:
			return '故障'
		case 4:
			return '告警'
		case 5:
			return '休眠'
		default:
			return '未知'
	}
}

export const EqSys = [
	{
		value: 1,
		label: '用电安全监控系统'
	},
	{
		value: 2,
		label: '火灾远程监控系统'
	},
	{
		value: 5,
		label: '用水安全监控系统'
	},
	{
		value: 6,
		label: '环境报警监控系统'
	},
	{
		value: 7,
		label: '视频报警监控系统'
	}
]

export const TransDeviceStatus = [
	{
		value: 0,
		label: '全部'
	},
	{
		value: 1,
		label: '正常'
	},
	{
		value: 2,
		label: '掉线'
	},
	{
		value: 3,
		label: '故障'
	},
	{
		value: 4,
		label: '告警'
	},
	{
		value: -1,
		label: '未知'
	}
]

export const TransDeviceStatus2 = [
	{
		value: 0,
		label: '全部'
	},
	{
		value: 1,
		label: '正常'
	},
	{
		value: 2,
		label: '掉线'
	},
	{
		value: 3,
		label: '故障'
	},
	{
		value: 4,
		label: '告警'
	},
	{
		value: -1,
		label: '未知'
	}
]

export const getMonitorDeviceTypeList = [
	{
		value: 0,
		label: '热成像型号大华摄像头'
	},
	{
		value: 1,
		label: '其他型号大华摄像头'
	},
	{
		value: 2,
		label: '海康摄像头'
	},
	{
		value: 3,
		label: '智能消防柜'
	}
]
export function getMonitorDeviceType(type) {
	switch (type) {
		case 1:
			return '大华摄像头'
		case 2:
			return '海康摄像头'
	}
}
