<template>
  <div :class="[expand ? 'device-detail-content-expend': 'device-detail-content-no-expend']">
    <p>信号强度：{{ realtimeData != undefined ? realtimeData.csq : '暂无信息' }}</p>
<!--    <el-row :key="index" id="SensorData" v-for="(item,index) in sensorRtData" class="realtime-data" v-show="expand">-->
<!--      <el-col :span="5" class="hidden-font" :title="item.sensorName">{{ item.sensorName }}</el-col>-->
<!--      <el-col :span="9" >({{ item.minValue }}&lt;值&lt;{{ item.maxValue }} )</el-col>-->
<!--      <el-col :span="5" class="hidden-font"  :style="{color: renderRandomColor(item.sbzt)}" :title="item.sbztValue">{{ item.sbztValue }}</el-col>-->
<!--      <el-col :span="5" class="hidden-font" :title="item.realValue+item.unit">{{ item.realValue }}{{ item.unit }}</el-col>-->
<!--    </el-row>-->
    <HlTable
        v-show="expand"
        :table-data="sensorRtData"
        :column="senorOptions.senorColumn"
        :cell-style="senorOptions.senorCellStyle"
        :head-style="senorOptions.senorHeadStyle"
        color="rgba(23,38,76,0.89)"
        :height="`350px`"
        :show-header="false"
    >
      <template #sbztValue="scope">
        <div class="hidden-font"  :style="{color: renderRandomColor(scope.row.sbzt)}" :title="scope.row.sbztValue">{{ scope.row.sbztValue }}</div>
      </template>
      <template #valRegion="scope">
<!--        <div  :title="`( ${scope.row.minValue}<值<${scope.row.maxValue})`">({{ scope.row.minValue }}&lt;值&lt;{{ scope.row.maxValue }})</div>-->
        <div  :title="scope.row.valRegion">{{scope.row.valRegion}}</div>
      </template>

      <template #realVal="scope">
        <div  :title="scope.row.realVal">{{ resolveRealVal(scope.row.sensorName,scope.row.realVal) }}</div>
      </template>
<!--      <template #realValue="scope">-->
<!--        {{ scope.row.realValue }}{{ scope.row.unit }}-->
<!--      </template>-->
    </HlTable>
    <div class="arrow">
      <p class="hidden-font" :title="realtimeData.statusTime">更新时间：{{ realtimeData != undefined ? realtimeData.statusTime : '暂无信息' }}</p>
      <img v-if="expand" src="../../../../../images/arrow-up.png" @click="arrowClick()" class="arrow-down"/>
      <img v-else src="../../../../../images/arrow-down.png" @click="arrowClick()" class="arrow-down"/>
    </div>
<!--    <p class="realtime-title">更新时间：{{ realtimeData != undefined ? realtimeData.statusTime : '暂无信息' }}</p>-->

<!--    <p class="realtime-title">信号强度：{{  }}</p>-->


  </div>
</template>

<script>

import {getSensorColorNew} from "@/utils/constants";
import HlTable from "@/component/Tabel/HlTable";
import {senorColumn, senorCellStyle, senorHeadStyle, resolveRealVal, resolveSensorValue} from './index'
export default {
  name: 'DeviceDetailRealtimeData',
  props: {
    msg: String,
    // sensorRtData: Array,
    // realtimeData: Object
  },
  components:{
    HlTable
  },
  data() {
    return {
      senorOptions:{
        senorColumn,
        senorCellStyle,
        senorHeadStyle
      },
      tableData: []
    }
  },
  computed:{
    sensorRtData(){
      console.log("=====")
      return this.$store.getters.sensorRtData;
    },
    realtimeData(){
      return this.$store.getters.realtimeData;
    },
    expand(){
      return this.$store.getters.expand;
    }
  },
  methods: {
    arrowClick() {
      this.$store.commit('SET_EXPAND', !this.expand)
    },
    renderRandomColor (arg) {
      // console.log('----rbzt', this.sensorRtData)
      return getSensorColorNew(arg);
    },
    resolveRealVal(sensorName, value){
      return resolveSensorValue(sensorName, value);
    }
  },
  watch:{
    '$store.getters.sensorRtData':{
      handler(newVal, oldVal){
        console.log("===执行watch===", newVal)
        this.tableData = newVal
      },
      deep: true
    }
  },
  activated() {
    console.log('activated')
  },
  deactivated() {
    console.log('deactivated')
  },
}
</script>

<style scoped>
.device-detail-content{
  position: relative;
}
p {
  width: 80%;
  height: 20px;
  font-size: 14px;
  letter-spacing: 1px;
  margin: 0 0 19px 0;
}

.realtime-title {
  padding-left: 10px;
  font-size: 8px !important;
  letter-spacing: 1px !important;
}

.realtime-data {
  /*background: rgba(255, 255, 255, 0.05);*/
  font-size: 14px;
  letter-spacing: 1px !important;
  margin-top: 2px;
  margin-bottom: 18px;
}
.arrow{
  width: calc(100% - 44px);
  height: 20px;
  display: flex;
  position: absolute;
  bottom: 10px;
  left: 22px;
  justify-content: space-between;
}
.arrow img{
  width: 18px;
  height: 10px;
  opacity: 1;
  display: block; margin-top: 5px;
}
.arrow p{
  font-size: 12px;

}
.warning-text{
  color: #FD3838;
}
</style>
