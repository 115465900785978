import axios from 'axios'
import Vue from 'vue'
import router from '@/router'
// import { ACCESS_TOKEN } from '@/store/mutation-types'
// import store from '@/store'

import baseUrl from '@/api/baseUrl'
import {clearLoginInfo} from "@/utils";

const useProxy = process.env.NODE_ENV !== 'production'

const service = axios.create({
  baseURL: useProxy ? '/proxyApi' : baseUrl.BASE_API,
  timeout: 15000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
  },
})

service.interceptors.request.use(config => {
  config.headers.token = localStorage.token
  // config.headers.token = Vue.ls.get(ACCESS_TOKEN)
  return config
}, error => {
  Promise.reject(error)
})

service.interceptors.response.use(response => {
  if (response.data && response.data.code === 401) {
    console.log('401.重新登录')
    clearLoginInfo();
    router.push('login')
    // store.dispatch('Logout').then(() => {
    //   setTimeout(() => {
    //     window.location.reload()
    //   }, 500)
    // })
  }
  return response
}, err => {
  return Promise.resolve(err)
})

export default service
