<template>
    <el-container id="display-body" class="main-content">
      <el-header class="header">
        <fire-header></fire-header>
      </el-header>
      <el-container class="content">
        <!--- 地图区 -->
        <el-aside>
          <map-content></map-content>
          <right-stat></right-stat>
        </el-aside>
        <!--右侧 结束-->
      </el-container>
    </el-container>
</template>

<script>
import FireHeader from "@/views/Layout/Header/Header";
import MapContent from "@/views/Layout/Main/MainLeft/MapContent";
import RightStat from "@/views/Layout/Main/MainRight/RightStat";
import baseUrl from "@/api/baseUrl";
import {axiosServer} from "@/api/judge";
import bus from "@/utils/eventBus/bus";
export default {
  name: "Home",
  components:{
    FireHeader,
    MapContent,
    RightStat
  },
  created() {
    console.log('======Created======')
    // 页面刚进入时开启长连接
    this.connWebSocket();
    // 开启全局定时器

  },
  data(){
    return{
      count: 0,
      timer: null,
      webSocketInstance: null,
      wsTimer: null,
    }
  },

  mounted(){
    this.startWSListenerTimer();
  },
  methods:{
    // sleep(ms){
    //   return new Promise(res => {
    //     setTimeout(res, ms);
    //   })
    // },
    closeWSListenerTimer() {
      // console.log('清除ws监听器');
      clearInterval(this.wsTimer);
    },
    connWebSocket() {
      // console.log('conWebSocket');
      let url = baseUrl.WS_URL + '?userId=' + localStorage.getItem('userId') + '&platform=display';
      console.log(url)
      this.webSocketInstance = new WebSocket(url);
      this.webSocketInstance.onopen = this.ws_open;
      this.webSocketInstance.onmessage = (evt) => {
        let data = JSON.parse(evt.data);
        let code = data.code;
        // && data.alarmType == 1
        if (code == 1001) {
          let msg = JSON.parse(data.msg);
          if (localStorage.getItem("resetPwd") == 6500){
            bus.$emit('openVideoDialog', {
              type: 0,
              msg: msg
            });
          }else {
            if (msg.alarmType == 1){
              let deviceId = JSON.parse(data.msg).deviceId;
              // this.requestAlarmCount();
              bus.$emit('requestAlarm', deviceId);
            }
          }
          // eslint-disable-next-line no-empty
        }
        if (code == 7500){
          let msg = JSON.parse(data.msg);
          bus.$emit('openVideoDialog', {
            type: 2,
            msg: msg
          });
        }
      };
      this.webSocketInstance.onclose = this.ws_close;
    },
    /**
     * 启动一个监听webSocket的监视器
     * 0 (CONNECTING)
     * 正在链接中
     * 1 (OPEN)
     * 已经链接并且可以通讯
     * 2 (CLOSING)
     * 连接正在关闭
     * 3 (CLOSED)
     * 连接已关闭或者没有链接成功
     * */
    startWSListenerTimer() {
      let self = this
      // console.log('启动ws监听器');
      this.wsTimer = setInterval(() => {
        let wsState = this.webSocketInstance.readyState;
        //        console.log("ws state = " + wsState);
        if (wsState == 3) {
          // console.log('ws 断开了，重连');
          self.connWebSocket();
        }
      }, 5000);
    },
    ws_close() {
      // 断开连接
      this.webSocketInstance.close();
      // this.$message.error("WS连接断开，重连...");
    },
    // 测试websocket
    getWarning(id){
      axiosServer({
        url: '/test/sendWSAlarmTest',
        type: 'get',
        params: {
          userId: localStorage.getItem('userId'),
          deviceId: id
        }
      }).then(res => {
        console.log('getWarning', res)
        if (res.data.code == 0){
          bus.$emit('requestAlarm', id);
        }
      })
    }
  },
  computed:{

  },
  destroyed() {
    console.log("== 触发socket关闭 ==")
    this.closeWSListenerTimer();
    this.webSocketInstance.close();
  }
}
</script>

<style scoped>
.content-right{

  background-color: #101c42 !important;
  overflow-x: hidden !important;
}

.main-content{
  height: 100% !important;
  /*overflow-x: hidden;*/
}

.el-header, .el-footer {
  height: 70px !important;
}

.el-aside {
  text-align: center;
  width: 100% !important;
}

.el-main {
  background-color: #E9EEF3;
  color: #333;
  text-align: center;
  width: 25% !important;

}



body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}

</style>
