<template>
  <el-dialog
      :visible.sync="show"
      width="800px"
      height="auto"
      custom-class="setting"
      :close-on-click-modal="false"
      :before-close="close"
  >
    <div class="setting-text">
      处理告警
    </div>
    <div class="deal-form" v-show="state == 0">
      <el-form label-position="right" :model="dealForm" ref="dealFormRef">
        <el-form-item label="设备名称" :label-width="labelWidth">
          <div class="text">{{ deviceAlarm.deviceInfo.deviceName || '无' }}</div>
        </el-form-item>
        <el-form-item label="设备IP" :label-width="labelWidth">
          <div class="text">{{ deviceAlarm.deviceInfo.ip || '无' }}</div>
        </el-form-item>
        <el-form-item label="联系人" :label-width="labelWidth">
          <div class="text">{{  deviceInfo.contacts || '无' }}</div>
        </el-form-item>
        <el-form-item label="联系电话" :label-width="labelWidth">
          <div class="text">{{   deviceInfo.phone || '无' }}</div>
        </el-form-item>
        <el-form-item label="告警时间" :label-width="labelWidth">
          <div class="text">{{ deviceAlarm.alarmInfo.jcsj || '无' }}</div>
        </el-form-item>
        <el-form-item label="告警类型" :label-width="labelWidth">
          <div class="text">{{ deviceAlarm.alarmInfo.statusValue || '无' }}</div>
        </el-form-item>
        <el-form-item label="告警传感" :label-width="labelWidth">
          <div class="text">{{ deviceAlarm.alarmInfo.sensorName || '无' }}</div>
        </el-form-item>
<!--        <el-form-item label="客户机状态" :label-width="labelWidth">-->
<!--          <div class="text">{{ realtimeData.clientStateValue }}</div>-->
<!--        </el-form-item>-->
        <el-form-item label="告警数据" :label-width="labelWidth">
          <div class="text">{{ deviceAlarm.alarmInfo.valueFormat + deviceAlarm.sensorInfo.unit  || '无' }}</div>
        </el-form-item>
        <el-form-item label="核实告警原因" :label-width="labelWidth" prop="closeReason">
          <el-input v-model="dealForm.closeReason" placeholder="请输入核实告警原因"></el-input>
<!--          <el-select v-model="dealForm.content" placeholder="请选择">-->
<!--            <el-option-->
<!--                v-for="item in list"-->
<!--                :key="item.id"-->
<!--                :label="item.text"-->
<!--                :value="item.text">-->
<!--            </el-option>-->
<!--          </el-select>-->
        </el-form-item>
<!--        <el-form-item class="upload" label="上传现场凭证" label-width="146px" prop="imgUrl">-->

<!--          <el-upload-->
<!--              class="avatar-uploader"-->
<!--              :action="action"-->
<!--              :show-file-list="false"-->
<!--              :on-success="handleAvatarSuccess"-->
<!--              :before-upload="beforeAvatarUpload"-->
<!--          >-->
<!--            <img v-if="dealForm.imgUrl" :src="dealForm.imgUrl" class="avatar">-->
<!--            <i v-else class="el-icon-plus avatar-uploader-icon"></i>-->

<!--          </el-upload>-->
<!--          <div class="text-warning">-->
<!--            <div>上传jpg、png图片格式；</div>-->
<!--            <div>建议图片大小在500KB以内；</div>-->
<!--          </div>-->
<!--        </el-form-item>-->
        <el-form-item style="display: none" label="上传文件" :label-width="labelWidth" prop="file" >

        </el-form-item>
      </el-form>
    </div>
    <div class="deal-form" v-show="state == 1">
      <el-form label-position="right" :model="dealForm" ref="dealFormRef">
        <el-form-item label="设备名称" :label-width="labelWidth">
          <div class="text">{{ tableInfo.deviceName || '无' }}</div>
        </el-form-item>
        <el-form-item label="设备IP" :label-width="labelWidth">
          <div class="text">{{ tableInfo.ip || '无' }}</div>
        </el-form-item>
        <el-form-item label="联系人" :label-width="labelWidth">
          <div class="text">{{  tableInfo.contacts || '无' }}</div>
        </el-form-item>
        <el-form-item label="联系电话" :label-width="labelWidth">
          <div class="text">{{  tableInfo.phone || '无' }}</div>
        </el-form-item>
        <el-form-item label="告警时间" :label-width="labelWidth">
          <div class="text">{{ tableInfo.jcsj || '无' }}</div>
        </el-form-item>
        <el-form-item label="告警类型" :label-width="labelWidth">
          <div class="text">{{ tableInfo.statusValue || '无' }}</div>
        </el-form-item>
        <el-form-item label="告警传感" :label-width="labelWidth">
          <div class="text">{{ tableInfo.sensorName || '无' }}</div>
        </el-form-item>
<!--        <el-form-item label="客户机状态" :label-width="labelWidth">-->
<!--          <div class="text">{{ tableInfo.sbztlx }}</div>-->
<!--        </el-form-item>-->
        <el-form-item label="告警数据" :label-width="labelWidth">
          <div class="text">{{ tableInfo.jcz + tableInfo.unit || '无' }}</div>
        </el-form-item>
        <el-form-item label="核实告警原因" :label-width="labelWidth" prop="closeReason">
          <el-input v-model="dealForm.closeReason" placeholder="请输入核实告警原因"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <!--  历史告警  -->
    <div class="deal-form" v-show="state == 2">
      <el-form label-position="right" :model="dealForm" ref="dealFormRef">
        <el-form-item label="设备名称" :label-width="labelWidth">
          <div class="text">{{ tableInfo.deviceName || '无' }}</div>
        </el-form-item>
        <el-form-item label="设备IP" :label-width="labelWidth">
          <div class="text">{{ tableInfo.ip || '无' }}</div>
        </el-form-item>
        <el-form-item label="联系人" :label-width="labelWidth">
          <div class="text">{{  tableInfo.contacts || '无' }}</div>
        </el-form-item>
        <el-form-item label="联系电话" :label-width="labelWidth">
          <div class="text">{{  tableInfo.phone || '无' }}</div>
        </el-form-item>
        <el-form-item label="解除人" :label-width="labelWidth">
          <div class="text">{{ tableInfo.closeUserName || '无' }}</div>
        </el-form-item>
        <el-form-item label="解除时间" :label-width="labelWidth">
          <div class="text">{{ tableInfo.closeTime || '无' }}</div>
        </el-form-item>
        <el-form-item label="解除原因" :label-width="labelWidth">
          <div class="text">{{ tableInfo.closeReason || '无' }}</div>
        </el-form-item>
        <el-form-item label="告警时间" :label-width="labelWidth">
          <div class="text">{{ tableInfo.jcsj || '无' }}</div>
        </el-form-item>
        <el-form-item label="告警类型" :label-width="labelWidth">
          <div class="text">{{ tableInfo.statusValue || '无' }}</div>
        </el-form-item>
        <el-form-item label="告警传感" :label-width="labelWidth">
          <div class="text">{{ tableInfo.sensorName || '无' }}</div>
        </el-form-item>
<!--        <el-form-item label="客户机状态" :label-width="labelWidth">-->
<!--          <div class="text">{{ tableInfo.clientStateValue }}</div>-->
<!--        </el-form-item>-->
        <el-form-item label="告警数据" :label-width="labelWidth">
          <div class="text">{{ tableInfo.jcz + tableInfo.unit || '无' }}</div>
        </el-form-item>
<!--        <el-form-item label="核实告警原因" :label-width="labelWidth" prop="closeReason">-->
<!--          <el-input v-model="dealForm.closeReason" placeholder="请输入核实告警原因"></el-input>-->
<!--        </el-form-item>-->
      </el-form>
    </div>
    <div class="deal-button">
      <el-button type="primary" @click="confirm" v-show="state == 0 || state == 1">解除告警</el-button>
      <el-button type="primary" @click="close" v-show="state == 2">关闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {Message} from 'element-ui'
import {axiosServer} from "@/api/judge";
import baseUrl from "@/api/baseUrl";
import {closeAlarm} from "@/api/impl/alarm";
import bus from "@/utils/eventBus/bus";

export default {
  name: "RecordDialog",
  props:{
    show:{
      type: Boolean,
      default: false
    },
    state: {
      type: Number,
      default: 0
    },
    tableInfo:{
      type: Object,
      default: () => {}
    },

  },
  data() {
    return {
      dealForm: {
        // file: null,
        // content: '',
        // imgUrl: '',
        closeReason: ''
      },
      action: '',
      labelWidth: '150px',
      alarmId: 0,
      // 实时警告
      reqsBody: {
       body: {
         closed: 1,
         page: 1,
         alarmType: 1,
         pageSize: 100,
       },
        type: 1
      },
    }
  },
  computed:{
    deviceAlarm(){
      return this.$store.getters.deviceAlarm;
    },
    realtimeData(){
      return this.$store.getters.realtimeData
    },
    deviceInfo(){
      return this.$store.getters.deviceInfo;
    },
  },
  // 上传图片成功回调方法
  methods: {
    handleAvatarSuccess(res, file) {
      console.log('handleAvatarSuccess',res)
      this.imgUrl = res.url;
      // this.settingForm.imgUrl = URL.createObjectURL(file.raw)
    },
    // 图片上传限制
    beforeAvatarUpload(file) {
      let isJPG = ['image/png', 'image/jpg', 'image/jpeg'].indexOf(file.type) > -1 ? true : false;
      const isLt2M = file.size / 1024 / 1024 <= 1;
      if (!isJPG) {
        Message({
          showClose: true,
          message: '请上传jpg、png图片格式',
          type: 'error'
        })
      }
      if (!isLt2M) {
        Message({
          showClose: true,
          message: '图片上传尺寸不能大于1MB',
          type: 'error'
        })
      }
      this.dealForm.file = file;
      this.dealForm.imgUrl = URL.createObjectURL(file);
      return isJPG && isLt2M;
    },
    // 关闭按钮
    close() {
      this.$nextTick(() => {
        this.$refs.dealFormRef.resetFields();
      })
      this.$emit('changeShow', 7)
    },
    // 取消按钮
    cancel() {
      // 弹框隐藏
      this.$emit('changeShow', 7)
    },
    // 确定按钮
    confirm() {
      let id = null;
      // if (this.state === 1){
      //   id = this.deviceAlarm.alarmInfo.id;
      // }else {
      //   id = this.tableInfo.alarmId;
      // }
      console.log(this.tableInfo,'tableInfo')
      // 提交表单
      closeAlarm({ id: this.deviceAlarm.alarmInfo.id || this.alarmId, closeReason: this.dealForm.closeReason })
          .then((res) => {
            if (res.code == 0){
              Message({
                message: '解除成功',
                type: 'success'
              });
              this.$store.dispatch('GET_WARNING_DATA', this.reqsBody)
            }else {
              Message({
                message: '解除失败:' + res.msg,
                type: 'error'
              });
            }
            bus.$emit('closeOverlay', false)
          })

      // 弹框隐藏
      this.$nextTick(() => {
        this.$refs.dealFormRef.resetFields();
      })

      this.$emit('changeShow', 7)
    },

  },
  mounted(){
    let path = process.env.NODE_ENV !== 'production' ? '/proxyApi' : baseUrl.BASE_API
    this.action = `${path}/sys/oss/upload?token=${localStorage.getItem('token')}`
  },
  watch:{
    tableInfo:{
      handler(newVal){
        this.alarmId = newVal.alarmId
      }
    }
  }
}
</script>

<style scoped>
.el-dialog__wrapper{
  width: 100vw;
  height: 100vh;

}
/deep/.el-dialog{
  height: auto;
  background: #17264c;
  margin:0 !important;
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
}
/deep/ .el-pager li{
  background: #17264c;
}
/* 顶部文字区域 */
.setting-text {
  padding-top: 40px;
  text-align: center;
  font-size: 18px;
  color: #fff;
}

/* 表单区域 */

.deal-form {
  margin: 20px 30px 0 10px;
}

.el-form {
  width: 100%;
}

.el-form-item {
  margin-bottom: 10px;
}

/deep/ .el-form-item__label {
  /*height: 30px;*/
  line-height: 30px;
  color: #fff;
  /*margin-right: 20px;*/
  font-size: 18px;
  padding: 0 20px 0 0;
}

.text {
  height: 30px;
  line-height: 30px;
  color: #fff;
  font-size: 18px;
}

/deep/ .el-input__inner {
  /*width: 772px;*/
  height: 48px;
  background: #17264c !important;
  border: 1px solid rgba(91, 163, 237, 0.50) !important;
  border-radius: 8px;
}

/*/deep/ .el-select .el-input__inner {*/

/*  !*color: #EAEAEA4D!important;*!*/
/*  background: #17264c !important;*/
/*  border: 1px solid rgba(91, 163, 237, 0.50) !important;*/
/*  border-radius: 8px;*/
/*}*/

/*/deep/ .el-input__prefix, /deep/ .el-input__suffix {*/
/*  top: 0px;*/
/*}*/

.avatar-uploader {
  width: 188px;
  height: 188px;
  background: #17264c;
  border: 1px solid rgba(91, 163, 237, 0.50);
  border-radius: 8px;
  box-sizing: border-box;
}

.avatar-uploader-icon {
  font-size: 18px;
  color: #5BA3ED;
  width: 188px;
  height: 188px;
  line-height: 188px;
  text-align: center;
}

.upload /deep/ .el-form-item__content {
  display: flex;
}

.avatar {
  width: 186px;
  height: 186px;
  object-fit: cover;
  object-position: top left;
  border-radius: 8px;
}

.text-warning {
  height: 188px;
  display: flex;
  justify-content: flex-end;
  margin-left: 20px;
  flex-direction: column;
}

.text-warning div {
  height: 25px;
  line-height: 25px;
  font-size: 18px;
  color: #EAEAEA4D;
  font-weight: 400;
  letter-spacing: 1px;
}

/*按钮*/
.deal-button {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  margin-bottom: 61px;
  padding-top: 170px;
}

.el-button {
  width: 411px;
  height: 49px;
  opacity: 1;
  color: #fff;
  background: #0364cd;
  border-color: #0364cd;
  border-radius: 7px;
  font-size: 15px;
}
.el-button--primary:focus, .el-button--primary:hover{
  border-color: #046cdc;
  background: #046cdc;
}
</style>
<style>
.warning {

  border-radius: 7px;
  background: #17264C;
  border: 1px solid #5BA3ED4D;
  margin-top: 0vh !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.warning > div:nth-child(1) {
  padding: 0;
}

.warning > div:nth-child(1) .el-dialog__headerbtn {
  font-size: 22px;
}

.warning > div:nth-child(2) {
  padding: 0;
}

.warning > div:nth-child(3) {
  padding: 0;
}

</style>
