<template>
  <div class="root">
    <div class="container-shell">
      <!--      <div class="container-shell-title">jessibuca demo player <span class="tag-version" v-if="version">({{-->
      <!--          version-->
      <!--        }})</span></div>-->
      <div class="option">
        <!--        <span>缓冲(秒):</span>-->
        <!--        <input-->
        <!--            style="width: 50px"-->
        <!--            type="number"-->
        <!--            ref="buffer"-->
        <!--            value="0.2"-->
        <!--            @change="changeBuffer"-->
        <!--        />-->
        <!--        <input-->
        <!--            type="checkbox"-->
        <!--            v-model="useMSE"-->
        <!--            ref="vod"-->
        <!--            @change="restartPlay('mse')"-->
        <!--        /><span>MediaSource</span>-->
        <!--        <input-->
        <!--            type="checkbox"-->
        <!--            v-model="useWCS"-->
        <!--            ref="vod"-->
        <!--            @change="restartPlay('wcs')"-->
        <!--        /><span>webcodecs</span>-->

      </div>
      <div id="container" ref="container">

      </div>
      <div class="input">
        <!--        <div>输入URL：</div>-->
        <!--        <input-->
        <!--            type="input"-->
        <!--            autocomplete="on"-->
        <!--            ref="playUrl"-->
        <!--            value=""-->
        <!--        />-->
        <button v-if="!playing" @click="playBtnClick">播放</button>
        <button v-else @click="pause">停止</button>
      </div>
      <!--      <div class="input" v-if="loaded" style="line-height: 30px">-->
      <!--        <button @click="destroy">销毁</button>-->
      <!--        <button v-if="quieting" @click="cancelMute">取消静音</button>-->
      <!--        <template v-else>-->
      <!--          <button @click="mute">静音</button>-->
      <!--          音量-->
      <!--          <select v-model="volume" @change="volumeChange">-->
      <!--            <option value="1">100</option>-->
      <!--            <option value="0.75">75</option>-->
      <!--            <option value="0.5">50</option>-->
      <!--            <option value="0.25">25</option>-->
      <!--          </select>-->
      <!--        </template>-->
      <!--        <span>旋转</span>-->
      <!--        <select v-model="rotate" @change="rotateChange">-->
      <!--          <option value="0">0</option>-->
      <!--          <option value="90">90</option>-->
      <!--          <option value="270">270</option>-->
      <!--        </select>-->

      <!--        <button @click="fullscreen">全屏</button>-->
      <!--        <button @click="screenShot">截图</button>-->
      <!--        <div style="line-height: 30px">-->
      <!--          <input-->
      <!--              type="checkbox"-->
      <!--              ref="operateBtns"-->
      <!--              v-model="showOperateBtns"-->
      <!--              @change="restartPlay"-->
      <!--          /><span>操作按钮</span>-->
      <!--          <input-->
      <!--              type="checkbox"-->
      <!--              ref="operateBtns"-->
      <!--              v-model="showBandwidth"-->
      <!--              @change="restartPlay"-->
      <!--          /><span>网速</span>-->
      <!--          <span v-if="performance">性能：{{ performance }}</span>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="input" v-if="loaded">-->
      <!--        <input-->
      <!--            type="checkbox"-->
      <!--            ref="offscreen"-->
      <!--            v-model="useOffscreen"-->
      <!--            @change="restartPlay('offscreen')"-->
      <!--        /><span>离屏渲染</span>-->

      <!--        <select v-model="scale" @change="scaleChange">-->
      <!--          <option value="0">完全填充(拉伸)</option>-->
      <!--          <option value="1">等比缩放</option>-->
      <!--          <option value="2">完全填充(未拉伸)</option>-->
      <!--        </select>-->
      <!--        <button v-if="!playing" @click="clearView">清屏</button>-->
      <!--        <template v-if="playing">-->
      <!--          <select v-model="recordType">-->
      <!--            <option value="webm">webm</option>-->
      <!--            <option value="mp4">mp4</option>-->
      <!--          </select>-->
      <!--          <button v-if="!recording" @click="startRecord">录制</button>-->
      <!--          <button v-if="!recording" @click="stopAndSaveRecord">暂停录制</button>-->
      <!--        </template>-->

      <!--      </div>-->
    </div>
  </div>
</template>

<script>
import {axiosServer} from "@/api/judge";

let jessibucaPlayer = {};
export default {
  name: 'jessibuca',
  data() {
    return {
      playing: false,
      isNotMute: false,
      quieting: false,
      fullscreen: false,
      loaded: false, // mute
      speed: 0,
      performance: "", // 工作情况
      kBps: 0,
      btnDom: null,
      videoInfo: null,
      volume: 1,
      rotate: 0,
      vod: true, // 点播
      forceNoOffscreen: false,
    };
  },
  props: ['videoUrl', 'error', 'hasAudio', 'height', 'deviceObj'],
  mounted() {
    window.onerror = (msg) => {
      // console.error(msg)
    };
    console.log(this._uid)
    // let paramUrl = decodeURIComponent(this.$route.params.url)
    this.$nextTick(() => {
      // this.updatePlayerDomSize()
      // window.onresize = () => {
      //   this.updatePlayerDomSize()
      // }
      if (typeof (this.videoUrl) == "undefined") {
        // eslint-disable-next-line vue/no-mutating-props
        // this.videoUrl = paramUrl;
      }
      this.btnDom = document.getElementById("buttonsBox");
      console.log("初始化时的地址为: " + this.videoUrl)
      this.play(this.videoUrl)
    })
  },
  watch: {
    videoUrl(newData, oldData) {
      this.play(newData)
      console.log("watch demo", newData !== oldData)
      if (newData !== oldData){
        this.stopCamera(oldData)
      }
    },
    immediate: true
  },
  methods: {
    updatePlayerDomSize() {
      let dom = this.$refs.container;
      let width = dom.parentNode.clientWidth
      let height = (9 / 16) * width

      const clientHeight = Math.min(document.body.clientHeight, document.documentElement.clientHeight)
      if (height > clientHeight) {
        height = clientHeight
        width = (16 / 9) * height
      }

      dom.style.width = width + 'px';
      dom.style.height = height + "px";
    },
    create() {
      let options = {};
      console.log("hasAudio  " + this.hasAudio)

      jessibucaPlayer[this._uid] = new window.Jessibuca(Object.assign(
          {
            container: this.$refs.container,
            videoBuffer: 0.2, // 最大缓冲时长，单位秒
            isResize: true,
            decoder: "decoder.js",
            useMSE: true,
            showBandwidth: false,
            isFlv: true,
            // text: "WVP-PRO",
            // background: "static/images/zlm-logo.png",
            loadingText: "疯狂加载中...",
            hasAudio: false,
            debug: false,
            supportDblclickFullscreen: false, // 是否支持屏幕的双击事件，触发全屏，取消全屏事件。
            operateBtns: {
              fullscreen: false,
              screenshot: false,
              play: false,
              audio: false,
              recorder: false,
            },
            record: "record",
            vod: this.vod,
            forceNoOffscreen: this.forceNoOffscreen,
            isNotMute: this.isNotMute,
          },
          options
      ));
      let jessibuca = jessibucaPlayer[this._uid];
      let _this = this;
      jessibuca.on("load", function () {
        console.log("on load init");
      });

      jessibuca.on("log", function (msg) {
        console.log("on log", msg);
      });
      jessibuca.on("record", function (msg) {
        console.log("on record:", msg);
      });
      jessibuca.on("pause", function () {
        _this.playing = false;
      });
      jessibuca.on("play", function () {
        _this.playing = true;
      });
      jessibuca.on("fullscreen", function (msg) {
        console.log("on fullscreen", msg);
        _this.fullscreen = msg
      });

      jessibuca.on("mute", function (msg) {
        console.log("on mute", msg);
        _this.isNotMute = !msg;
      });
      jessibuca.on("audioInfo", function (msg) {
        // console.log("audioInfo", msg);
      });

      jessibuca.on("videoInfo", function (msg) {
        // this.videoInfo = msg;
        console.log("videoInfo", msg);

      });

      jessibuca.on("bps", function (bps) {
        // console.log('bps', bps);

      });
      let _ts = 0;
      jessibuca.on("timeUpdate", function (ts) {
        // console.log('timeUpdate,old,new,timestamp', _ts, ts, ts - _ts);
        _ts = ts;
      });

      jessibuca.on("videoInfo", function (info) {
        console.log("videoInfo", info);
      });

      jessibuca.on("error", function (error) {
        console.log("error", error);
      });

      jessibuca.on("timeout", function () {
        console.log("timeout");
      });

      jessibuca.on('start', function () {
        console.log('start');
      })

      jessibuca.on("performance", function (performance) {
        let show = "卡顿";
        if (performance === 2) {
          show = "非常流畅";
        } else if (performance === 1) {
          show = "流畅";
        }
        _this.performance = show;
      });
      jessibuca.on('buffer', function (buffer) {
        // console.log('buffer', buffer);
      })

      jessibuca.on('stats', function (stats) {
        // console.log('stats', stats);
      })

      jessibuca.on('kBps', function (kBps) {
        _this.kBps = Math.round(kBps);
      });

      // 显示时间戳 PTS
      jessibuca.on('videoFrame', function () {

      })

      //
      jessibuca.on('metadata', function () {

      });
    },
    playBtnClick: function (event) {
      this.play(this.videoUrl)
    },
    play: function (url) {
      console.log(url)
      if (jessibucaPlayer[this._uid]) {
        this.destroy();
      }
      this.create();
      jessibucaPlayer[this._uid].on("play", () => {
        this.playing = true;
        this.loaded = true;
        this.quieting = true;
      });
      if (jessibucaPlayer[this._uid].hasLoaded()) {
        jessibucaPlayer[this._uid].play(url);
      } else {
        jessibucaPlayer[this._uid].on("load", () => {
          console.log("load 播放")
          jessibucaPlayer[this._uid].play(url);
        });
      }
    },
    pause: function () {
      if (jessibucaPlayer[this._uid]) {
        jessibucaPlayer[this._uid].pause();
      }
      this.playing = false;
      this.err = "";
      this.performance = "";
    },
    destroy: function () {
      if (jessibucaPlayer[this._uid]) {
        jessibucaPlayer[this._uid].destroy();
      }
      // if (document.getElementById("buttonsBox") == null) {
      //   this.$refs.container.appendChild(this.btnDom)
      // }
      jessibucaPlayer[this._uid] = null;
      this.playing = false;
      this.err = "";
      this.performance = "";

    },
    eventcallbacK: function (type, message) {
      // console.log("player 事件回调")
      // console.log(type)
      // console.log(message)
    },
    fullscreenSwich: function () {
      let isFull = this.isFullscreen()
      jessibucaPlayer[this._uid].setFullscreen(!isFull)
      this.fullscreen = !isFull;
    },
    isFullscreen: function () {
      return document.fullscreenElement ||
          document.msFullscreenElement ||
          document.mozFullScreenElement ||
          document.webkitFullscreenElement || false;
    },
    async stopCamera(url){
      await axiosServer({
        url: "/v3/monitor/stop/camera?sendUrl=" + url,
        type: "GET",
        // params:{
        //   sendUrl: url
        // }
      }).then(res => {
        console.log("停止摄像头推流", res)
      })
    }
  },
  beforeDestroy() {
    if (this.videoUrl){
      this.stopCamera();
    }
  },
  destroyed() {
    if (jessibucaPlayer[this._uid]) {
      jessibucaPlayer[this._uid].destroy();
    }
    this.playing = false;
    this.loaded = false;
    this.performance = "";
  },
}
</script>

<style lang="less" scoped>
.root {
  display: flex;
  place-content: center;
  /*margin-top: 3rem;*/
}

/*.container-shell {*/
/*  position: relative;*/
/*  backdrop-filter: blur(5px);*/
/*  background: hsla(0, 0%, 50%, 0.5);*/
/*  padding: 30px 4px 10px 4px;*/
/*  !* border: 2px solid black; *!*/
/*  width: auto;*/
/*  position: relative;*/
/*  border-radius: 5px;*/
/*  box-shadow: 0 10px 20px;*/
/*}*/

.container-shell-title {
  position: absolute;
  color: darkgray;
  top: 4px;
  left: 10px;
  text-shadow: 1px 1px black;
}

.tag-version {
}

#container {
  background: rgba(13, 14, 27, 0.7);
  width: 940px;
  height: 500px;
}
.container-option{
  width: 100%;
  height: 50px;
  background: #000000;

}

.input {
  display: flex;
  align-items: center;
  margin-top: 10px;
  color: white;
  place-content: stretch;
}


.input2 {
  bottom: 0px;
}

.input input[type='input'] {
  flex: auto;
}

.err {
  position: absolute;
  top: 40px;
  left: 10px;
  color: red;
}

.option {
  position: absolute;
  top: 4px;
  right: 10px;
  display: flex;
  place-content: center;
  font-size: 12px;
}

.option span {
  color: white;
}

.page {
  /*background: url(/bg.jpg);*/
  background-repeat: no-repeat;
  background-position: top;
}

@media (max-width: 720px) {
  #container {
    width: 90vw;
    height: 52.7vw;
  }
}
</style>
