<template>
  <el-dialog
      :visible.sync="show"
      width="565px"
      custom-class="setting"
      :close-on-click-modal="false"

      :before-close="close"
  >
    <div class="setting-text">
      常规设置
    </div>
    <div class="setting-form">



      <el-form  :model="settings" ref="phoneForm">
        <el-form-item label="设备统计隐藏掉线">
          <el-switch v-model="settings.offlineShow"></el-switch>
        </el-form-item>

      </el-form>
    </div>
<!--    <div class="setting-btn">-->
<!--      <el-button type="primary" @click="cancel">取消</el-button>-->
<!--      <el-button type="primary" @click="confirm">确定</el-button>-->
<!--    </div>-->
  </el-dialog>
</template>

<script>
import {Message} from 'element-ui'
import {axiosServer} from "@/api/judge";
import baseUrl from "@/api/baseUrl";
import {getSystemSettingDetails} from "@/api/impl/sys";

export default {
  name: "SettingDialog",
  props:{
    show: Boolean,
  },
  data() {
    return {
      // 弹框显示隐藏初始化
      dialogVisible: true,
      settings: {
        offlineShow: false
      }

    }
  },
  computed:{

  },
  methods: {


    // 关闭按钮
    close() {
      this.$emit('changeShow', 8)
      // for (const key in this.settingForm) {
      //   this.settingForm[key] = null;
      // }
    },

    // 取消按钮
    cancel() {
      // 弹框隐藏
      this.$emit('changeShow', 8)
    },

    // 确定按钮
    confirm() {
      // this.$store.commit("SET_COMMON_SETTING", this.settings)
      this.$emit('changeShow', 8)
      // 弹框隐藏
      // this.$emit('changeShow', 1)
    },

  },
  created(){
    this.settings= this.$store.getters.commonSetting;
  },
  mounted() {

  },


}
</script>

<style scoped>
.el-dialog__wrapper{
  width: 100vw;
  height: 100vh;
}
/deep/.el-dialog{
  margin:0 !important;
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  height: 240px;
}
/deep/.el-dialog, .el-pager li{
  background: #17264c;
}
/* 顶部文字区域 */
.setting-text {
  padding-top: 40px;
  text-align: center;
  font-size: 18px;
  color: #fff;
}

.setting-form {
  margin: 20px 30px 0 30px;
}

.el-form {
  width: 100%;
}

.el-form-item {
  margin-bottom: 20px;
}

/deep/ .el-form-item__label {
  height: 30px;
  line-height: 30px;
  color: #fff;
  margin-bottom: 10px;
  font-size: 18px;
  padding: 0;
}


/deep/ .el-form > div:nth-child(1) > .el-form-item__content {
  display: flex;
  justify-content: flex-end;
  height: 30px;
  align-items: center;
  /*line-height: 30px;*/
}
/deep/ .el-select .el-input__inner {
  margin-left: 15px;
  /*color: #EAEAEA4D!important;*/
  background: #17264c !important;
  border: 1px solid rgba(91, 163, 237, 0.50) !important;
  border-radius: 8px;
}

.text-warning {
  height: 188px;
  display: flex;
  justify-content: flex-end;
  margin-left: 20px;
  flex-direction: column;
}

.text-warning > div {
  height: 25px;
  line-height: 25px;
  font-size: 18px;
  color: #EAEAEA4D;
  font-weight: 400;
  letter-spacing: 1px;
}

/* 按钮区域 */
.setting-btn {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.setting-btn > .el-button:nth-child(1) {
  width: 128px;
  height: 47px;
  opacity: 1;
  border-color: #3e4f77;
  background: #3e4f77;
  border-radius: 4px;
}

.setting-btn > .el-button:nth-child(2) {
  width: 128px;
  height: 47px;
  opacity: 1;
  border-color: #0364cd;
  background: #0364cd;
  border-radius: 4px;
  margin-left: 50px;
}
</style>
<style>
.setting {
  height: 565px;
  border-radius: 7px;
  background: #17264C;
  border: 1px solid #5BA3ED4D;
  /*margin-top: 0!important;*/
  /*position: absolute;*/
  /*top: 50%;*/
  /*left: 50%;*/
  /*transform: translate(-50%, -50%);*/
}

.setting > div:nth-child(1) {
  padding: 0;
}

.setting > div:nth-child(1) .el-dialog__headerbtn {
  font-size: 22px;
}

.setting > div:nth-child(2) {
  padding: 0;
}

.setting > div:nth-child(3) {
  padding: 0;
}

/*弹窗自定义 动画*/
.el-dialog__wrapper {
  transition-duration: 0s;
}

.dialog-fade-enter-active {
  animation: none !important;
}

.dialog-fade-leave-active {
  /*定义 退出时的 动画 过渡时间*/
  transition-duration: 0s !important;
  animation: none !important;
}

.dialog-fade-enter-active .el-dialog,
.dialog-fade-leave-active .el-dialog {
  /*设置 填充模式   此处的值为 当动画完成后，保持最后一个属性值（在最后一个关键帧中定义）*/
  animation-fill-mode: none;
}

.dialog-fade-enter-active .el-dialog {
  /* 进入自定义动画*/
  animation-name: none;
}

.dialog-fade-leave-active .el-dialog {
  /* 退出 自定义动画*/
  animation-name: none;
}

</style>
