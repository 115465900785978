<template>
  <div class="box">
    <!--  点击  -->
    <div class="container" v-show="overlayType === 0">
      <div class="title ">
        <div class="left hidden-font" :title="deviceAlarm.deviceInfo.deviceName">{{ deviceInfo.deviceName || '无' }}</div>
        <div class="right" :style="{background: renderBgColor(position.sbzt)}">{{ renderText(position.sbzt) }}</div>
        <!--        <div :style="{backgroundColor: renderBgColor(item.sbzt)}">{{ renderText(item.sbzt) }}</div>-->
      </div>
      <div class="text hidden-font" :title="deviceInfo.regionName">所属单位：{{ deviceInfo.regionName || '无' }}</div>
      <div class="text hidden-font" :title="deviceInfo.address">设备位置：{{ deviceInfo.address || '无' }}</div>
      <div class="text hidden-font" :title="deviceInfo.contacts">联系人：{{  deviceInfo.contacts || '无' }}</div>
      <div class="text hidden-font" :title="deviceInfo.phone">联系电话：{{  deviceInfo.phone || '无' }}</div>
      <div class="text hidden-font text-last" :title="deviceInfo.alarmSize">告警总次数：{{   deviceInfo.alarmSize || '0' }}</div>
      <div class="text" @click="openVideo">查看告警视频</div>
      <div class="container__arrow container__arrow--bc" ></div>
    </div>

    <!--  告警  -->
    <div class="container-warn" v-show="overlayType === 1">
      <div class="title ">
        <div class="left hidden-font" :title="deviceInfo.deviceName || '无'">{{ deviceAlarm.deviceInfo.deviceName || '无' }}</div>
        <div class="right" >告警</div>
        <!--        <div :style="{backgroundColor: renderBgColor(item.sbzt)}">{{ renderText(item.sbzt) }}</div>-->
      </div>
      <div class="text hidden-font" :title="deviceAlarm.deviceInfo.regionName">所属单位：{{ deviceAlarm.deviceInfo.regionName || '无' }}</div>
      <div class="text hidden-font" :title="deviceAlarm.deviceInfo.address">设备位置：{{ deviceAlarm.deviceInfo.address || '无' }}</div>
      <div class="text hidden-font" :title="deviceInfo.contacts">联系人：{{ deviceInfo.contacts || '无' }}</div>
      <div class="text hidden-font" :title="deviceInfo.phone">联系电话：{{ deviceInfo.phone || '无' }}</div>
      <div class="text hidden-font" :title="deviceAlarm.alarmInfo.jcsj">告警时间：{{ deviceAlarm.alarmInfo.jcsj || '无' }}</div>
      <div class="text hidden-font" :title="deviceAlarm.alarmInfo.statusValue">告警类型：{{ deviceAlarm.alarmInfo.statusValue || '无' }}</div>
      <div class="text hidden-font" :title="deviceAlarm.alarmInfo.sensorName">告警传感：{{ deviceAlarm.alarmInfo.sensorName || '无' }}</div>
      <div class="text hidden-font"
           :title="deviceAlarm.alarmInfo.valueFormat + deviceAlarm.sensorInfo.unit"
      >
        告警数据：{{ deviceAlarm.alarmInfo.valueFormat + deviceAlarm.sensorInfo.unit || '无' }}
      </div>

      <div class="text" @click="openVideo">查看告警视频</div>
      <div class="btn" @click="dealWarning">处理告警</div>
      <div class="container__arrow container__arrow--bc" ></div>
    </div>

    <!--  搜索  -->
    <div class="container" v-show="overlayType === 2">
      <div class="title ">
        <div class="left hidden-font" :title="searchDeviceInfo.deviceName || '无'">{{ searchDeviceInfo.deviceName || '无' }}</div>
        <div class="right" :style="{background: renderBgColor(position.sbzt)}">{{ renderText(position.sbzt) }}</div>
        <!--        <div :style="{backgroundColor: renderBgColor(item.sbzt)}">{{ renderText(item.sbzt) }}</div>-->
      </div>
      <div class="text hidden-font" :title="searchDeviceInfo.regionName">所属单位：{{ searchDeviceInfo.regionName || '无' }}</div>
      <div class="text hidden-font" :title="searchDeviceInfo.address">设备位置：{{ searchDeviceInfo.address || '无' }}</div>
      <div class="text hidden-font" :title="deviceInfo.contacts">联系人：{{  deviceInfo.contacts || '无' }}</div>
      <div class="text hidden-font" :title="deviceInfo.phone">联系电话：{{  deviceInfo.phone || '无' }}</div>
      <div class="text hidden-font" :title="deviceInfo.alarmSize">告警总次数：{{   deviceInfo.alarmSize || '0' }}</div>
      <div class="container__arrow container__arrow--bc" ></div>
    </div>
    <el-dialog
        v-if="isVisibleVideo"
        v-model:visible="isVisibleVideo"
        left
        width="1000px"
        @closed="videoDialogClose"
        title="告警联动视频"
        :close-on-click-modal="false"
    >
      <JessibucaDemo ref="video" :video-url="videoUrl" />
      <!--      <monitor-vedio-play v-if="isVisibleVedio" :rowItemData="rowItemData" @onClose="videoDialogColse" ref="video"/>-->
      <!--      <video id="videoElement" autoplay controls width="960px" height="500px"></video>-->
    </el-dialog>
  </div>

</template>

<script>
import bus from "@/utils/eventBus/bus";
import JessibucaDemo from "@/component/JessibucaDemo";
export default {
  name: "MyOverlayCard",
  props: {
  },
  data(){
    return{
      interval: null,
      //
      alarmBody:{
        page: 1,
        pageSize: 20,
        alarmType: 1,
        deviceId: null
      },
      position: {},
      isVisibleVideo: false,
      videoUrl: ""
    }
  },
  computed:{
    deviceId(){
      return this.$store.getters.deviceId;
    },
    deviceInfo(){
      return this.$store.getters.deviceInfo;
    },
    overlayType(){
      return this.$store.getters.overlayType;
    },
    deviceAlarm(){
      return this.$store.getters.deviceAlarm;
    },
    searchDeviceInfo(){
      return this.$store.getters.searchDeviceInfo;
    },

  },
  methods:{
    init(position){
      this.position = JSON.parse(JSON.stringify(position))
    },
    // 显示overlay颜色
    renderBgColor(sbzt){
      switch (sbzt){
        case 1:
          return '#4fc164';
        case 2:
          return '#a0a0a0';
        case 3:
          return '#EF7818';
        case 4:
          return '#FD3838';
        case 5:
          return '#4E95F7';
      }
    },
    // 显示overlay文字
    renderText(sbzt){
      switch (sbzt){
        case 1:
          return '正常';
        case 2:
          return '掉线';
        case 3:
          return '故障';
        case 4:
          return '告警';
        case 5:
          return '休眠';
      }
    },
    // 定时获取实时数据
    startARequestRealtimeInterval(id) {
      this.interval = setInterval(() => {
        this.$store.dispatch('GET_SENSOR_RT_DATA_BY_ID', id)
      },  30 * 1000);
    },
    // 获取设备告警信息
    getAlarmById(id){
      this.alarmBody.deviceId = id;
      this.$store.dispatch('GET_ALARM_DATA', this.alarmBody)
    },
    // 打开处理警告弹框
    dealWarning(event){
      // 阻止点击事件穿透
      event.stopPropagation();
      // state 0处理告警 1实时告警
      bus.$emit('dealDialog', {show: true, id: this.deviceId, state: 0});
    },
    openVideo(){

    },
    videoDialogClose(){
      this.isVisibleVideo = false;
    }
  },
  watch: {
    // 监听vuex中设备id的值 只要设备id发生变化 执行获取设备信息的方法
    "$store.getters.deviceId": {
      handler(newVal) {
        // 清除上一个定时器
        clearInterval(this.interval)
        // 根据设备id请求数据
        this.$store.dispatch('GET_DEVICE_INFO_BY_ID', newVal);
        this.$store.dispatch('GET_SENSOR_RT_DATA_BY_ID', newVal);
        this.$store.dispatch('GET_DEVICE_SENSOR_BY_ID', newVal);
        this.$store.dispatch('GET_DEVICE_SENSOR_HISTORY_BY_ID', newVal);
        this.startARequestRealtimeInterval(newVal);
        this.getAlarmById(newVal);
      }
    },
  },
}
</script>

<style lang="less" scoped>
.box{

}

.container {
  width: 366px !important;

  height: 205px;
  /* Border */
  border: 1px solid #5ba3ed;
  box-shadow: 0px 7px 21px 0px rgba(62,154,249,0.42);
  background: rgba(19,32,64,0.91);
  border-radius: 3px;
  /* Used to position the arrow */
  position: relative;

  .title, .text{
    color: #fff;
  }

  .title{
    display: flex;
    width: 366px;
    height: 50px;
    padding: 10px 16px;
    justify-content: space-between;
    letter-spacing: 1px;
    .left{
      font-size: 18px;
      font-weight: 600;
      //padding: 20px;
      line-height: 30px;
      width: 260px;
      height: 100%;
      text-align: left;

    }
    .right{
      font-size: 14px;
      width: 63px;
      height: 29px;
      line-height: 29px;
      border-radius: 3px;
      text-align: center;
    }
  }
  .text{
    font-size: 14px;
    padding: 0 16px 10px 16px;
    text-align: left;
    font-weight: 600;
    letter-spacing: 1px;
  }
  // 倒三角
  .container__arrow {
    /* Size */
    height: 16px;
    width: 16px;

    background-color: #FFF;
    position: absolute;
  }

  .container__arrow--bc {
    /* Position at the top left corner */
    bottom: -16px;
    left: 50%;
    background: rgba(19,32,64,0.91);
    opacity: .91;
    /* Border */

    border-bottom: 1px solid #5ba3ed;
    border-right: 1px solid #5ba3ed;
    transform: translate(-50%, -50%) rotate(45deg);
  }

}

.container-warn {
  width: 366px !important;

  height: 380px;
  /* Border */
  background: #772427;
  border: 1px solid #d44b4b;
  border-radius: 3px;
  box-shadow: 0px 7px 21px 0px rgba(62,154,249,0.42);
  /* Used to position the arrow */
  position: relative;

  .title, .text{
    color: #fff;
  }

  .title{
    display: flex;
    width: 366px;
    height: 50px;
    padding: 10px 16px;
    justify-content: space-between;
    letter-spacing: 1px;
    .left{
      font-size: 18px;
      font-weight: 600;
      //padding: 20px;
      line-height: 30px;
      width: 260px;
      height: 100%;
      text-align: left;

    }
    .right{
      font-size: 14px;
      width: 63px;
      height: 29px;
      line-height: 29px;
      border-radius: 5px;
      text-align: center;
      background: #d65050;
    }
  }
  .text{
    font-size: 14px;
    padding: 0 16px 10px 16px;
    text-align: left;
    font-weight: 600;
    letter-spacing: 1px;
  }
  .btn{
      width: 323px;
      height: 44px;
      opacity: 1;
      background: #d81e06;
      border-radius: 6px;
      margin: 10px auto;
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      color: #ffffff;
      line-height: 44px;
      letter-spacing: 1px;
      cursor: pointer;
  }
  // 倒三角
  .container__arrow {
    /* Size */
    height: 16px;
    width: 16px;

    background-color: #FFF;
    position: absolute;
  }

  .container__arrow--bc {
    /* Position at the top left corner */
    bottom: -16px;
    left: 50%;
    background: #772427;
    opacity: .91;
    /* Border */
    border-bottom: 1px solid #d44b4b;
    border-right: 1px solid #d44b4b;
    transform: translate(-50%, -50%) rotate(45deg);
  }

}

</style>
