import { render, staticRenderFns } from "./RecordDialog.vue?vue&type=template&id=f28510f8&scoped=true&"
import script from "./RecordDialog.vue?vue&type=script&lang=js&"
export * from "./RecordDialog.vue?vue&type=script&lang=js&"
import style0 from "./RecordDialog.vue?vue&type=style&index=0&id=f28510f8&scoped=true&lang=css&"
import style1 from "./RecordDialog.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f28510f8",
  null
  
)

export default component.exports