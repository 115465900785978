<template>
  <el-dialog
      :visible.sync="show"
      width="1300px"
      custom-class="setting"
      :close-on-click-modal="false"
      :before-close="close"
      z-index="100"
  >

    <div style="display: flex">

      <div style="color: #ffffff;position: absolute; top:10px; left: 20px">
        监控名称：{{monitor.monitorDeviceName}}
      </div>
      <div style="color: #ffffff;position: absolute; top:24px; left: 20px">
        关联设备名称：{{ device.deviceName}} 关联设备地址：{{device.address}}
      </div>
      <div style="width: 1000px;"


      >
        <JessibucaDemo ref="video" style="margin-top: 50px" :video-url="videoUrl"  />
        <div style="display: flex">
          <el-card style="margin-top: 8px; color: #fff; width: 200px; height: 100px; margin-left: 5px; margin-left: 5px;background-color: #0D1B58; padding: 0" v-for="item in videoRelations" :key="item.id">
            <div>{{item.device.deviceName}}</div>
            <div>指令下发：</div>
                      <div style="height: 30px; display: flex; justify-content: space-between;align-items: center">


                          <el-tag  size="mini"  v-for="it in item.prefix"  :key="item.id"  @click="deviceOperation(item.device,it.url)">
                            {{it.name}}
                          </el-tag>
                          <el-tag  size="mini"  @click="changeSendType(item.relation)" >{{ item.relation.operateType == 0 ? '自动下发' : '手动下发'}}</el-tag>
                        </div>

          </el-card>
        </div>

      </div>
      <div class="clean" style="width: 320px; height: 630px; overflow-y: auto; margin-top: 50px">
        <div v-for="item in list" :key="item.id" style="display: flex">
          <div>
            <img :src="item.picImageTwo" preview="1" style="width: 100px; height: 70px; ">
          </div>
          <div>

            <div style="color: #ffffff">告警时间：{{item.warnTime}}</div>
            <div style="color: #ffffff">告警类型：{{item.ruleText}}</div>
            <div style="color: #ffffff">识别对象：{{item.objType || "未知"}}</div>
          </div>
        </div>
      </div>
    </div>

  </el-dialog>
</template>

<script>
import {Message} from 'element-ui'
import {axiosServer} from "@/api/judge";
import baseUrl from "@/api/baseUrl";
import {closeAlarm} from "@/api/impl/alarm";
import bus from "@/utils/eventBus/bus";
import JessibucaDemo from "@/component/JessibucaDemo";
import {getDeviceById, getLogByHostCode} from "@/api/impl/device";
export default {
  name: "VideoDialog",
  components: {
    JessibucaDemo
  },
  props:{
    show:{
      type: Boolean,
      default: false
    },
    state: {
      type: Number,
      default: 0
    },
    deviceId: {
      type:Number,
      default: 0
    }

  },
  data() {
    return {
      action: '',
      labelWidth: '150px',
      // 实时警告
      reqsBody: {
       body: {
         closed: 1,
         page: 1,
         alarmType: 1,
         pageSize: 100,
       },
        type: 1
      },
      isVisibleVideo: false,
      videoUrl: "",
      list: [

      ],
      monitor: {},
      device:{
        deviceName: "",
        address: ""
      },
      videoRelations: []
    }
  },
  computed:{
  },
  // 上传图片成功回调方法
  methods: {
    // 关闭按钮
    close() {
      this.$emit('changeShow', 9)
      this.$refs.video.destroy();
    },
    playVideo(){
      this.$refs.video.play(this.videoUrl);
    },
    playVideoUrl(url){
      console.log("==videoUrl==", url)
      this.videoUrl = url;
      this.$refs.video.play(url);
    },
    // 取消按钮
    cancel() {
      // 弹框隐藏
      this.$emit('changeShow', 9)
      this.$refs.video.destroy();
    },
    // 确定按钮
    confirm() {
      this.$emit('changeShow', 9)
    },
    getAiList(hostCode, page, pageSize){
      getLogByHostCode({hostCode: hostCode, page: page, pageSize: pageSize}).then(res => {
        // console.log("getLogByHostCode", res)
        this.list = res.data.records;
        this.$previewRefresh();
      })
    },
    getDevice(id){

      getDeviceById(id).then(res => {
        this.device = res.device

      })
    },
    deviceOperation(device,operation, type){
      console.log("device",device)
      this.$message.info("正在下发")
      switch (operation){
        case 'closeVoice':
          axiosServer({
            url:'/jk/prefix-server/to-prefix/clearSound',
            type: 'get',
            params: {
              // 20658
              deviceId: device.deviceId,
              type: 0
            }
          }).then(res => {
            if (res.data.code === 0){
              this.$message.success(res.data.resultMsg)
              this.$store.commit("SET_PREFIX_OPERATION_ID", res.data.id)
            }else {
              this.$message.error(res.data.resultMsg)
            }
          }).catch(e => {
            this.$message.error('发生错误' + e)
          })
          break;
        case 'closeSwitch':
          axiosServer({
            url:'/jk/prefix-server/to-prefix/controlSwitch',
            type: 'get',
            params: {
              // 20658
              deviceId: device.deviceId,
              type: 1
            }
          }).then(res => {
            if (res.data.code === 0){
              this.$message.success(res.data.resultMsg)
              this.$store.commit("SET_PREFIX_OPERATION_ID", res.data.id)
            }else {
              this.$message.error(res.data.resultMsg)
            }
          }).catch(e => {
            this.$message.error('发生错误' + e)
          })
          break;
        case 'openVoice':
          axiosServer({
            url:'/jk/prefix-server/to-prefix/clearSound',
            type: 'get',
            params: {
              // 20658
              deviceId: device.deviceId,
              type: 1
            }
          }).then(res => {
            if (res.data.code === 0){
              this.$message.success(res.data.resultMsg)
              this.$store.commit("SET_PREFIX_OPERATION_ID", res.data.id)
            }else {
              this.$message.error(res.data.resultMsg)
            }
          }).catch(e => {
            this.$message.error('发生错误' + e)
          })
          break;
        case 'openSwitch':
          axiosServer({
            url:'/jk/prefix-server/to-prefix/controlSwitch',
            type: 'get',
            params: {
              // 20658
              deviceId: device.deviceId,
              type: 0
            }
          }).then(res => {
            if (res.data.code === 0){
              this.$message.success(res.data.resultMsg)
              this.$store.commit("SET_PREFIX_OPERATION_ID", res.data.id)
            }else {
              this.$message.error(res.data.resultMsg)
            }
          }).catch(e => {
            this.$message.error('发生错误' + e)
          })
          break;
        case 'reset':
          axiosServer({
            url:'/jk/prefix-server/to-prefix/reset',
            type: 'get',
            params: {
              // 20658
              deviceId: device.deviceId,
            }
          }).then(res => {
            if (res.data.code === 0){
              this.$message.success(res.data.resultMsg);
              this.$store.commit("SET_PREFIX_OPERATION_ID", res.data.id)
            }else {
              this.$message.error(res.data.resultMsg)
            }
          }).catch(e => {
            this.$message.error('发生错误' + e)
          })
          break;
      }

    },
    getVideoRelationDevice(id){
      axiosServer({
        url: "/v3/monitor/relation/device/info",
        type: "POST",
        params: {
          monitorId: id,
        }
      }).then(res => {
        console.log("video",res)
        this.videoRelations = res.data.list;
      })
    },
    changeSendType(item){
      this.$confirm('正在操作指令下发操作,更换成自动下发时是一个危险操作,是否需要执行', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        axiosServer({
          url: "/v3/monitor/relation/device/operateType",
          type: "POST",
          params: item
        }).then(res => {
          // console.log("video",res)
          // this.videoRelations = res.data.list;
          this.getVideoRelationDevice(item.monitorId);
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    }
  },
  mounted(){
    bus.$on('requestAiAlarm', res => {
      console.log("接收ai告警", res)
      if (this.monitor.hostCode == res.hostCode){
        this.getAiList(this.monitor.hostCode, 1, 20)
      }
    })
  },
  watch:{
    show: {
      handler(newValue, oldValue){
        console.log("videoDialog", newValue,oldValue)
        // if (newValue){
        //   this.$nextTick(() => {
        //     // this.playVideo();
        //   })
        // }
        if (newValue !== oldValue){
          this.$refs.video.stopCamera(this.videoUrl);
        }
      },

    }
  },
  beforeDestroy() {
    // this.$refs.video.stopCamera(this.videoUrl);
  }
}
</script>

<style scoped>
.el-dialog__wrapper{
  width: 100vw;
  height: 100vh;

}
/deep/.el-dialog{
  height: auto;
  background: #17264c;
  height: 700px;
  margin:0 !important;
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
}
/deep/ .el-pager li{
  background: #17264c;
}
/* 顶部文字区域 */
.setting-text {
  padding-top: 40px;
  text-align: center;
  font-size: 18px;
  color: #fff;
}

/* 表单区域 */

.deal-form {
  margin: 20px 30px 0 10px;
}

.el-form {
  width: 100%;
}

.el-form-item {
  margin-bottom: 10px;
}

/deep/ .el-form-item__label {
  /*height: 30px;*/
  line-height: 30px;
  color: #fff;
  /*margin-right: 20px;*/
  font-size: 18px;
  padding: 0 20px 0 0;
}

.text {
  height: 30px;
  line-height: 30px;
  color: #fff;
  font-size: 18px;
}

/deep/ .el-input__inner {
  /*width: 772px;*/
  height: 48px;
  background: #17264c !important;
  border: 1px solid rgba(91, 163, 237, 0.50) !important;
  border-radius: 8px;
}

/*/deep/ .el-select .el-input__inner {*/

/*  !*color: #EAEAEA4D!important;*!*/
/*  background: #17264c !important;*/
/*  border: 1px solid rgba(91, 163, 237, 0.50) !important;*/
/*  border-radius: 8px;*/
/*}*/

/*/deep/ .el-input__prefix, /deep/ .el-input__suffix {*/
/*  top: 0px;*/
/*}*/

.avatar-uploader {
  width: 188px;
  height: 188px;
  background: #17264c;
  border: 1px solid rgba(91, 163, 237, 0.50);
  border-radius: 8px;
  box-sizing: border-box;
}

.avatar-uploader-icon {
  font-size: 18px;
  color: #5BA3ED;
  width: 188px;
  height: 188px;
  line-height: 188px;
  text-align: center;
}

.upload /deep/ .el-form-item__content {
  display: flex;
}

.avatar {
  width: 186px;
  height: 186px;
  object-fit: cover;
  object-position: top left;
  border-radius: 8px;
}

.text-warning {
  height: 188px;
  display: flex;
  justify-content: flex-end;
  margin-left: 20px;
  flex-direction: column;
}

.text-warning div {
  height: 25px;
  line-height: 25px;
  font-size: 18px;
  color: #EAEAEA4D;
  font-weight: 400;
  letter-spacing: 1px;
}

/*按钮*/
.deal-button {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  margin-bottom: 61px;
  padding-top: 170px;
}

.el-button {
  width: 411px;
  height: 49px;
  opacity: 1;
  color: #fff;
  background: #0364cd;
  border-color: #0364cd;
  border-radius: 7px;
  font-size: 15px;
}
.el-button--primary:focus, .el-button--primary:hover{
  border-color: #046cdc;
  background: #046cdc;
}
.clean::-webkit-scrollbar{
  display: none;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
}
</style>
<style>
.warning {

  border-radius: 7px;
  background: #17264C;
  border: 1px solid #5BA3ED4D;
  margin-top: 0vh !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.warning > div:nth-child(1) {
  padding: 0;
}

.warning > div:nth-child(1) .el-dialog__headerbtn {
  font-size: 22px;
}

.warning > div:nth-child(2) {
  padding: 0;
}

.warning > div:nth-child(3) {
  padding: 0;
}

</style>
