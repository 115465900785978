import axios from '@/api/axios'
import { method } from '@/api/method'

export function axiosServer ({
  url = '',
  type = method.GET,
  params = {},
}) {
  if (typeof options === 'object') {
    console.log(params)
  }
  const config1 = {
    method: type,
    url: url,
    data: params,
  }
  if (type === method.GET) {
    config1.params = params
  }
  return axios(config1)
}
