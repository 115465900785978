const getters = {
    // device
    deviceId: state => state.device.deviceId,
    deviceInfo: state => state.device.deviceInfo,
    deviceDetailsShow: state => state.device.deviceDetailsShow,
    deviceCompanyShow: state => state.device.deviceCompanyShow,
    expand: state => state.device.expand,

    sensorHistory: state => state.device.sensorHistory,
    deviceSensor: state => state.device.deviceSensor,
    realtimeData: state => state.device.realtimeData,
    sensorRtData: state => state.device.sensorRtData,
    deviceNum: state => state.device.deviceNum,

    overlayType: state => state.device.overlayType,
    deviceAlarm: state => state.device.deviceAlarm,
    searchDeviceInfo: state => state.device.searchDeviceInfo,
    sbzt: state => state.device.sbzt,

    comDevice: state => state.device.comDevice,
    // table
    warnData: state => state.table.warnData,
    warnLoad: state => state.table.warnLoad,
    warnTotal: state => state.table.warnTotal,
    monitorComData: state => state.table.monitorComData,
    monitorLoad: state => state.table.monitorLoad,
    deviceAlarmInfo: state => state.table.deviceAlarmInfo,
    alarmLoad: state=> state.table.alarmLoad,
    // user
    settingInfo: state => state.user.settingInfo,
    commonSetting: state => state.user.commonSetting,

}
export default getters
