import {
  isEmpty
} from '../Objects'

/**
 *
 * 解析故障详情
 *
 */
export function parseDeviceFaultInfo(detectorState, clientState, deviceStatus, lineState) {
  //大状态暂时不判断，因为判断故障的详情的都是已经确定是故障
  // console.log("clientState = " + clientState + "  deviceStatus = " + deviceStatus + " lineState= " + lineState)
  let b1 = getBite(clientState, 1)
  let b4 = getBite(clientState, 4)
  let b5 = getBite(clientState, 5)
  let b6 = getBite(clientState, 6)

  let result = ""

  if (b1 == 1) {
    result += "通讯终端有故障 "
  }

  if (b4 == 1) {
    result += "主电故障（停电） "
  }

  if (b5 == 1) {
    result += "备电故障（欠压） "
  }

  if (b6 == 1) {
    result += "总线故障 "
  }


  let bb1 = getBite(deviceStatus, 1)
  if (bb1 == 1) {
    result += "部件终端有故障 "
  }


  let g1 = getBite(lineState, 5)
  if (g1 == 1) {
    result += "短路或断路或开关故障 "
  }

  return result;
}


/**
 * 版本号V3.0的通讯终端状态域格式定义(客户机状态 client_state)
 */
export function parseClientState(value) {

  if (isEmpty(value)) {
    return "未知状态"
  }

  let b0 = getBite(value, 0)
  let b1 = getBite(value, 1)
  let b2 = getBite(value, 2)
  let b3 = getBite(value, 3)
  let b4 = getBite(value, 4)
  let b5 = getBite(value, 5)
  let b6 = getBite(value, 6)
  let b7 = getBite(value, 7)
  let b8 = getBite(value, 8)
  let b9 = getBite(value, 9)

  let result = ""

  if (b0 == 1) {
    result += "测试状态 "
  }

  if (b1 == 1) {
    result += "通讯终端有故障 "
  }

  if (b2 == 1) {
    result += "配置改变 "
  }

  if (b3 == 1) {
    result += "等待对时 "
  }

  if (b4 == 1) {
    result += "主电故障（停电） "
  }

  if (b5 == 1) {
    result += "备电故障（欠压） "
  }

  if (b6 == 1) {
    result += "总线故障 "
  }

  if (b7 == 1) {
    result += "停止使用 "
  }

  // if(b8 == 1){
  //   result += "报警 "
  // }

  if (b9 == 1) {
    result += "休眠 "
  }

  // console.log("----- clientState = " + result)

  if (result === "") {
    result = "正常"
  }

  return result
}

/**
 * 临时补救
 * @param lineState
 */
export function parseOneLine(lineState) {
  let alarmType = getBite(lineState, 1)
  let status = getBite(lineState, 0)
  // console.log("报警类型 = " + SENSOR_ALARM_TYPE[alarmType])
  // statusValue[0] = SENSOR_ALARM_TYPE[alarmType]
  let alarmLevel = getBite(lineState, 2)

  // statusValue[2] = alarmLevel //告警等级 0 一般  1 紧急

  let upLimit = getBite(lineState, 4)
  let faultState = getBite(lineState, 5)
  let downState = getBite(lineState, 6)

  let formatValue = ""
  let formatStatus = 0

  // if(alarmType == 0 && upLimit == 0 && faultState == 0 && downState == 0){
  //   formatValue = "正常"
  // } else {


  if (status == 1) {
    formatValue += " 测试状态"
  }

  if (upLimit == 1) {
    formatStatus = 1
    formatValue += " 越上限报警"
  }

  if (faultState == 1) {
    formatStatus = 2
    formatValue = formatValue + " 短路或断路故障"
  }

  if (downState == 1) {
    formatStatus = 3
    formatValue = formatValue + " 越下限报警"
  }

  //模块状态
  let modalStatus = getBite(lineState, 8)
  //监管状态
  let superviseStatus = getBite(lineState, 9)
  //反馈状态
  let feedbackStatus = getBite(lineState, 10)

  if (modalStatus == 1) {
    formatValue = formatValue + " 模块开启"
  }

  if (superviseStatus == 1) {
    formatValue = formatValue + " 监管关闭"
  }

  if (feedbackStatus == 1) {
    formatValue = formatValue + " 反馈取消"
  }
  // }


  if (formatValue === "") {
    formatValue = "正常"
  }

  return [formatStatus, formatValue]
}
/**
 * 解析传感器的状态
 */
export function parseSensorStatus(originalData) {

  let results = []
  for (let i = 1; i < 64; i++) {
    let lineStateName = 'line' + i + 'State'
    let lineValueName = 'line' + i + 'Value'
    let lineState = originalData[lineStateName]
    let lineValue = originalData[lineValueName]

    if (!isEmpty(lineState)) {
      let result = []

      let sensorName = lineState >>> 24 & 63
      let sensorNameValue = SENSOR_NAME[sensorName]

      result[0] = sensorNameValue

      // console.log(sensorNameValue)

      let sensorDigital = lineState >>> 22 & 3
      // console.log("模拟量小数位数 = " + sensorDigital  + " value = " + lineValue)

      let sensorNo = lineState >>> 16 & 63
      // console.log("模拟量编号 = " + sensorNo)
      result[3] = sensorNo


      let statusValue = []

      statusValue[2] = 0

      let useStatus = getBite(lineState, 7)
      // console.log("使用状态 = " + SENSOR_USE_TYPE[useStatus])

      if (useStatus == 0) {

        let alarmState = getBite(lineState, 0)
        // console.log("传感器状态 " + SENSOR_STATUS[alarmState])



        // if (alarmState != 0) { //todo 这个状态都是0

        // Bit0	运行状态	正常	报警
        // Bit1	一般报警	正常	一般报警
        // Bit2	紧急报警	正常	紧急报警
        // Bit3	预留	0	0
        // Bit4	越上限	正常	越上限报警
        // Bit5	故障状态	正常	短路或断路故障
        // Bit6	越下限	正常	越下限报警
        // Bit7	使用状态	启动	停止

        // statusValue[1] = 1

        let alarmType = getBite(lineState, 1)
        let sensorStatus = getBite(lineState, 0)
        // console.log("报警类型 = " + SENSOR_ALARM_TYPE[alarmType])
        statusValue[0] = SENSOR_ALARM_TYPE[alarmType]
        let alarmLevel = getBite(lineState, 2)

        statusValue[2] = alarmLevel //告警等级 0 一般  1 紧急

        let upLimit = getBite(lineState, 4)
        let faultState = getBite(lineState, 5)
        let downState = getBite(lineState, 6)
        //模块状态
        let modalStatus = getBite(lineState, 8)
        //监管状态
        let superviseStatus = getBite(lineState, 9)
        //反馈状态
        let feedbackStatus = getBite(lineState, 10)

        // if(alarmType == 0 && upLimit == 0 && faultState == 0 && downState == 0){
        //   statusValue[0] = "正常"
        //   statusValue[1] = 0
        // } else {

        let formatValue = ""

        if (sensorStatus == 1) {
          formatValue += " 测试状态"
        }

        if (upLimit == 1) {
          formatValue += " 越上限报警"
        }

        if (faultState == 1) {
          formatValue = formatValue + " 短路或断路故障"
        }

        if (downState == 1) {
          formatValue = formatValue + " 越下限报警"
        }

        if (modalStatus == 1) {
          formatValue = formatValue + " 模块开启"
        }

        if (superviseStatus == 1) {
          formatValue = formatValue + " 监管关闭"
        }

        if (feedbackStatus == 1) {
          formatValue = formatValue + " 反馈取消"
        }

        if (formatValue === "") {
          formatValue = "正常"
        }
        statusValue[0] = formatValue
        statusValue[1] = formatValue === "正常" ? 0 : 1
        // }
        /*} else {
          statusValue[0] = "正常"
          statusValue[1] = 0
        }*/
      } else {
        statusValue[0] = "停用"
      }

      result[1] = statusValue
      result[2] = lineValue
      results.push(result)
    }

  }

  return results;

  // console.log(results)

}

const SENSOR_USE_TYPE = ["启动", "停止"]
const SENSOR_FAULT_TYPE = ["正常", "故障"]
const SENSOR_ALARM_LEVEL = ["一般", "紧急"]
const SENSOR_ALARM_TYPE = ["越上限", "越下限"]
const SENSOR_STATUS = ["正常", "告警"]
const SENSOR_NAME = [
  [],
  ['漏电', 'mA', 0, 2000, 0.1],
  ['电流', 'A', 0, 3200, 0.1],
  ['温度', '℃', -45, 200, 0.1],
  ['电压', 'V', 0, 400, 0.1],
  ['水压', 'kPa', 0, 2000, 0.1], //kPa(千帕)
  ['水压', 'MPa', 0, 2, 0.1], //MPa(兆帕)
  ['水位', 'm', 0, 320, 0.01],
  ['烟雾浓度', 'dB/m', 0, 320, 0.01],
  ['气体浓度', '%LEL', 0, 100, 0.1],
  ['流量', 'L/s', 0, 3200, 0.1],
  ['风速', 'm/s', 0, 20, 0.1],
  ['风量', '㎡/min', 0, 3200, 0.1],
  ['时间', 's', 0, 3200, 1],
  ['事件计数', '件', 0, 3200, 1],
]

/**
 * 解析设备的探测器状态信息 device_status
 * @param originalData
 * @returns {string}
 */
export function parseDeviceStatus(sbztlx) {
  // console.log("sbztlx = " + sbztlx)
  let clientStatus = sbztlx

  if (sbztlx == null) {
    return '未知状态'
  }


  let runState = getBite(clientStatus, 0)
  let runStateValue = DEVICE_RUN_STATE[runState]
  // console.log('消防设施部件状态 ' + runState + ' ' + runStateValue)

  /*if(runState == 0){
    return '正常'
  }*/

  // let isOpen = getBite(clientStatus, 15)
  // let deviceOpenValue = DEVICE_OPEN[isOpen]
  // console.log('使用状态 ' + isOpen + ' ' + deviceOpenValue)

  // if(isOpen == 1){
  //   return deviceOpenValue
  // }

  let result = ""

  let deviceFault = getBite(clientStatus, 1)
  let deviceFaultValue = DEVICE_FAULT[deviceFault]
  // console.log('部件故障 ' + deviceFault + ' ' + deviceFaultValue)

  let deviceSetting = getBite(clientStatus, 2)
  let deviceSettingValue = DEVICE_SETTING[deviceSetting]
  // console.log('部件配置 ' + deviceSetting + ' ' + deviceSettingValue)

  let netState = getBite(clientStatus, 4)
  let netStateValue = DEVICE_NET_STATUS[netState]
  // console.log('通讯状态 ' + netState + ' ' + netStateValue)

  if (deviceFault == 1) {
    result = result + " " + deviceFaultValue
  }

  if (runState == 1) {
    result = result + " " + runStateValue
  }

  if (netState == 1) {
    result = result + " " + netStateValue
  }

  let silenceState = getBite(clientStatus, 5)
  let silenceStateValue = DEVICE_SILENCE[silenceState]
  // console.log('消音状态 ' + silenceState + ' ' + silenceStateValue)

  if (silenceState == 1) {
    result = result + " " + silenceStateValue
  }

  let relayState = getBite(clientStatus, 6)
  let relayStateValue = DEVICE_RELAY[relayState]
  // console.log('消音状态 ' + relayState + ' ' + relayStateValue)

  let b8 = getBite(clientStatus, 8)

  let b9 = getBite(clientStatus, 9)
  // let b9Value = DEVICE_RELAY[b9]

  if (b8 == 1) {
    result = result + " 报警"
  }

  if (b9 == 1) {
    result = result + " 模块开启"
  }

  let b10 = getBite(clientStatus, 10)
  // let b10Value = DEVICE_RELAY[b10]

  if (b10 == 1) {
    result = result + " 监管关闭"
  }

  let b11 = getBite(clientStatus, 11)
  // let b11Value = DEVICE_RELAY[b11]
  if (b11 == 1) {
    result = result + " 反馈取消"
  }

  if (relayState == 1) {
    result = result + " " + relayStateValue
  }

  if (isEmpty(result)) return "正常"

  return result
}

const DEVICE_OPEN = ['启动', '停止']
const DEVICE_RELAY = ['继电器控制关闭状态', '继电器控制输出']
const DEVICE_SILENCE = ['未消音', '已消音']
const DEVICE_NET_STATUS = ['通讯正常', '通讯异常']
const DEVICE_SETTING = ['无配置改变', '配置改变']
const DEVICE_FAULT = ['部件终端正常', '部件终端有故障']
const DEVICE_RUN_STATE = ['正常运行状态', '正常运行']

/**
 * 解析通讯装置信息
 * @param originalData
 * @returns {Array}
 */
export function parseCommInfo(originalData) {
  let clientStatus = originalData.clientState
  if (isEmpty(clientStatus)) {
    return []
  }

  let commType = clientStatus >>> 29 & 7
  let commTypeValue = COMM_COMM_TYPE[commType]
  // console.log('通讯方式 ' + commType + ' ' + commTypeValue)

  let deviceType = clientStatus >>> 24 & 31
  let deviceTypeValue = COMM_DEVICE_TYPE[deviceType]
  // console.log('设备类型 ' + deviceType + ' ' + deviceTypeValue)

  let useEnv = clientStatus >>> 20 & 3
  let useEnvValue = COMM_USE_ENV[useEnv]
  // console.log('使用类型 ' + useEnv + ' ' + useEnvValue)

  let powerType = clientStatus >>> 18 & 3
  let powerTypeValue = COMM_POWER_TYPE[powerType]
  // console.log('供电类型 ' + powerType + ' ' + powerTypeValue)

  let netType = clientStatus >>> 16 & 3
  let netTypeValue = COMM_NET_TYPE[netType]
  // console.log('联网类型 ' + netType + ' ' + netTypeValue)

  let runState = getBite(clientStatus, 0)
  let runStateValue = COMM_RUN_STATE[runState]
  // console.log('运行状态 ' + runState + ' ' + runStateValue)

  let clientFault = getBite(clientStatus, 1)
  let clientFaultValue = COMM_CLIENT_FAULT[runState]
  // console.log('终端故障 ' + clientFault + ' ' + clientFaultValue)

  let clientSetting = getBite(clientStatus, 2)
  let clientSettingValue = COMM_CLIENT_SETTING[clientSetting]
  // console.log('终端配置 ' + clientFault + ' ' + clientSettingValue)

  let mainPowerState = getBite(clientStatus, 4)
  let mainPowerStateValue = COMM_MAIN_POWER[mainPowerState]
  // console.log('主电状态 ' + mainPowerState + ' ' + mainPowerStateValue)

  let reservePowerState = getBite(clientStatus, 5)
  let reservePowerStateValue = COMM_RESERVE_POWER[reservePowerState]
  // console.log('备电状态 ' + reservePowerState + ' ' + reservePowerStateValue)

  let busState = getBite(clientStatus, 6)
  let busStateValue = COMM_BUS_state[busState]
  // console.log('总线状态 ' + busState + ' ' + busStateValue)
}

const COMM_BUS_state = ['总线正常', '总线故障']
const COMM_MAIN_POWER = ['主电正常', '主电故障']
const COMM_RESERVE_POWER = ['备电正常', '备电故障（前压）']
const COMM_CLIENT_FAULT = ['通讯终端正常', '通讯终端故障']
const COMM_CLIENT_SETTING = ['无配置改变', '配置改变']
const COMM_RUN_STATE = ['正常', '测试']
const COMM_NET_TYPE = ['直接联网型', '网关联网型', '控制器联网型', '预留']
const COMM_POWER_TYPE = ['仅内部电池供电型', '外部电源供电且配有内部备用电池型', '仅外部供电型', '预留']
const COMM_USE_ENV = ['室内型', '室外型', '预留', '预留']
const COMM_COMM_TYPE = ['2G', '4G', 'WIFI', 'LORA', '预留', '预留', '预留', 'LAN']
const COMM_DEVICE_TYPE = ['保留', 'JC-GR', 'JC-GR/4', 'JC-GR/16', 'JC-MD', 'JC-HR-FR1001', 'JC-7688']

/**
 * 取某一位的值
 * @param num
 * @param index
 * @returns {number}
 */
function getBite(num, index) {
  return (num & (0x1 << index)) >>> index
}
