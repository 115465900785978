<template>
  <el-dialog

      :visible.sync="show"
      width="565px"
      custom-class="setting"
      :close-on-click-modal="false"

      :before-close="close"
  >
    <div class="setting-text">
      密码修改
    </div>
    <div class="setting-form" >
      <el-form
          label-position="top"
          :model="passwordForm"
          :rules="passwordRule"
          ref="passwordForm"
      >
        <el-form-item label="原密码" prop="password">
          <el-input v-model="passwordForm.password" placeholder="请输入原密码"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input v-model="passwordForm.newPassword" placeholder="请输入新密码"></el-input>
        </el-form-item>
        <el-form-item label="再次新密码" prop="confirmPassword">
          <el-input v-model="passwordForm.confirmPassword" placeholder="请再次输入新密码"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="setting-btn">
      <el-button type="primary" @click="cancel">取消</el-button>
      <el-button type="primary" @click="confirm">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {axiosServer} from "@/api/judge";
import {Message} from 'element-ui'
import {clearLoginInfo} from "@/utils";
export default {
  name: "PasswordDialog",
  props:{
    show: Boolean,
  },
  data() {
    let validateConfirmPassword = (rule, value, callback) => {
      if (this.passwordForm.newPassword !== value) {
        callback(new Error("确认密码与新密码不一致"));
      } else {
        callback();
      }
    };
    return {
      dialogVisible: false,
      passwordForm: {
        password: '',
        newPassword: '',
        confirmPassword: ''
      },
      passwordRule: {
        password: [
          { required: true, message: "原密码不能为空", trigger: "blur" }
        ],
        newPassword: [
          { required: true, message: "新密码不能为空", trigger: "blur" }
        ],
        confirmPassword: [
          { required: true, message: "确认密码不能为空", trigger: "blur" },
          { validator: validateConfirmPassword, trigger: "blur" }
        ]
      }
    }
  },

  methods: {
    // 关闭按钮
    close() {
      this.$emit('changeShow', 3)
      // 重置表单
      this.$refs['passwordForm'].resetFields();
    },
    // 取消按钮
    // 取消按钮
    cancel() {
      // 弹框隐藏
      this.$emit('changeShow', 3)
      // 重置表单
      this.$refs['passwordForm'].resetFields();
    },

    // 确定按钮
    confirm() {
      // 提交表单
      this.$refs["passwordForm"].validate(valid => {
        if (valid){
          console.log(this.passwordForm)
          axiosServer({
            url: '/sys/user/password',
            type: 'post',
            params: {
              password: this.passwordForm.password,
              newPassword: this.passwordForm.newPassword
            }
          }).then(res => {
            console.log(res)
            if (res.data.code === 0){
              Message({
                message: '操作成功',
                type: 'success'
              });
              this.$refs['passwordForm'].resetFields();
              clearLoginInfo()
              this.$router.replace('login')

            }else {
              Message({
                message: res.data.msg,
                type: 'error'
              });
              this.$refs['passwordForm'].resetFields();
            }
          })
        }
      })
      // 弹框隐藏
      this.$emit('changeShow', 3)
    },
  }
}
</script>

<style scoped>
.el-dialog__wrapper{
  width: 100vw;
  height: 100vh;

}
/deep/.el-dialog{
  margin:0 !important;
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
}
/deep/.el-dialog, .el-pager li{
  background: #17264c;
}
/* 顶部文字区域 */
.setting-text {
  padding-top: 40px;
  text-align: center;
  font-size: 18px;
  color: #fff;
}

/* 表单区域 */
.setting-form {
  margin: 20px 30px 0 30px;
}

.el-form {
  width: 100%;
}

.el-form-item {
  margin-bottom: 20px;
}

/deep/ .el-form-item__label {
  height: 30px;
  line-height: 30px;
  color: #fff;
  margin-bottom: 10px;
  font-size: 18px;
  padding: 0;
}

/deep/ .el-input__inner {
  height: 48px;
  background: #17264c !important;
  border: 1px solid rgba(91, 163, 237, 0.50) !important;
  border-radius: 8px;
}

/* 按钮区域 */
.setting-btn {
  margin-top: 82px;
  display: flex;
  justify-content: center;
}

.setting-btn > .el-button:nth-child(1) {
  width: 128px;
  height: 47px;
  opacity: 1;
  border-color: #3e4f77;
  background: #3e4f77;
  border-radius: 4px;
}

.setting-btn > .el-button:nth-child(2) {
  width: 128px;
  height: 47px;
  opacity: 1;
  border-color: #0364cd;
  background: #0364cd;
  border-radius: 4px;
  margin-left: 50px;
}
</style>
