<template>
  <div class="bmap-wrapper">
    <el-bmap class="bmap-box"
             :zoom="zoom"
             :center="center"
             :vid="'bmap-vue'"
             :map-style-v2="darkStyle"
    >
      <el-bmap-info-window-custom
       :is-custom="true"
       :title="currentWindow.title"
       :position="currentWindow.position"
       :visible.sync="currentWindow.visible"
       :offset="[0, -15]"
       :enableCloseOnClick="false"
       :enableAutoPan="true"
       :events="{open: ()=>{open()},close: ()=>{close()}}"
       v-if="currentWindow.visible"
     >
       <template>
         <div class="box">
           <!--  点击  -->
           <div class="container" v-show="overlayType === 0">
             <div class="title ">
               <div class="left hidden-font" :title="deviceAlarm.deviceInfo.deviceName">{{ deviceInfo.deviceName || '无' }}</div>
               <div class="right" :style="{background: renderBgColor(position.sbzt)}">{{ renderText(position.sbzt) }}</div>
               <!--        <div :style="{backgroundColor: renderBgColor(item.sbzt)}">{{ renderText(item.sbzt) }}</div>-->
             </div>
             <div class="text hidden-font" :title="deviceInfo.regionName">所属单位：{{ deviceInfo.regionName || '无' }}</div>
             <div class="text hidden-font" :title="deviceInfo.detailAddress">详细位置：{{ deviceInfo.detailAddress || '无' }}</div>
             <div class="text hidden-font" :title="deviceInfo.contacts">联系人：{{  deviceInfo.contacts || '无' }}</div>
             <div class="text hidden-font" :title="deviceInfo.phone">联系电话：{{  deviceInfo.phone || '无' }}</div>
             <div class="text hidden-font text-last" :title="deviceInfo.alarmSize">告警总次数：{{   deviceInfo.alarmSize || '0' }}</div>
             <div class="container__arrow container__arrow--bc" ></div>
           </div>

           <!--  告警  -->
           <div class="container-warn" v-show="overlayType === 1">
             <div class="title ">
               <div class="left hidden-font" :title="deviceInfo.deviceName || '无'">{{ deviceAlarm.deviceInfo.deviceName || '无' }}</div>
               <div class="right" >告警</div>
               <!--        <div :style="{backgroundColor: renderBgColor(item.sbzt)}">{{ renderText(item.sbzt) }}</div>-->
             </div>
             <div class="text hidden-font" :title="deviceAlarm.deviceInfo.regionName">所属单位：{{ deviceAlarm.deviceInfo.regionName || '无' }}</div>
             <div class="text hidden-font" :title="deviceAlarm.deviceInfo.detailAddress">详细位置：{{ deviceAlarm.deviceInfo.detailAddress || '无' }}</div>
             <div class="text hidden-font" :title="deviceInfo.contacts">联系人：{{ deviceInfo.contacts || '无' }}</div>
             <div class="text hidden-font" :title="deviceInfo.phone">联系电话：{{ deviceInfo.phone || '无' }}</div>
             <div class="text hidden-font" :title="deviceAlarm.alarmInfo.jcsj">告警时间：{{ deviceAlarm.alarmInfo.jcsj || '无' }}</div>
             <div class="text hidden-font" :title="deviceAlarm.alarmInfo.statusValue">告警类型：{{ deviceAlarm.alarmInfo.statusValue || '无' }}</div>
             <div class="text hidden-font" :title="deviceAlarm.alarmInfo.sensorName">告警传感：{{ deviceAlarm.alarmInfo.sensorName || '无' }}</div>
             <div class="text hidden-font"
                  :title="deviceAlarm.alarmInfo.valueFormat + deviceAlarm.sensorInfo.unit"
             >
               告警数据：{{ deviceAlarm.alarmInfo.valueFormat + deviceAlarm.sensorInfo.unit || '无' }}
             </div>

             <div class="text">查看告警视频</div>
             <div class="btn" @click="dealWarning">处理告警</div>
             <div class="container__arrow container__arrow--bc" ></div>
           </div>

           <!--  搜索  -->
           <div class="container" v-show="overlayType === 2">
             <div class="title ">
               <div class="left hidden-font" :title="searchDeviceInfo.deviceName || '无'">{{ searchDeviceInfo.deviceName || '无' }}</div>
               <div class="right" :style="{background: renderBgColor(position.sbzt)}">{{ renderText(position.sbzt) }}</div>
               <!--        <div :style="{backgroundColor: renderBgColor(item.sbzt)}">{{ renderText(item.sbzt) }}</div>-->
             </div>
             <div class="text hidden-font" :title="searchDeviceInfo.regionName">所属单位：{{ searchDeviceInfo.regionName || '无' }}</div>
             <div class="text hidden-font" :title="searchDeviceInfo.detailAddress">详细位置：{{ searchDeviceInfo.detailAddress || '无' }}</div>
             <div class="text hidden-font" :title="deviceInfo.contacts">联系人：{{  deviceInfo.contacts || '无' }}</div>
             <div class="text hidden-font" :title="deviceInfo.phone">联系电话：{{  deviceInfo.phone || '无' }}</div>
             <div class="text hidden-font" :title="deviceInfo.alarmSize">告警总次数：{{   deviceInfo.alarmSize || '0' }}</div>
             <div class="container__arrow container__arrow--bc" ></div>
           </div>

         </div>
<!--         <MyOverlayCard ref="card"></MyOverlayCard>-->
       </template>
     </el-bmap-info-window-custom>
      <!--   图层   -->
      <el-bmapv-view>
        <el-bmapv-icon-layer
            ref="layer"
           :height="32"
           :width="32"
           :data="pointData"
           :enable-picked="true"
           :onClick="(e)=>{onClick(e)}"></el-bmapv-icon-layer>
      </el-bmapv-view>
      <el-bmapv-view>
        <el-bmapv-ripple-layer :size="rippleOptions.size"
                               :color="rippleOptions.color"
                               :data="rippleOptions.data"></el-bmapv-ripple-layer>
      </el-bmapv-view>
    </el-bmap>

  </div>

</template>

<script>
import {darkStyle} from './map/mapStyle/drakStyle'
import {getDeviceAlarmInfoLatest, getDeviceMap} from "@/api/impl/device";
import {isEmpty} from "@/utils/Objects";
import MyOverlayCard from "@/views/Layout/Main/MainLeft/components/map/MyOverlayCard";
import bus from "@/utils/eventBus/bus";
import {parseOneLine} from "@/utils/device/DetectorValueParser";
import {SequenceSearch} from '@/utils/searchData'
export default {
  name: 'HelloWorld',
  components:{
    MyOverlayCard
  },
  data() {
    return {
      zoom: 19,
      center: [113.79316, 23.044483],
      pointData: [

      ],
      currentWindow: {
        position: [113.79316, 23.044483],
        content: 'hello world',
        events: {
        },
        visible: false
      },
      // 样式
      darkStyle: darkStyle,
      // 地图是否初始化过
      mapInit: false,
      // 波纹图层
      rippleOptions:{
        color: 'red',
        size: 150,
        duration: 1,
        data: []
      },
      interval: null,
      //
      alarmBody:{
        page: 1,
        pageSize: 20,
        alarmType: 1,
        deviceId: null
      },
      position: {}
    }
  },
  computed:{
    sbzt(){
      return this.$store.getters.sbzt
    },
    deviceId(){
      return this.$store.getters.deviceId;
    },
    deviceInfo(){
      return this.$store.getters.deviceInfo;
    },
    overlayType(){
      return this.$store.getters.overlayType;
    },
    deviceAlarm(){
      return this.$store.getters.deviceAlarm;
    },
    searchDeviceInfo(){
      return this.$store.getters.searchDeviceInfo;
    },
  },
  methods: {
    // 获取点坐标
    LocationRequestDevice(){
      getDeviceMap({ comId: null}).then(res => {
        console.log('getDeviceMap', res)
        if (res.code == 0){
          this.deviceMapLocation = res.data
          // 如果地图没初始化 执行函数
          if (!this.mapInit){
            console.log('fillMapv执行')
            this.fillMapV(0);
          }
        }
      }).catch(e => {
        console.log('getDeviceMap:error', e)
      })
    },
    getMapDevicePoint(sbzt){
      let data = [];
      this.deviceMapLocation.forEach((item) => {
        if (!isEmpty(this.selectDevice)) {
          if (this.selectDevice.deviceId == item.deviceId) {
            this.selectDevice = item;
          }
        }
        if (item.sbzt == sbzt || sbzt == 0) {
          let img = new Image();
          switch (item.sbzt) {
            case 1:
              // img.src = "https://jc-app-store.oss-cn-shenzhen.aliyuncs.com/icon/green.png";
              img.src = require("../../../../../images/green.png");
              break;
            case 2:
              // img.src = "https://jc-app-store.oss-cn-shenzhen.aliyuncs.com/icon/grey.png";
              img.src = require("../../../../../images/grey.png");
              break;
            case 3:
              // img.src = "https://jc-app-store.oss-cn-shenzhen.aliyuncs.com/icon/yellow.png";
              img.src = require("../../../../../images/yellow.png");
              break;
            case 4:
              // img.src = "https://jc-app-store.oss-cn-shenzhen.aliyuncs.com/icon/red.png";
              img.src = require("../../../../../images/red.png");
              break;
            case 5:
              // img.src = "https://jc-app-store.oss-cn-shenzhen.aliyuncs.com/icon/blue.png";
              img.src = require("../../../../../images/blue.png");
              break;
            default:
              // img.src = "https://jc-app-store.oss-cn-shenzhen.aliyuncs.com/icon/grey.png";
              img.src = require("../../../../../images/grey.png");
          }
          data.push({
            geometry: {
              type: 'Point',
              coordinates: [item.lng, item.lat, 1],
            },
            deviceInfo: item,
            properties: {
              icon: img,
            }
          });
        }
      });
      let img = new Image();
      img.src = require("../../../../../images/she.png");
      data.push({
        geometry: {
          type: 'Point',
          coordinates: [113.916016 , 22.576784, 1],
        },
        deviceInfo: {
          deviceId: 0,
          sbzt: 10086,
          type: 1111,
          lng:113.916016 ,
          lat:22.576784
        },
        properties: {
          icon: img,
        }
      });
      return data;
    },
    // 填充点
    fillMapV(sbzt){
      this.pointData = this.getMapDevicePoint(sbzt);
      console.log('this.pointData', this.pointData);
    },
    // 信息框体事件
    onClick(e) {
      if (e.dataItem) {
        if (!this.alarmShow){
          this.currentWindow.visible = false;

          // this.currentWindow.position = [e.dataItem.geometry.coordinates[0] , e.dataItem.geometry.coordinates[1] + 0.00007];
          this.currentWindow.position = [e.dataItem.geometry.coordinates[0] , e.dataItem.geometry.coordinates[1]];
          this.$store.commit('SET_DEVICE_ID', e.dataItem.deviceInfo.deviceId)
          // this.$refs.card.init(e.dataItem.deviceInfo)
          this.position = e.dataItem.deviceInfo;
          this.$store.commit('SET_OVERLAY_CARD_TYPE', 0);
          this.$store.commit('SET_DEVICE_DETAILS_SHOW', true)
          this.$store.commit('SET_EXPAND', false)
          this.$nextTick(() => {
            this.open();
          })
        }else {
          this.$message.warning('请先解除当前告警')
        }
      } else {
        if (!this.alarmShow){
          this.close();
        }else {
          this.$message.warning('请先解除当前告警')
        }
      }
    },
    open(){
      this.currentWindow.visible = true
      // this.$emit('visible', true);
      console.log('open')
    },
    close(){

      this.currentWindow.visible = false;
      // this.$emit('visible', false);
      console.log('close')
    },
    // 获取点
    getOverlay(deviceId){

      this.pointData.map(item => {
        if (item.deviceInfo.deviceId == deviceId){
          // 清除上一个告警图标的波纹图层数据
          this.rippleOptions.data = [];
          let img = new Image();
          img.src = require("../../../../../images/red.png");
          item.properties.oldIcon = item.properties.icon;
          item.properties.icon = img;
          item.deviceInfo.sbzt = 4;
          item.geometry.coordinates = [item.geometry.coordinates[0],item.geometry.coordinates[1], 11];
          this.rippleOptions.data.push(item);
          console.log('===item===', item)
          return;
        }
      })
      // 清除上一个告警图标的波纹图层数据
      // console.log('this.pointData[', this.pointData);
      // console.log('deviceId', deviceId);
      // this.rippleOptions.data = [];
      // let img = new Image();
      // img.src = require("../../../../../images/red.png");
      // let i = SequenceSearch(this.pointData, deviceId, 'deviceId');
      // // console.log('i', i)
      // // this.pointData[i].properties.oldIcon = this.pointData[i].properties.icon;
      // this.pointData[i].properties.icon = img;
      // this.pointData[i].deviceInfo.sbzt = 4;
      // this.pointData[i].geometry.coordinates =  [ this.pointData[i].geometry.coordinates[0], this.pointData[i].geometry.coordinates[1], 11];
      // this.rippleOptions.data.push(this.pointData[i]);

      console.log(this.pointData[i])
    },
    // 地图移动
    mapMove(device, show, status, isMove){
      if (isMove){
        this.close()
        this.$store.commit('SET_DEVICE_ID', device.deviceId)
        // this.bmCenter.lat = device.lat;
        // this.bmCenter.lng = device.lng;
        this.center = [device.lng,device.lat];
        // this.currentWindow.position = [device.lng, device.lat + 0.00007];
        this.currentWindow.position = [device.lng, device.lat];
        this.open()
      }
      // else {
      //   this.$store.commit('SET_DEVICE_ID', device.deviceId)
      //   this.overlayDeviceInfo = {lat: device.lat, lng: device.lng, deviceId: device.deviceId, sbzt: device.sbzt, status: status};
      //   this.open()
      // }
    },
    // 获取告警信息
    async getDeviceAlarmInfo(id, isMove){
      let res = await getDeviceAlarmInfoLatest({deviceId: id});
      console.log('getDeviceAlarmInfoLatest' , res,'告警类型', res.alarm.alarmType === 1 ? 'alarm告警': '故障')
      if (res.code === 0 && res.alarm.alarmType === 1){
        this.mapMove(res.device, true, 1, isMove)
        // 解析值
        let oneLineValue = parseOneLine( res.alarm.gjdj);
        res.alarm.statusValue = oneLineValue[1];
        if ( res.alarm.jcz == 0) {
          if (oneLineValue[0] == 1) {
            //越上限
            res.alarm.valueFormat = "大于" +  res.sensor.maxValue;
          } else if (oneLineValue[0] == 3) {
            //越下限
            res.alarm.valueFormat = "小于" +  res.sensor.minValue;
          }
        }else {
          res.alarm.valueFormat = res.alarm.jcz;
        }

        this.$store.commit('SET_ALARM_DEVICE', {
          deviceInfo: res.device,
          alarmInfo: res.alarm,
          sensorInfo: res.sensor
        })
        // 刷新实时告警表格
        await this.$store.dispatch('GET_WARNING_DATA', {
          closed: 1,
          page: 1,
          alarmType: 1,
          pageSize: 100,
        });
        return  true;
      }else {
        return  false;
      }
    },
    // 显示overlay颜色
    renderBgColor(sbzt){
      switch (sbzt){
        case 1:
          return '#4fc164';
        case 2:
          return '#a0a0a0';
        case 3:
          return '#EF7818';
        case 4:
          return '#FD3838';
        case 5:
          return '#4E95F7';
      }
    },
    // 显示overlay文字
    renderText(sbzt){
      switch (sbzt){
        case 1:
          return '正常';
        case 2:
          return '掉线';
        case 3:
          return '故障';
        case 4:
          return '告警';
        case 5:
          return '休眠';
      }
    },
    // 定时获取实时数据
    startARequestRealtimeInterval(id) {
      this.interval = setInterval(() => {
        this.$store.dispatch('GET_SENSOR_RT_DATA_BY_ID', id)
      },  30 * 1000);
    },
    // 获取设备告警信息
    getAlarmById(id){
      this.alarmBody.deviceId = id;
      this.$store.dispatch('GET_ALARM_DATA', this.alarmBody)
    },
    // 打开处理警告弹框
    dealWarning(event){
      // 阻止点击事件穿透
      event.stopPropagation();
      // state 0处理告警 1实时告警
      bus.$emit('dealDialog', {show: true, id: this.deviceId, state: 0});
    },
  },
  watch:{
    sbzt: {
      handler(newVal){
        console.log('sbzt',newVal)
        // 点击切换按钮关闭弹框
        this.close();
        this.fillMapV(newVal)
        // this.mapVDataset.set(data);

      }
    },
    '$store.getters.settingInfo':{
      handler(newVal) {
        if (newVal.lat && newVal.lng){
          // this.bmCenter.lat = parseFloat(newVal.lat);
          // this.bmCenter.lng = parseFloat(newVal.lng);
          this.center = [newVal.lng, newVal.lat]
        }
      },
      deep: true
    },
    // 监听vuex中设备id的值 只要设备id发生变化 执行获取设备信息的方法
    "$store.getters.deviceId": {
      handler(newVal) {
        // 清除上一个定时器
        clearInterval(this.interval)
        // 根据设备id请求数据
        this.$store.dispatch('GET_DEVICE_INFO_BY_ID', newVal);
        this.$store.dispatch('GET_SENSOR_RT_DATA_BY_ID', newVal);
        this.$store.dispatch('GET_DEVICE_SENSOR_BY_ID', newVal);
        this.$store.dispatch('GET_DEVICE_SENSOR_HISTORY_BY_ID', newVal);
        this.startARequestRealtimeInterval(newVal);
        this.getAlarmById(newVal);
      }
    },

  },

  created() {
    this.LocationRequestDevice();
    // console.log(this.pointData);
  },
  mounted(){
    // this.interval = setInterval(() => {
    //   this.LocationRequestDevice();
    // }, 1000 * 10)
    // 监听eventBus
    bus.$on('searchMove', device => {
      this.$store.commit('SET_OVERLAY_CARD_TYPE', 0);
      // this.searchMove(device);
      this.mapMove(device, true, 0, true)
      // return deviceId;
    });
    bus.$on('requestAlarm', async deviceId => {
      console.log('requestAlarm', deviceId)
      let flag = await this.getDeviceAlarmInfo(deviceId,true)
      console.log('===flag===',flag)
      if (flag){
        // 设置为红色告警弹框
        this.$store.commit('SET_OVERLAY_CARD_TYPE', 1);
        // 改变overlay的图标
        this.getOverlay(deviceId);
        // 设置告警弹框无法通过点击消除 需要解除警告才可以查看其他设备信息
        this.alarmShow = true;
        // 设置告警声音
        bus.$emit('alarmPlay')
      }
    });
    bus.$on('closeOverlay', res => {
      // this.$refs.overlay.showOverlay(res);
      this.alarmShow = false;
      this.close();
    })
  },
  destroyed(){
    bus.$off(['searchMove','requestAlarm','closeOverlay']);
    clearInterval(this.timer);
    clearInterval(this.interval);

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.bmap-wrapper {
  width: 100% ;
  height: calc(100% - 70);
  /*z-index: 1;*/
}
/deep/.bmap-info-window-custom{
  z-index: 9!important;
}

.container {
  width: 366px !important;

  height: 205px;
  /* Border */
  border: 1px solid #5ba3ed;
  box-shadow: 0px 7px 21px 0px rgba(62,154,249,0.42);
  background: rgba(19,32,64,0.91);
  border-radius: 3px;
  /* Used to position the arrow */
  position: relative;

  .title, .text{
    color: #fff;
  }

  .title{
    display: flex;
    width: 366px;
    height: 50px;
    padding: 10px 16px;
    justify-content: space-between;
    letter-spacing: 1px;
    .left{
      font-size: 18px;
      font-weight: 600;
      //padding: 20px;
      line-height: 30px;
      width: 260px;
      height: 100%;
      text-align: left;

    }
    .right{
      font-size: 14px;
      width: 63px;
      height: 29px;
      line-height: 29px;
      border-radius: 3px;
      text-align: center;
    }
  }
  .text{
    font-size: 14px;
    padding: 0 16px 10px 16px;
    text-align: left;
    font-weight: 600;
    letter-spacing: 1px;
  }
  // 倒三角
  .container__arrow {
    /* Size */
    height: 16px;
    width: 16px;

    background-color: #FFF;
    position: absolute;
  }

  .container__arrow--bc {
    /* Position at the top left corner */
    bottom: -16px;
    left: 50%;
    background: rgba(19,32,64,0.91);
    opacity: .91;
    /* Border */

    border-bottom: 1px solid #5ba3ed;
    border-right: 1px solid #5ba3ed;
    transform: translate(-50%, -50%) rotate(45deg);
  }

}

.container-warn {
  width: 366px !important;

  height: 380px;
  /* Border */
  background: #772427;
  border: 1px solid #d44b4b;
  border-radius: 3px;
  box-shadow: 0px 7px 21px 0px rgba(62,154,249,0.42);
  /* Used to position the arrow */
  position: relative;

  .title, .text{
    color: #fff;
  }

  .title{
    display: flex;
    width: 366px;
    height: 50px;
    padding: 10px 16px;
    justify-content: space-between;
    letter-spacing: 1px;
    .left{
      font-size: 18px;
      font-weight: 600;
      //padding: 20px;
      line-height: 30px;
      width: 260px;
      height: 100%;
      text-align: left;

    }
    .right{
      font-size: 14px;
      width: 63px;
      height: 29px;
      line-height: 29px;
      border-radius: 5px;
      text-align: center;
      background: #d65050;
    }
  }
  .text{
    font-size: 14px;
    padding: 0 16px 10px 16px;
    text-align: left;
    font-weight: 600;
    letter-spacing: 1px;
  }
  .btn{
    width: 323px;
    height: 44px;
    opacity: 1;
    background: #d81e06;
    border-radius: 6px;
    margin: 10px auto;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
    line-height: 44px;
    letter-spacing: 1px;
    cursor: pointer;
  }
  // 倒三角
  .container__arrow {
    /* Size */
    height: 16px;
    width: 16px;

    background-color: #FFF;
    position: absolute;
  }

  .container__arrow--bc {
    /* Position at the top left corner */
    bottom: -16px;
    left: 50%;
    background: #772427;
    opacity: .91;
    /* Border */
    border-bottom: 1px solid #d44b4b;
    border-right: 1px solid #d44b4b;
    transform: translate(-50%, -50%) rotate(45deg);
  }

}
</style>
