import vxeTable from "@/component/vxe-table";



vxeTable.VXETable.renderer.add('regionName', {
    renderDefault(h, renderOpts, params) {
        let {row,column} = params
        return [
            <div class="hidden-font" title={row[column.property]}>{ row[column.property] }</div>
        ]
    }
})
vxeTable.VXETable.renderer.add('jcsj', {
    renderDefault(h, renderOpts, params) {
        let {row,column} = params
        return [
            <div style='text-align: center'>{ row[column.property].split(' ')[0] }</div>,
            <div style='text-align: center'>{ row[column.property].split(' ')[1] }</div>
        ]
    }
})
vxeTable.VXETable.renderer.add('comId', {
    renderDefault(h, renderOpts, params) {
        let {row,column} = params
        return [
            <div className="hidden-font" title={row[column.property]}>{row[column.property]}</div>
        ]
    }
})

vxeTable.VXETable.renderer.add('comCount', {
    renderDefault(h, renderOpts, params) {
        let {row,column} = params
        return [
            <div className="hidden-font" title={row[column.property]}>{row[column.property]} 台</div>
        ]
    }
})

const warnCellStyle = function (){
    return {
        color: '#ffffff',
        border: 'none',
        fontSize: '12px',
        padding: '5px 0 5px 0',
        cursor: 'pointer',
    };
}

const warnHeadStyle = function (){
    return {
        color: '#ffffff',
        fontSize: '16px',
        paddingTop: '16px',
        paddingBottom: '14px',
        borderBottom: '1px solid #979797'
    };
}

const monitorCellStyle = function () {
    return{
        // rgba(23,38,76,0.89)
        background: 'transparent',
        border: 'none',
        fontSize: '12px',
        color: '#ffffff',
        height: '20px',
        padding: '5px 0 5px 0',
        cursor: 'pointer',
    }
}

const monitorHeadStyle = function (){
    return {
        background: "transparent",
        color: "#ffffff",
        fontSize: "16px",
        // paddingTop: "16px",
        border: 'none'
    };
}

export const warnColumn = [
    {
        field: 'jcsj',
        title: '告警时间',
        headerAlign: 'left',
        align: 'left',
        cellRender: {name: 'jcsj'},
        width: 86,
    },
    {
        field: 'regionName',
        title: '告警单位',
        headerAlign: 'center',
        align: 'center',
        cellRender: {name: 'regionName'},
        minWidth: 120
    },
    {
        field: 'sensorName',
        title: '告警传感',
        headerAlign: 'center',
        align: 'center',
        width: 90
    },
    {
        field: 'statusValue',
        title: '告警类型',
        headerAlign: 'right',
        align: 'right',
        width: 90
    },
]
export const warnStyle = {
    border: 'none',
    hover: '#a338384D',
    headStyle: warnHeadStyle,
    cellStyle: warnCellStyle
}
// monitor
export const monitorColumn = [
    {
        field: 'comName',
        title: '告警单位',
        headerAlign: 'center',
        align: 'center',
        cellRender: {name: 'comName'},
        minWidth: 200

    },
    {
        field: 'count',
        title: '告警设备数量',
        headerAlign: 'center',
        align: 'center',
        sortable: true,
        cellRender: {name: 'comCount'},
        width: 200
    },
]

export const monitorStyle = {
    border: 'none',
    hover: '#5BA3ED4D',
    cellStyle: monitorCellStyle,
    headStyle: monitorHeadStyle
}
