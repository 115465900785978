import {axiosServer,} from '../judge'
import {method,} from '../method'
import {parserResult,} from '../parser'
import {apis,} from '../urls'

export function getDashBoardAlarmData() {
	return axiosServer({
		url: apis.dashboardAlarm,
		type: method.GET
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

export function getDashBoardData() {
	return axiosServer({
		url: apis.dashboard,
		type: method.GET
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

export function changePwd(param) {
	return axiosServer({
		url: apis.changePwd,
		type: method.POST,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

export function saveRegion(param, addUser) {
	return axiosServer({
		url: apis.regionSave + '?addUser=' + addUser,
		type: method.POST,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

/**
 * 获取第一级省级的设备数量
 */
export function regionLevel1(param) {
	return axiosServer({
		url: apis.regionLevel1,
		type: method.GET,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

/**
 * 通过省级id获取第二级市的设备数量
 */
export function regionLevel2(param) {
	return axiosServer({
		url: apis.regionLevel2,
		type: method.GET,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

/**
 * 通过市级id获取第三级区的设备数量
 */
export function regionLevel3(param) {
	return axiosServer({
		url: apis.regionLevel3,
		type: method.GET,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

/**
 * 通过区级id获取第四级的设备数量
 */
export function regionLevel4(param) {
	return axiosServer({
		url: apis.regionLevel4,
		type: method.GET,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

/**
 * 通过第四级id获取第五级的设备数量
 */
export function regionLevel5(param) {
	return axiosServer({
		url: apis.regionLevel5,
		type: method.GET,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

/**
 * 通过第五级id获取第六级的设备数量
 */
export function regionLevel6(param) {
	return axiosServer({
		url: apis.regionLevel6,
		type: method.GET,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

/**
 * 获取某个用户有某个等级的区域
 * @param param  userId:登录用户的，填0   level 某个等级之上的等级，不包此等级
 * @returns {Promise<T | never>}
 */
export function getExistRegionLevel(param) {
	return axiosServer({
		url: apis.existRegionLevel,
		type: method.GET,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

/**
 * 获取登录用户的所有区域
 */
export function getUserAllRegion(userId) {
	return axiosServer({
		url: apis.region,
		type: method.GET,
		params: { page: 1, pageSize: 100, userId: userId }
	})
}

/**
 * 添加区域下的图纸
 */
export function planSave(param) {
	return axiosServer({
		url: apis.plan,
		type: method.POST,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

/**
 * 删除区域下的图纸
 */
export function planDelete(param) {
	return axiosServer({
		url: apis.plan,
		type: method.DELETE,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

/**
 * 请求图纸列表
 */
export function getplanDate(param) {
	return axiosServer({
		url: apis.plan_region_device,
		type: method.GET,
		params: param
	})
}

/**
 * 根据区域获取图纸
 * @param param
 */
export function getPlanZinger(param) {
	return axiosServer({
		url: apis.plan_zinger,
		type: method.GET,
		params: param
	})
}

export function getPlanRegionDevice(param) {
	return axiosServer({
		url: apis.plan_region_device,
		type: method.GET,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

export function saveRegionPlanDevice(param) {
	return axiosServer({
		url: apis.plan_region_device,
		type: method.POST,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

export function getCompany(param) {
	return axiosServer({
		url: apis.company,
		type: method.GET,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

export function getCompanyUser(param) {
	return axiosServer({
		url: apis.companyUser,
		type: method.GET,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

/**
 * 获取省市区的区域
 * @param param
 */
export function getRegion(param) {
	let url = '/sys/viewregiontop/list'
	return axiosServer({
		url: url,
		type: method.GET,
		params: param
	})
}

export function getRegion4(param) {
	let url = apis.region4List + '?districtId=' + param.districtId
	return axiosServer({
		url: url,
		type: method.GET,
		params: param
	})
}

export function getRegion5(param) {
	let url = apis.region5List
	return axiosServer({
		url: url,
		type: method.GET,
		params: param
	})
}

export function getRegion6(param) {
	let url = apis.region6List
	return axiosServer({
		url: url,
		type: method.GET,
		params: param
	})
}

export function saveEnterprise(param, addUser, roles) {
	//添加企业信息
	return axiosServer({
		url: apis.company + '?addUser=' + addUser + '&roles=' + roles,
		type: method.POST,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

export function saveEnterpriseMain(param) {
	//添加企业信息
	return axiosServer({
		url: apis.companyMain,
		type: method.POST,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

export function findByNameMan() {
	//获取用户的主企业信息
	return axiosServer({
		url: apis.companyMain,
		type: method.GET
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

export function regionGrant(params, userId) {
	//获取用户的主企业信息
	return axiosServer({
		url: apis.regionGrant + '?userId=' + userId,
		type: method.POST,
		params: params
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

export function regionRevoke(params, userId) {
	//获取用户的主企业信息
	return axiosServer({
		url: apis.regionRevoke + '?userId=' + userId,
		type: method.POST,
		params: params
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

export function getRegion4With2User(param) {
	return axiosServer({
		url: apis.region4With2User,
		type: method.GET,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

export function getRegion5With2User(param) {
	return axiosServer({
		url: apis.region5With2User,
		type: method.GET,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

export function getRegion6With2User(param) {
	return axiosServer({
		url: apis.region6With2User,
		type: method.GET,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

/**
 * 修改用户密码
 * @param param
 */

export function getRessetPwd(param) {
	return axiosServer({
		url: apis.modifyPwd,
		type: method.POST,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

/**
 * 获取修改密码的用户
 * @param param
 */

export function getResetData(param) {
	return axiosServer({
		url: apis.resetListBody,
		type: method.POST,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

/**
 * 增加修改密码的用户
 * @param param
 */
export function getFormPwd(param) {
	return axiosServer({
		url: apis.modifiedUsers,
		type: method.POST,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

/**
 * 登录
 * @param param
 */
export function getLoginInterface(param) {
	return axiosServer({
		url: apis.userLogin,
		type: method.POST,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

/**
 * 判断是否是欣旺达用户
 * @param param
 */
 export function getIsWXD() {
	return axiosServer({
		url: apis.isXWD,
		type: method.GET,
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}

export function getSystemSettingDetails(param) {
	return axiosServer({
		url: apis.displaySystemSetting,
		type: method.GET,
		params: param
	})
		.then((resp) => {
			return Promise.resolve(parserResult(resp))
		})
		.catch((e) => {
			return Promise.reject(e)
		})
}
