<template>
  <el-dialog
      :visible.sync="show"
      width="565px"
      custom-class="setting"
      :close-on-click-modal="false"
      :before-close="close"
  >
    <div class="setting-text">
      联系电话
    </div>
    <div class="setting-form">
      <el-form label-position="top" :model="phoneForm" :rules="phoneFormRule" ref="phoneForm">
        <el-form-item label="联系电话" prop="phone">
          <el-input v-model="phoneForm.phone" placeholder="请输入联系电话"></el-input>
        </el-form-item>
<!--        <el-form-item label="验证码">-->
<!--          <el-input class="codeInput" v-model="phoneForm.name" placeholder="请输入验证码"></el-input>-->
<!--          <el-button class="codeBtn">获取验证码</el-button>-->
<!--        </el-form-item>-->
      </el-form>
    </div>
    <div class="setting-btn">
      <el-button type="primary" @click="cancel">取消</el-button>
      <el-button type="primary" @click="confirm">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {axiosServer} from "@/api/judge";
import {Message} from 'element-ui'
export default {
  name: "PhoneDialog",
  props:{
    show: Boolean,
  },
  data() {
    let validPhone=(rule, value,callback)=>{
      if (!value){
        callback(new Error('请输入电话号码'))
      }else  if (!this.isValidPhone(value)){
        callback(new Error('请输入正确的11位手机号码'))
      }else {
        callback()
      }
    }
    return {
      // 弹框显示隐藏初始化
      dialogVisible: false,
      // 表单初始化
      phoneForm: {
       phone: null,
      },
      phoneFormRule:{
        phone: [
          { required: true, trigger: 'blur', validator: validPhone }//这里需要用到全局变量
        ]
      }
    }
  },
  computed:{
    phone(){
      return this.$store.getters.settingInfo.phone;
    }
  },
  methods: {
    // 关闭按钮
    close() {
      this.$emit('changeShow', 2)
      this.$refs['phoneForm'].resetFields();
    },
    // 取消按钮
    cancel() {

      // 弹框隐藏
      this.$emit('changeShow', 2)
      this.$refs['phoneForm'].resetFields();
    },
    isValidPhone(str) {
      const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
      return reg.test(str)
    },
    // 确定按钮
    confirm() {
      this.$refs['phoneForm'].validate(valid =>{
        if (valid){
          axiosServer({
            url: '/display/contactPhone',
            type: 'POST',
            params:{
              userId: localStorage.getItem('userId'),
              phone: this.phoneForm.phone
            }
          }).then(res => {
            if (res.data.code == 0){
              Message.success('提交成功')
            }else {
              Message.error('提交失败')
            }
            this.$emit('changeShow', 2)
            this.$refs['phoneForm'].resetFields();
          })
        }
      })
      // 提交表单

      // 弹框隐藏
      // this.$emit('changeShow', 2)
    },
  },
  created() {
    this.phoneForm.phone = this.phone
  }
}
</script>

<style scoped>
.el-dialog__wrapper{
  width: 100vw;
  height: 100vh;
}
/deep/.el-dialog{
  margin:0 !important;
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  height: 400px;
}
/deep/.el-dialog, .el-pager li{
  background: #17264c;
}
/* 顶部文字区域 */
.setting-text {
  padding-top: 40px;
  text-align: center;
  font-size: 18px;
  color: #fff;
}

/* 表单区域 */
.setting-form {
  margin: 20px 30px 0 30px;
}

.el-form {
  width: 100%;
}

.el-form-item {
  margin-bottom: 20px;
}

/deep/ .el-form-item__label {
  height: 30px;
  line-height: 30px;
  color: #fff;
  margin-bottom: 10px;
  font-size: 18px;
  padding: 0;
}

/deep/ .el-input__inner {
  height: 48px;
  background: #17264c !important;
  border: 1px solid rgba(91, 163, 237, 0.50) !important;
  border-radius: 8px;
}

/* 验证码输入栏 */
.codeInput {

  width: 338px;
  height: 48px;
  opacity: 1;
  background: #17264c;
  border: 1px solid rgba(91, 163, 237, 0.50);
  border-radius: 8px;
}

.el-button {
  width: 158px;
  height: 50px;
  opacity: 1;
  background: #0364cd;
  border-color: #0364cd;
  border-radius: 4px;
  margin-left: 5px;
  color: #fff;
  font-size: 15px;
}

/* 按钮区域 */
.setting-btn {
  margin-top: 100px;
  display: flex;
  justify-content: center;
}

.setting-btn > .el-button:nth-child(1) {
  width: 128px;
  height: 47px;
  opacity: 1;
  border-color: #3e4f77;
  background: #3e4f77;
  border-radius: 4px;
}

.setting-btn > .el-button:nth-child(2) {
  width: 128px;
  height: 47px;
  opacity: 1;
  border-color: #0364cd;
  background: #0364cd;
  border-radius: 4px;
  margin-left: 50px;
}
</style>
