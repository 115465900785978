<template>
  <section>
    <div class="font-style">
      <div class="dev-title">单位数量</div>
      <div class="dev-size">{{ total }}</div>
      <div class="dev-num">个</div>
    </div>

    <el-row class="dev-status-row">
      <el-col :span="12" >
        <div id="pieChart1" ref="myRef" class="chart-box" />
        <p class="chart-desc">正常单位</p>
      </el-col>


      <el-col :span="12">
        <div id="pieChart2" ref="myRef2" class="chart-box" />
        <p class="chart-desc">告警单位</p>
      </el-col>

    </el-row>
  </section>
</template>

<script>

// import { defineComponent, getCurrentInstance, ref, onMounted } from 'vue';

export default({
  name: 'StatSupervisionUnit',
  props: {
    msg: String
  },

  data(){
    return{
      chart1: null,
      chart2: null,
    }
  },
  computed:{
    charts(){
      return this.$store.getters.monitorComData.charts;
    },
    total(){
      return this.charts.first + this.charts.second ;
    },
  },
  methods:{
    init(domName, option, id){
      id === 2 ? option =  {
        series: [
          {
            type: 'gauge',
            startAngle: 90,
            endAngle: -270,
            pointer: {
              show: false
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false,
              itemStyle: {
                borderWidth: 5,
                borderColor: '#ff5660',
                color: "#ff5660",
              }
            },
          axisLine: {
            lineStyle: {
              width: 7,
            }
          },
          splitLine: {
            show: false,
            distance: 0,
            length: 10
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false,
            distance: 50
          },
          data: [
            {
              value: this.formatPercentage(this.charts.second, this.total),
              name: this.formatNum(this.charts.second),
              color: '#fff',
              title: {
                offsetCenter: ['0%', '0%'],
                fontSize: 20,
                color: '#fff',
                borderColor: '#fff',
                fontWeight:"bold",
              },
              detail: {
                offsetCenter: ['0%', '0%'],
                show:false,
              }
            }
          ],
          title: {
            fontSize: 14
          },
          detail: {
            width: 50,
            height: 14,
            fontSize: 14,
            color: '#fff',
            borderColor: '#fff',
            borderRadius: 10,
            borderWidth: 0,
            formatter: '{value}%'
          }
        }]
      } : option =  {
        series: [
          {
            type: 'gauge',
            startAngle: 90,
            endAngle: -270,
            pointer: {
              show: false
            },
          progress: {
            show: true,
            overlap: false,
            roundCap: true,
            clip: false,
            itemStyle: {
              borderWidth: 5,
              borderColor: this.$echarts.graphic.LinearGradient(1, 1, 0, 0, [
                {offset: 0, color: '#4fc164'},
                {offset: 1, color: '#4ae8dd'},
              ]),
              color:  this.$echarts.graphic.LinearGradient(1, 1, 0, 0, [
                {offset: 0, color: '#4fc164'},
                {offset: 1, color: '#4ae8dd'},
              ]),
            }
          },
          axisLine: {
            lineStyle: {
              width: 7,
            }
          },
          splitLine: {
            show: false,
            distance: 0,
            length: 10
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false,
            distance: 50
          },
          data: [
          {
            value: this.formatPercentage(this.charts.first, this.total),
            name: this.formatNum(this.charts.first),
            color: '#fff',
            title: {
              offsetCenter: ['0%', '0%'],
              fontSize: 20,
              color: '#fff',
              borderColor: '#fff',
              fontWeight:"bold",
            },
            detail: {
              offsetCenter: ['0%', '0%'],
              show:false,
            }
         }
          ],
          title: {
            fontSize: 14
          },
          detail: {
            width: 50,
            height: 14,
            fontSize: 14,
            color: '#fff',
            borderColor: '#fff',
            borderRadius: 10,
            borderWidth: 0,
            formatter: '{value}%'
          }
        }]
      }
      const myChart = this.$echarts.init(document.getElementById(domName));
      myChart.setOption(option);
      return myChart;
    },
    formatNum(num){
      if (num < 1000){
        return '' + num;
      }else if (num >= 1000 && num < 10000){
        num = '' + num;
        return num.substring(0,1) + '.' + num.substring(1,2) + 'k';
      }else if (num >= 10000 && num < 100000){
        num = '' + num;
        return num.substring(0,2) + '.' + num.substring(2,3) + 'k';
      }else {
        return '99k+';
      }
    },
    formatPercentage(num,total){
      return (num/total).toFixed(2) * 100;
    }
  },
  mounted(){
  },
  watch:{
    charts:{
      handler(){
       this.chart1 = this.init('pieChart1', {}, 1);
       this.chart2 = this.init('pieChart2', {}, 2);
      }
    }
  },
  destroyed() {
    console.log("StatSupervision === destroyed");
    this.$echarts.dispose(this.chart1);
    this.$echarts.dispose(this.chart2);
  }
})
</script>

<style scoped>

  .chart-desc{
    font-size: 14px;
    color: #ffffff;
    text-align: center;
    width: 100%;
    padding:0;
    letter-spacing: 1px;
    margin: 26px 0 0 0;
  }

  .font-style{
    height: 40px;
    color:white;
    background:rgba(255,255,255,0.1);
    width:100%;
    letter-spacing: 2px;
    border-radius: 5px;
    font-size: 14px;
    padding-left: 22px;
    display: flex;
  }
  .dev-title{
    font-size: 14px;
    line-height: 40px;
  }
  .dev-size{
    font-size: 24px;
    padding: 0 10px 0 24px;
    line-height: 40px;
    font-weight: 600;
    /*line-height: 40px;*/
  }
  .dev-num{
    font-size: 14px;
    line-height: 46px;
    font-weight: 600;
  }

  .dev-size{

    font-size:22px;
    font-weight:bold;
  }

  .chart-box{
    margin: 0 auto;
    width: 100px;
    height: 100px;
  }
  .dev-status-row{
    margin-top: 36px;
  }
</style>
