<template>
  <div class="container" >
    <el-container class="content-map"  @click.native="isShow">
      <baidu-map-show></baidu-map-show>
<!--      <BaiduMapvglShow></BaiduMapvglShow>-->
    </el-container>
    <!-- 搜索 -->
    <el-container style="z-index: 10" class="search-input">
      <el-input
          placeholder=""
          v-model="searchContent"
          class="input-with-select"
          v-on:input="onChange"
          @focus="onFocus"
      >
        <template #prepend>
          <el-select v-model="searchType" placeholder="请选择" @change="selectChange">
            <el-option label="设备名称" value="1"></el-option>
            <el-option label="设备编码" value="2"></el-option>
          </el-select>
        </template>

        <template #append>
          <el-button icon="el-icon-search" @click="search"></el-button>
        </template>
      </el-input>
      <div class="search-card-box" v-show="searchCardShow">
        <div  class="search-card">
          <template  v-if="searchList.length === 0">
            <div class="search-card-item">
              <span>无</span>
            </div>
          </template>
          <template v-else>
            <div
                class="search-card-item"
                v-for="item in searchList"
                :key="item.id"
                @click="btnClickDeviceInfo(item)"
            >
              <span > {{  item.deviceName }}</span>
            </div>
          </template>
        </div>
      </div>
    </el-container>
    <!-- 按钮组   -->
    <el-container style="z-index: 10" class="btn-group">
      <div :class="['btn', sbzt == 0 ? 'btn-active-all': '']" @click="btnMapShow(0)">全部</div>
      <div :class="['btn', sbzt == 1 ? 'btn-active-normal': '']" @click="btnMapShow(1)">正常</div>
      <div :class="['btn', sbzt == 2 ? 'btn-active-offline': '']" @click="btnMapShow(2)">掉线</div>
      <div :class="['btn', sbzt == 4 ? 'btn-active-warning': '']" @click="btnMapShow(4)">告警</div>
      <div :class="['btn', sbzt == 3 ? 'btn-active-fault': '']" @click="btnMapShow(3)">故障</div>
      <div :class="['btn', sbzt == 5 ? 'btn-active-all': '']" @click="btnMapShow(5)">休眠</div>
    </el-container>

    <div class="device-detail-btn" style="z-index: 10" v-show="!deviceDetailsShow" @click="deviceShow(1)">
      <i class="el-icon-s-fold"></i>
    </div>

    <div class="company-detail-btn" style="z-index: 10" v-show="!deviceCompanyShow" @click="deviceShow(2)">
      <i class="el-icon-s-unfold"></i>
    </div>
    <!-- 公司设备详情 -->
    <company-detail style="z-index: 10;"></company-detail>
    <!-- 单个设备详情 -->
    <device-detail style="z-index: 10"></device-detail>

    <el-container style="z-index: 10" class="content-bottom-1  comm-bg comm-bg-red">
      <img src="../../../../images/comm-shining.png" class="comm-shining-img" style="z-index: -1">
      <div class="comm-title-container" >实时告警</div>
      <div :class="['warning-card', warnLoad ? '': 'pdr']" >
<!--        <warning-Table :warning-data="warningData"></warning-Table>-->
<!--        <warning-Table ></warning-Table>-->
<!--        <HlTable-->
<!--            :Table-data="warnData"-->
<!--            :column="warnOptions.warnColumn"-->
<!--            :cell-style="warnOptions.warnCellStyle"-->
<!--            :head-style="warnOptions.warnHeadStyle"-->
<!--            :loading="warnLoad"-->
<!--            :height="`228px`"-->
<!--            color="rgba(130,36,36,0.91)"-->
<!--            @row-click="warnClick"-->
<!--            @is-scroll="addWarnData"-->
<!--        >-->
<!--          <template #regionName="scope" >-->
<!--            <div class="hidden-font" :title="scope.row.regionName">{{scope.row.regionName}}</div>-->
<!--          </template>-->
<!--        </HlTable>-->
        <HlTable
            :table-data="warnData"
            :options="warnOptions"
            @row-click="warnClick"
            :load="warnLoad"
            @is-scroll="addWarnData"
            :scroll="true"
            ref="hlTable"
        >
        </HlTable>

      </div>
    </el-container>

    <el-container style="z-index: 10" class="content-bottom-2  comm-bg">
      <img src="../../../../images/comm-shining.png" class="comm-shining-img">
      <div class="comm-title-container" >告警单位统计</div>
      <div  :class="['monitor-card', monitorLoad ? '': 'pdr']">
<!--        <monitor-Table :monitor-data="monitorData"></monitor-Table>-->
<!--        <HlTable-->
<!--            :Table-data="monitorData"-->
<!--            :column="monitorOptions.monitorColumn"-->
<!--            :cell-style="monitorOptions.monitorCellStyle"-->
<!--            :head-style="monitorOptions.monitorHeadStyle"-->
<!--            color="rgba(23,38,76,0.89)"-->
<!--            :loading="monitorLoad"-->
<!--            :height="`228px`"-->
<!--            @row-click="monitorClick"-->
<!--        >-->
<!--          <template #comId="scope" >-->
<!--            <div class="hidden-font" :title="scope.row.comId">{{scope.row.comId}}</div>-->
<!--          </template>-->
<!--          <template #count="scope">-->
<!--            <div class="hidden-font">{{ scope.row.count }} 台 </div>-->
<!--          </template>-->
<!--        </HlTable>-->
        <HlTable
            :table-data="monitorData"
            :options="monitorOptions"
            :load="monitorLoad"
            @row-click="monitorClick"
        >
        </HlTable>
      </div>
    </el-container>

    <el-container style="z-index: 10" class="content-bottom-3  comm-bg">
      <img src="../../../../images/comm-shining.png" class="comm-shining-img">
      <div class="comm-title-container" >厂商/供应商统计</div>
      <div class="statistics-card">
        <div class="myTab">
          <div class="tab-List">
            <div class="tab-box">
              <div
                  :class="['tabs', activeName === index ? 'active-tab': '']"
                  v-for="(item, index) in tabData"
                  :key="item.id" @click="handleClick(index)"
              >
                {{ item.title }}
              </div>
            </div>
            <div class="tab-card">
              <template v-for="(i,index) in tabData.length">
                <div v-if="activeName == index"  :key="i" class="tab-card-content">
                  <div class="tap-content">
                    <div class="tab-text">正常状态</div>
                    <div class="tap-text">{{tabData[index].normal}} 台</div>

                  </div>
                  <div class="tap-content">
                    <div class="tab-text">掉线状态</div>
                    <div class="tap-text">{{tabData[index].offline}} 台</div>
<!--                    <div class="tap-num"></div>-->
                  </div>
                  <div class="tap-content">
                    <div class="tab-text">告警状态</div>
                    <div class="tap-text">{{tabData[index].warning}} 台</div>
<!--                    <div class="tap-num">台</div>-->
                  </div>
                  <div class="tap-content">
                    <div class="tab-text">故障状态</div>
                    <div class="tap-text">{{tabData[index].fault}} 台</div>

                  </div>
                  <div class="tap-content">
                    <div class="tab-text">休眠状态</div>
                    <div class="tap-text">{{tabData[index].sleep}} 台</div>

                  </div>

                </div>
              </template>
            </div>


          </div>
        </div>
      </div>
    </el-container>


  </div>

</template>

<script>
import DeviceDetail from './components/DeviceDetail'
import BaiduMapShow from './components/BaiduMapShow'
import BaiduMapvglShow from "@/views/Layout/Main/MainLeft/components/BaiduMapvglShow";

import {getDeviceList} from "@/api/impl/device";
import bus from "@/utils/eventBus/bus";
import { warnColumn, warnStyle, monitorColumn, monitorStyle } from "@/views/Layout/Main/MainLeft/index";
import {axiosServer} from "@/api/judge";
import CompanyDetail from "@/views/Layout/Main/MainLeft/components/CompanyDetail";
import HlTable from "@/component/Table/HlTable";
export default {
  name: 'MapContent',
  components:{
    DeviceDetail,BaiduMapShow,BaiduMapvglShow,CompanyDetail,HlTable
  },

  data() {
    return {
      searchType: "1",
      searchContent: "",
      activeName: 0,
      // 搜索
      searchCardShow: false,
      reqBody: {
        page: 1,
        pageSize: 15,
        // eqSysId: 1,
      },
      searchList: [],
      // 实时警告
      reqsBody: {
        closed: 1,
        page: 1,
        alarmType: 1,
        pageSize: 100,
      },
      allAlign: null,
      warnOptions:{
        column: warnColumn,
        style: warnStyle
      },
      monitorOptions: {
        column:monitorColumn,
        style: monitorStyle
      },
      // warnOptions: {
      //   warnColumn: warnColumn,
      //   warnCellStyle: warnCellStyle,
      //   warnHeadStyle: warnHeadStyle,
      //   rowClass: true
      // },
      // monitorOptions:{
      //   monitorColumn:monitorColumn,
      //   monitorCellStyle: monitorCellStyle,
      //   monitorHeadStyle: monitorHeadStyle,
      // },
      // 厂商/供应商统计
      tabData: [

      ],

    }
  },

  methods: {
    async warnClick(e){
      let res = await axiosServer({
        url: '/device/contacts?deviceId=' + e.deviceId,
        type: 'GET',
      })
      if (res.data.code == 0){
        e.contacts = res.data.com.contacts;
        e.phone = res.data.com.phone;
        bus.$emit('dealDialog', {show: true, id: e.deviceId, state: 1, tableInfo: e});
      }else {
        e.contacts = '暂无信息';
        e.phone = '暂无信息';
        bus.$emit('dealDialog', {show: true, id: e.deviceId, state: 1, tableInfo: e});
      }
    },
    addWarnData(e){
      if (e && this.warnData.length < this.warnTotal){
          this.reqsBody.page += 1;
          this.$store.dispatch('GET_WARNING_DATA', {body:this.reqsBody, type: 2})
      }
    },
    monitorClick(e){
      this.$store.commit('SET_DEVICE_COMPANY_SHOW', true);
      this.$store.dispatch('GET_MONITOR_DEVICE_BY_COM_ID', {page: 1, pageSize: 1000, comId: e.comId})
      bus.$emit('companyName', e)
    },
    handleClick(index) {
      this.activeName = index;
    },
    btnMapShow(num) {
      this.$store.commit('SET_SBZT', num);
    },
    // 监听搜索input输入
    onChange(e){
      if (e == ''){
        this.searchCardShow = false;
      }else {
        this.search();
      }
    },
    onFocus(){
      if (this.searchContent){
        this.search();
      }else {
        this.searchCardShow = false;
      }
    },
    // 搜索框 搜索按钮
    search(){
      this.searchCardShow = true;
      this.requestDevice();
    },
    // 搜索框 搜索类型切换
    selectChange(e){
      // 切换搜索类型 数据置空 且关闭搜索框
      this.searchContent = '';
      this.searchCardShow = false;
      // 当类型为1 搜索为设备名称 请求分页数据是否包含ip属性 是则需要删除ip属性
      this.reqBody.hasOwnProperty('ip') && e == 1 && this.$delete(this.reqBody, 'deviceName');
      // 当类型为2 搜索为设备编码 请求分页数据是否包含deviceName属性 是则需要删除deviceName属性
      this.reqBody.hasOwnProperty('deviceName') && e == 2 && this.$delete(this.reqBody, 'deviceName');
    },
    // 点击搜索列表显示详细该设备数据
    btnClickDeviceInfo(device){
      this.$store.commit('SET_DEVICE_ID', device.deviceId);
      // this.$store.commit('SET_DEVICE_DETAILS_SHOW', false);
      this.$store.commit('SET_EXPAND', false);
      // this.$store.commit('SET_SEARCH_DEVICE_INFO', device);
      bus.$emit('mapMove', device);
      this.searchCardShow = false;
    },
    isShow(){
      this.searchCardShow = false;
    },
    /**
     *  获取设备列表
     * **/
    requestDevice () {
      if (this.reqBody.sbzt == 0) {
        this.reqBody.sbzt = null;
      }
      if(this.searchType == 1){
        this.reqBody.deviceName = this.searchContent;
      }
      if(this.searchType == 2){
        this.reqBody.ip = this.searchContent;
      }
      getDeviceList(this.reqBody).then((res) => {
        console.log('getDeviceList', res)
        if (res.code == 0){
          this.searchList = res.page.list;
        }

      }).catch((e) => {
        //提示错误
        console.log(e)
      });

    },
    deviceShow(type){
      switch (type){
        case 1:
          this.$store.commit('SET_DEVICE_DETAILS_SHOW', true);
          break;
        case 2:
          this.$store.commit('SET_DEVICE_COMPANY_SHOW', true);
          break;
      }
    }

  },
  created(){
    // this.getUserAlarmInformation();
    this.$store.dispatch('GET_WARNING_DATA', {body:this.reqsBody, type: 1})
  },
  mounted(){
  },
  computed:{
    deviceNum(){
      return this.$store.getters.deviceNum;
    },
    deviceDetailsShow(){
      return this.$store.getters.deviceDetailsShow;
    },
    deviceCompanyShow(){
      return this.$store.getters.deviceCompanyShow;
    },
    sbzt(){
      return this.$store.getters.sbzt;
    },
    monitorData(){
      return this.$store.getters.monitorComData.tableData;
    },
    monitorLoad(){
      return this.$store.getters.monitorLoad;
    },
    warnData(){
      // this.warnOptions.loading = false
      return this.$store.getters.warnData;
    },
    warnLoad(){
      return this.$store.getters.warnLoad;
    },
    warnTotal(){
      return this.$store.getters.warnTotal;
    }

  },
  watch:{
    deviceNum:{
      handler(newVal){
        let obj = {id: 1, title: '将臣科技有限公司'}
        if (localStorage.getItem("userId") == 18025){
          obj.title = "联安智控"
        }
        this.tabData = [];
        this.tabData.push(Object.assign(newVal, obj))
      },
      deep: true
    }
  }
}
</script>


<style>

.el-textarea__inner,.el-input__inner {
    background: transparent !important;
    border: 0px solid #DCDFE6 !important;
    color: #fff !important;
}

.el-input-group__append, .el-input-group__prepend{
  background: transparent !important;
  border: 0px solid #DCDFE6 !important;
  color: #fff !important;
  width:65px !important;
  text-align: right;
  font-size: 12px;
}
</style>

<style lang="less" scoped>
.container{
  width: 100%;
  height: 100%;
  z-index: 1;
}
.comm-shining-img{
  position:fixed;
  margin-top: -55px;
  margin-left: -35px;
  pointer-events: none;
}

.content-bottom-1 .comm-title-container{
  background: linear-gradient(90deg,#c26060, rgba(255,255,255,0.00));
}
.comm-bg-red{
  background: rgba(130,36,36,0.91)!important;
  border: 1px solid #d44b4b!important;
}
.comm-title-container{
  width: 100%;
  height: 46px;
  background: linear-gradient(90deg,rgba(45,127,255,0.4), rgba(255,255,255,0.00));
  border-radius: 7px 7px 0px 0px;
  color: #fff !important;
  font-size: 18px;
  letter-spacing: 2px;
  line-height: 46px;
  text-align: left !important;
  padding-left: 30px;
}


.comm-title{
  position: fixed;
  margin: 14px 0 0 18px;
  color: #fff;
  font-size: 14px;
  letter-spacing: 2px;
}

.comm-bg{
  width: 478px;
  opacity: 1;
  background: rgba(23,38,76,0.8);
  border: 1px solid #5ba3ed;
  border-radius: 8px;
}

.input-with-select{
  height: 41px;
  background-color: #1b2c52;
  border-radius: 20px;
}

.content-map{
  background: #101c42;
  width: 100%;
  height: 100%;
}

.search-input{
  position: fixed;
  top:110px;
  width: 75%;
  height: 50px;
  z-index: 10;
  display: flex;
  justify-content: center;
  .el-input{
    width: 450px;
  }
  /deep/.el-select .el-input__inner{
    width: 95px;
  }
}
.search-card-box{
  width: 75%;
  position: fixed;
  top: 160px;
  display: flex;
  justify-content: center;
  .search-card{
    //position: fixed;
    //left: 180px;
    //top: 160px;
    width: 450px;
    max-height: 300px;
    background:  rgba(23,38,76,0.89);
    overflow-y: auto;
    border-radius: 2px;
    &::-webkit-scrollbar{
      width: 0;
      height: 0;
    }
    .search-card-item{
      width: 100%;
      height: 40px;
      line-height: 40px;
      color: #fff;
      font-size: 14px;
      text-align: left;
      padding-left: 10px;
      cursor: pointer;
      &:hover{
        background: rgba(34, 60, 123, 0.89);;
      }
      .search-card-item span{
        line-height: 40px;
      }
    }

  }
}



/* 按钮组 */
.btn-group{
  position: fixed;
  left:0px;
  bottom: 350px;
  width: 75%;
  height: 29px;
  /*padding: 0 50px;*/
  display: grid;
  grid-template-rows: 29px;
  grid-template-columns: repeat(6, 58px);
  grid-column-gap: 16px;
  justify-content: center;
  z-index: 1;
  .btn{
    width: 100%;
    height: 100%;
    opacity: 1;
    background: #3e4f77;
    color: #ffffff;
    font-size: 14px;
    line-height: 29px;
    cursor: pointer;
    &:hover{
      background: #5699FF;
    }
    &:nth-child(2):hover{
      background: #4FC164;
    }
    &:nth-child(3):hover{
      background: #929292;
    }
    &:nth-child(4):hover{
      background: #D81E06;
    }
    &:nth-child(5):hover{
      background: #FF8256;
    }
  }

}
.btn-active-all{
  background: #5699FF!important;
}
.btn-active-normal{
  background: #4FC164!important;
}
.btn-active-offline{
  background: #929292!important;
}
.btn-active-warning{
  background: #D81E06!important;
}
.btn-active-fault{
  background: #FF8256!important;
}

.content-bottom-1,.content-bottom-2,.content-bottom-3{
  width: 24%;
  height: 287px;
  position: fixed;
  bottom: 5px;
  margin-left: 15px;
  display: flex;
  flex-direction: column;
}

.content-bottom-2{
  left: 25%;
}

.content-bottom-3{
  left: 50%;
}
.content-bottom-3-content{
  width: 24%;
  height:200px;
  position: fixed;
  bottom: 5px;
  left: 49.5%;
  margin-left: 5px;
  margin-top:47px;
}
.comm-title-container{
  width: 100%;
  height: 56px;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
  line-height: 56px;
  letter-spacing: 1px;
}
/* 设备告警 */
.warning-card{
  width: 100%;
  padding: 0 16px;
  height: 230px;
}
.monitor-card{
}
.pdr{

}

/* 设置表格背景透明 */
/deep/ .el-table th,/deep/ .el-table tr{
  background: transparent;
}



/* 厂商统计 */
.statistics-card{
  width: 100%;
  height: 231px;
}
.tab-List{
  width: 100%;
  height: 100%;

  margin-top: 5px;

}
.tab-box{
  width: 100%;
  height: 30px;
  display: grid;
  grid-template-columns: repeat(3,111px);
  grid-template-rows: 30px;
  justify-content: space-around;
  margin-bottom: 10px;
  overflow-x: hidden;
  z-index: 1;

}
.tabs{
  font-size: 13px;
  text-align: center;
  color: #ffffff;
  line-height: 30px;

  cursor: pointer;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.active-tab{
  border-bottom: 2px solid #5699FF;
}
.tab-card{
  height: 190px;
  margin-left: 14px;
}
.tab-card-content{
  overflow-x: hidden;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-template-rows: repeat(2,76px);
  color: #fff;
  justify-content: space-around;
  align-content: space-evenly;
}
.tab-card .tap-content{
  width: 130px;
  height: 76px;
  /*line-height: 65px;*/
  text-align: right;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  font-weight: 900;
  font-size: 14px;
  position: relative;
  z-index: 3;
}
.tab-text{
  position: absolute;
  top: -5px;
  left: 78px;
  z-index: 4;
  font-size: 12px;
}
.tab-card .tap-content .tap-text{
  /*position: absolute;*/
  /*top: 37px;*/
  /*left: 70px;*/
  padding-top: 37px;
  padding-right: 8px;

}
.tap-num{
  position: absolute;
  top: 37px;
  left: 110px;
}
.tab-card .tap-content:nth-child(1){
  background-image: url("../../../../images/normal.png");

  /*background: #000;*/
}
.tab-card .tap-content:nth-child(2){
  background-image: url("../../../../images/offline.png");
  /*background: #000;*/
}
.tab-card .tap-content:nth-child(3){
  background-image: url("../../../../images/warning.png");
  /*background: #000;*/
}
.tab-card .tap-content:nth-child(4){
  background-image: url("../../../../images/fault.png");
  /*background: #000;*/
}
.tab-card .tap-content:nth-child(5){
  background-image: url("../../../../images/sleep.png");
  /*background: #000;*/
}
/**/
.device-detail-btn, .company-detail-btn{
  width: 30px;
  height: 30px;
  position: fixed;
  right: 26%;
  top: 105px;
  background: #17264C;
  cursor: pointer;
}
.company-detail-btn{
  right: 0;
  left: 25px;

}
.device-detail-btn i, .company-detail-btn i{
  font-size: 22px;
  line-height: 30px;
  color: #fff;
}
</style>
