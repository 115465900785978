<template>
  <el-dialog
      :visible.sync="show"
      width="1000px"
      custom-class="setting"
      :close-on-click-modal="false"

      :before-close="close"
  >
    <div class="setting-text">
      报表导出
    </div>
    <div class="setting-form">
      <el-form label-position="left" label-width="77px" :model="exportForm">
<!--        <el-form-item label="筛选时间">-->
<!--          <el-date-picker-->
<!--              v-model="exportForm.startTime"-->
<!--              type="datetime"-->
<!--              placeholder="开始时间"-->
<!--              popper-class="date-style"-->
<!--          >-->
<!--          </el-date-picker>-->
<!--          <el-date-picker-->
<!--              v-model="exportForm.endTime"-->
<!--              type="datetime"-->
<!--              placeholder="结束时间"-->
<!--              class="end-time"-->
<!--              popper-class="date-style"-->
<!--          >-->
<!--          </el-date-picker>-->
<!--        </el-form-item>-->
        <el-form-item label="导出内容">
          <el-select v-model="exportForm.content" placeholder="请选择">
            <el-option
                v-for="item in optionsList"
                :key="item.id"
                :label="item.text"
                :value="item.text">
            </el-option>
          </el-select>
          <el-button @click="search" type="primary">查询</el-button>
        </el-form-item>
<!--        <el-form-item label="设备状态" v-show="exportForm.content == '设备统计'">-->
<!--          <el-select v-model="exportForm.sbzt" placeholder="请选择">-->
<!--            <el-option-->
<!--                v-for="item in sbztList"-->
<!--                :key="item.id"-->
<!--                :label="item.text"-->
<!--                :value="item.id">-->
<!--            </el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
      </el-form>

    </div>
    <div class="setting-card">
      <el-card class="box-card">
<!--        <div class="card-top">-->
<!--          <div>查询结果</div>-->
<!--        </div>-->
<!--        <div class="card-bottom" v-if="isShow">-->
<!--&lt;!&ndash;          <div class="card-text">{{ exportForm.content +  this.date  }}.xslx</div>&ndash;&gt;-->
<!--          <div class="card-btn">-->
<!--&lt;!&ndash;            <div v-show="loading">正在创建...</div>&ndash;&gt;-->
<!--&lt;!&ndash;            <el-button  v-show="!loading" class="btn" type="primary" @click="exportExcel">导出</el-button>&ndash;&gt;-->
<!--          </div>-->
<!--        </div>-->

        <vxe-toolbar class-name="toolbar-color" custom  export>

        </vxe-toolbar>
        <div v-show="true" >

          <vxe-table
              ref="xTable"
              height="600"
              :loading="loading"
              :export-config="{message: false}"
              :data="exportData"
              header-row-class-name="cell-color"
              row-class-name="cell-color"
              cell-class-name="cell-color"
              border="none"
              show-overflow="false"
              :tooltip-config="{showAll: true, enterable: true}"
          >
            <vxe-column field="deviceName" title="设备名称" :filters="[{ data: '' }]" :filter-method="filterNameMethod" :filter-recover-method="filterNameRecoverMethod">
              <template #filter="{ $panel, column }">
                <template v-for="(option, index) in column.filters">
                  <input class="my-input" type="type" :key="index" v-model="option.data" @input="$panel.changeOption($event, !!option.data, option)" @keyup.enter="$panel.confirmFilter()" placeholder="按回车确认筛选">
                </template>
              </template>
            </vxe-column>
            <vxe-column field="ip" title="设备ip"></vxe-column>
            <vxe-column field="regionName" title="单位" :filters="[{ data: '' }]" :filter-method="filterComNameMethod" :filter-recover-method="filterNameRecoverMethod">
              <template #filter="{ $panel, column }">
                <template v-for="(option, index) in column.filters">
                  <input class="my-input" type="type" :key="index" v-model="option.data" @input="$panel.changeOption($event, !!option.data, option)" @keyup.enter="$panel.confirmFilter()" placeholder="按回车确认筛选">
                </template>
              </template>
            </vxe-column>
            <vxe-column field="contacts" title="单位联系人"></vxe-column>
            <vxe-column field="phone" title="单位联系电话"></vxe-column>
            <vxe-column field="sbzt" title="设备状态"
                        :formatter="formatterSbzt"
                        :filters="sbztList">
            </vxe-column>
            <vxe-column field="address" title="地址" ></vxe-column>
            <vxe-column field="detailAddress" title="详细地址" ></vxe-column>
            <vxe-column field="sim" title="sim卡号"></vxe-column>
          </vxe-table>
        </div>
      </el-card>
    </div>
  </el-dialog>
</template>

<script>
import {dateFormat} from '@/utils/date'
import {Message} from 'element-ui'
import {axiosServer} from "@/api/judge";
import baseUrl from "@/api/baseUrl";
import XEUtils from 'xe-utils'
import {getDevices} from "@/api/impl/device";
export default {
  name: "ExportDialog",
  props:{
    show: Boolean,
  },
  data() {
    return {
      dialogVisible: false,
      exportForm: {
        startTime: null,
        endTime: null,
        content: null
      },
      date: null,
      isShow: false,
      optionsList: [
        {
          id: 1,
          text: '设备统计'
        },

      ],
      sbztList: [
        {
          value: 1,
          label: "正常"
        },
        {
          value: 2,
          label: "掉线"
        },
        {
          value: 3,
          label: "故障"
        },
        {
          value: 4,
          label: "告警"
        },
        {
          value: 5,
          label: "休眠"
        },
      ],
      exportData: [],
      loading: false
    }
  },
  methods: {

    // 关闭按钮
    close() {
      this.$emit('changeShow', 4)
      for (const key in this.exportForm) {
        this.exportForm[key] = null;
      }
      this.date = null;
      this.isShow = false;
    },
    // 取消按钮
    cancel() {

      // 弹框隐藏
      this.$emit('changeShow', 4)
    },
    // 确定按钮
    confirm() {
      // 提交表单
      // 弹框隐藏
      this.$emit('changeShow', 4)
    },
    // 查询按钮
    search() {
      // if (!this.exportForm.startTime){
      //   Message.error('开始时间不能为空');
      //   return ;
      // }
      // if (!this.exportForm.endTime){
      //   Message.error('结束不能为空');
      //   return ;
      // }
      if (!this.exportForm.content){
        Message.error('查询类型不能为空');
        return ;
      }


      console.log(this.exportForm)
      //
      this.date = dateFormat('YYYYmmddHHMMSS', new Date());

      if (this.exportForm.content == "设备统计"){
        // page pagesize 无用 过后端接口
        this.loading = true;
        // let sbzt = this.exportForm.sbzt ? this.exportForm.sbzt : null;
        getDevices({userId: localStorage.getItem("userId"),export: 1, page: 1, pageSize: 10}).then(res => {

          if (res.code == 0){
            this.exportData = res.list;

          }else {
            this.$message.error("加载失败");
          }
          this.loading = false;
        })
      }
      this.isShow = true;
    },
    exportExcel(){
      // let path = process.env.NODE_ENV !== 'production' ? '/proxyApi' : baseUrl.BASE_API;
      // let url = path + '/display/statistic/eqSys?userId' + localStorage.getItem('userId');
      // window.open(url);
      this.$refs.xTable.exportData({
        filename: this.exportForm.content +  this.date,
        sheetName: 'Sheet1',
        type: 'xlsx'
      })
    },
    formatterSbzt({ cellValue, row, column }){
      if (cellValue == 1){
        return "正常"
      }else if (cellValue == 2){
        return "掉线"
      }else if (cellValue == 3){
        return  "故障"
      }else if (cellValue == 4){
        return "告警"
      }else if (cellValue == 5){
        return "休眠"
      }else  if (cellValue == 21){
        return "断电"
      }else if (cellValue == 22){
        return "拆除"
      }else {
        return "未知"
      }
    },
    // 表格筛选
    filterNameMethod ({ option, row, column }) {
      if (option.data) {
        return XEUtils.toValueString(row[column.property]).toLowerCase().indexOf(option.data) > -1
      }
      return true
    },
    filterComNameMethod({ option, row, column }){
      if (option.data) {
        return XEUtils.toValueString(row[column.property]).toLowerCase().indexOf(option.data) > -1
      }
      return true
    },
    filterStatusMethod({ option, row }){
      return row.sbzt === option.data
    },
    filterNameRecoverMethod ({ option }) {
      // 如果是自定义筛选模板，当为点击确认时，该选项将被恢复为默认值
      option.data = ''
    }
  },
  activated() {
    console.log('=====')
  }
}
</script>

<style scoped>
.el-dialog__wrapper{
  width: 100vw;
  height: 100vh;

}
.el-dialog{
  margin:0 !important;
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
}
/deep/.el-dialog, .el-pager li{
  background: #17264c;
}
/* 顶部文字区域 */
.setting-text {
  padding-top: 40px;
  text-align: center;
  font-size: 18px;
  color: #fff;
}

/* 表单区域 */
.setting-form {
  margin: 20px 30px 0 30px;
}

.el-form {
  width: 100%;
}

.el-form-item {
  margin-bottom: 20px;
}

/deep/ .el-form-item__label {
  height: 40px;
  line-height: 40px;
  color: #fff;

  font-size: 18px;
  padding: 0;
}

/deep/ .el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: 412px;
  margin-left: 15px;
  height: 48px;
  opacity: 1;
  background: #17264c !important;
  border: 1px solid rgba(91, 163, 237, 0.50) !important;
  border-radius: 8px;
}

/deep/ .el-input__inner {
  width: 412px;
  height: 48px;

}

/deep/ .el-select .el-input__inner {
  margin-left: 15px;
  /*color: #EAEAEA4D!important;*/
  background: #17264c !important;
  border: 1px solid rgba(91, 163, 237, 0.50) !important;
  border-radius: 8px;
}

/deep/ .el-date-picker__time-header {
  padding: 0;
}

/deep/ .el-input__prefix {
  top: 3px;
}

/deep/ .el-input__suffix .el-input__suffix-inner .el-input__icon {
  line-height: 45px;
}

.el-button {
  width: 416px;
  height: 49px;
  opacity: 1;
  color: #fff;
  margin-left: 15px;
  border-color: #0364cd;
  background: #0364cd;
  border-radius: 7px;
}

/*卡片区域*/
.setting-card {
  margin: 40px 30px 0 30px;
}

.el-card {
  width: 940px;
  height: 360px;
  opacity: 1;
  background: #3e4f77;
  border-radius: 4px;
  border-color: #3e4f77;
  color: #fff;
}

/deep/ .el-card__body {
  width: 100%;
  height: 100%;
  padding: 0;
}

.card-top {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  line-height: 25px;
  letter-spacing: 1px;
  padding-top: 40px;
}

.card-bottom {
  display: flex;

}

.card-text {
  padding: 52px 410px 0 60px;
  font-size: 18px;
  letter-spacing: 1px;
}

.card-btn {
  /*display: flex;*/
  /*justify-content: flex-end;*/
  /*padding-right: 141px;*/
  padding-top: 40px;
}

.btn {
  width: 128px;
  height: 49px;
  opacity: 1;
  border-color: #0364cd;
  background: #0364cd;
  border-radius: 7px;
  margin-left: 0;
}
.el-button--primary:focus, .el-button--primary:hover{
  border-color: #046cdc;
  background: #046cdc;
}
/deep/.toolbar-color{
  background: #3e4f77;
  /*color: #FFFFFF;*/
}
/deep/.cell-color{
  background: #3e4f77;
  color: #FFFFFF;
}
/deep/.vxe-table--header-wrapper,/deep/.vxe-table--body-wrapper{
  background-color: transparent!important;
}


/deep/.vxe-table--body-wrapper::-webkit-scrollbar{
  width: 7px;
  height: 7px;
}
/deep/.vxe-table--body-wrapper::-webkit-scrollbar:vertical{
  display: none;
}
/deep/.vxe-table--body-wrapper::-webkit-scrollbar-thumb{
  background-color: #5C6974 !important;
  border-radius: 20px !important;
}
/deep/.vxe-table--body-wrapper::-webkit-scrollbar-track{
  border-radius: 2px;
}
</style>
<style>
/*日期选择器里的日期和时间显示*/
.date-style > div > div > div > span > div .el-input__inner {
  /*padding: 0;*/
  color: #333333 !important;
  border: 1px solid #dcdfe6 !important;
}

.date-style > div > div > div > span > div .el-input__inner:hover {
  border: 1px solid #c8cad0 !important;
}

.date-style > div > div > div > span > div .el-input__inner:focus {
  border: 1px solid #849eec !important;
}
</style>
