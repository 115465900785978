import Vue from "vue";
import router from "@/router";
// import store from "@/store";

/**
 * 获取uuid
 */
export function getUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
    return (c === "x" ? (Math.random() * 16) | 0 : "r&0x3" | "0x8").toString(
      16
    );
  });
}

/**
 * 是否有权限
 * @param {*} key
 */
export function isAuth(key) {
  return true;
  // return JSON.parse(sessionStorage.getItem('permissions') || '[]').indexOf(key) !== -1 || false
}
export function isHttp(url) {
  let Rex = /(http|https):\/\/([\w.]+\/?)\S*/;
  if (!Rex.test(url)) {
    url = "http://" + url;
  }
  return url;
}
/**
 * 树形数据转换
 * @param {*} data
 * @param {*} id
 * @param {*} pid
 */
export function treeDataTranslate(data, id = "id", pid = "parentId") {
  var res = [];
  var temp = {};
  for (var i = 0; i < data.length; i++) {
    temp[data[i][id]] = data[i];
  }
  for (var k = 0; k < data.length; k++) {
    if (temp[data[k][pid]] && data[k][id] !== data[k][pid]) {
      if (!temp[data[k][pid]]["children"]) {
        temp[data[k][pid]]["children"] = [];
      }
      if (!temp[data[k][pid]]["_level"]) {
        temp[data[k][pid]]["_level"] = 1;
      }
      data[k]["_level"] = temp[data[k][pid]]._level + 1;
      temp[data[k][pid]]["children"].push(data[k]);
    } else {
      res.push(data[k]);
    }
  }
  return res;
}

/**
 * 清除登录信息
 */
export function clearLoginInfo() {
  console.log("清除登录信息");
  localStorage.removeItem('token');
  localStorage.removeItem('comId');
  localStorage.removeItem('userId');
  localStorage.removeItem('createType');
  localStorage.removeItem('expire');
  localStorage.removeItem('username');
  localStorage.removeItem('id')
  // store.commit("resetStore");
  router.options.isAddDynamicMenuRoutes = false;
}
//换肤加class函数
export function toggleClass(element, className) {
  if (!element || !className) {
    return;
  }
  element.className = className;
}
