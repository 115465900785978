// import { createRouter,createWebHistory } from 'vue-router'
import Login from '../views/Login'
import Home from '../views/Home'
import Router from 'vue-router'
import Vue from "vue"
import {clearLoginInfo} from "@/utils";
// import {routerMap} from "../../../../../Android/fire-admin2/src/router";
// const routerHistory = createWebHistory();

Vue.use(Router)
const routes = [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/login',
        // name: 'Login',
        component: Login
    },
    {
        path: '/home',
        // name: 'Layout',
        component: Home
    }

];
// const router = createRouter({
//     history: routerHistory,
//     routes
// })

const router = new Router({
    mode: 'hash',
    // scrollBehavior: () => ({y: 0}),
    routes: routes
})
const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
    return originalPush.call(this, location).catch(err => err)
}
router.beforeEach(((to, from, next) => {

    if (to.path == '/login' && from.path == '/home'){
        clearLoginInfo();
    }

    next();
}))
export default router;
