<template>
  <el-container class="main-container login">
    <div class="left-box">
      <img class="login-img1" src="../images/login-img1.png" alt="">
      <img class="login-img2" src="../images/login-img2.png" alt="">
    </div>
    <div class="right-box">
      <div class="login-box">
        <div class="text">
          <div class="zh-text">智慧消防管理云平台</div>
          <div class="en-text">Intelligent fire management cloud platform</div>
        </div>
        <div class="box">
          <div class="box-top">账号登录</div>
          <div class="box-bottom">
            <el-form ref="form" :model="form">
              <el-form-item >
                <el-input v-model="form.username"  placeholder="账号/手机号码" >
                  <template #prefix>
                    <img src="../images/user.png" alt="" class="input-user">
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item >
                <el-input v-model="form.password" placeholder="密码" type="password">
                  <template #prefix>
                    <img src="../images/lock.png" alt="" class="input-user">
                  </template>
                </el-input>
              </el-form-item>
<!--              <el-form-item >-->
<!--                <el-input v-model="form.captcha" class="check" placeholder="验证码">-->
<!--                  <template #prefix>-->
<!--                    <img src="../images/code.png" alt="" class="input-user">-->
<!--                  </template>-->
<!--                </el-input>-->
<!--&lt;!&ndash;                <div class="checkImg"></div>&ndash;&gt;-->
<!--                <img  :src="captchaPath" @click="getCaptcha()" class="checkImg" />-->
<!--              </el-form-item>-->
              <el-form-item >
                <el-button class="login-btn" @click="handleClick" @@keyup.13="show($event)">登录</el-button>
              </el-form-item>
            </el-form>

          </div>
          <div class="box-top-img"></div>
          <div class="box-bottom-img"></div>
        </div>
      </div>
    </div>
  </el-container>
</template>

<script>

import { apis } from "@/api/urls"
import { axiosServer } from "@/api/judge"
import { getUUID } from '@/utils';
import baseUrl from '@/api/baseUrl'
import {Message} from 'element-ui'
import bus from "@/utils/eventBus/bus";
export default {
  name: "Login",
  data(){
    return{
      form:{
        username: '',
        password: '',
        check: '',
        uuid: null,
        // 区分登录渠道？ 不会顶掉其他系统的账号
        pid: 4
      },

      param:{},
      captchaPath:"",
      stopClick: false
    }
  },
  created(){
    console.log('开启事件监听')
    this.getCaptcha()
    document.addEventListener('keydown', this.show);
  },
  destroyed() {
    console.log('移除事件监听')
    document.removeEventListener('keydown', this.show)
  },
  methods:{
    // 获取验证码
    getCaptcha() {
      this.form.uuid = getUUID();
      let path = process.env.NODE_ENV !== 'production' ? '/proxyApi' : baseUrl.BASE_API
      this.captchaPath = `${path}/captcha.jpg?uuid=${this.form.uuid}`
      // this.captchaPath = `${baseUrl.BASE_API}/captcha.jpg?uuid=${this.form.uuid}`
    },
    // 节流点击
    handleClick(){
      if (this.stopClick){
        return ;
      }
      this.stopClick = true;
      this.login();
      let timeout = setTimeout(()=>{
        // 两秒后可以点击 并清除上一个定时器
        this.stopClick = false;
        clearTimeout(timeout);
      },2000)
    },
    login(){
      if (this.form.username === ''){
        Message({
          message: '请输入账号/手机号',
          type: 'error'
        });
        return;
      }
      if (this.form.password === ''){
        Message({
          message: '请输入密码',
          type: 'error'
        });
        return;
      }
      axiosServer({
        url: apis.userLogin,
        type: 'post',
        params: this.form
      })
        .then((res) => {
          console.log('userLogin:', res)
          if(res.data.code === 0){
            Message({
              message: '登录成功',
              type: 'success'
            });
            localStorage.token = res.data.token;
            localStorage.comId = res.data.comId;
            localStorage.userId = res.data.userId;
            localStorage.createType = res.data.createType;
            localStorage.expire = res.data.expire;
            localStorage.resetPwd = res.data.resetPwd;
            localStorage.username = this.form.username;
            this.$router.push('/home')
            bus.$emit('tonePlay', () => {
              // 非静音状态下播放
              if (this.isShow){
                this.play();
              }
            })
          } else{
            Message({
              message: res.data.msg,
              type: 'error'
            });
             this.getCaptcha()
          }
        })
        .catch((e) => {
          this.getCaptcha()
          console.log(e)
        })

    },
    show(e){
      let keyNum = window.event ? e.keyCode : e.which;
      if (keyNum == 13){
        this.handleClick();
      }
    }
  }
}
</script>

<style scoped>
.login{
  width: 100vw;
  height: 100vh;
  background: #101C42;
  position: relative;
  display: flex;
  /*flex-direction: column;*/
  z-index: 0;
}
.login:after{
  content: '';
  width: 100%;
  height: 263px;
  /*height: 15%;*/
  background-image: url("../images/login-top2.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.login:before{
  content: '';
  width: 100%;
  /*height: 15%;*/
  height: 250px;
  background-image: url("../images/login-bottom2.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.left-box{
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
}

.login-img1{
  position: absolute;
  margin-bottom: 83px;
  left: 61px;
}

.login-img2{
  margin-left: 302px;
}

.right-box{
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}


.login-box{
  width: 587px;
  height: 587px;
  margin-right: 150px;
}
.text{
  width: 100%;
  height: 63px;
  margin-bottom: 63px;
  color: #5BA3ED;
  text-align: center;
  font-weight: bold;
  box-sizing: border-box;
}
.zh-text{
  font-size: 32px;
  letter-spacing: 18px;
  padding-left: 14px;
  z-index: 2;
}
.en-text{
  font-size: 20px;
}
.box{
  width: 85%;
  /*height: 430px;*/
  border: 1px solid #5BA3ED;
  margin: 0 auto ;
  position: relative;
}
.box-top{
  width: 100%;
  height: 100px;
  color: #fff;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.box-bottom{
  display: flex;
  justify-content: center;
}
.el-form{
  width: 80%;
}
.el-form-item{
  margin-bottom: 18px;
}
.el-input{
  border: 1px solid #5BA3ED;
  font-size: 14px;
  background: #002146;
}
.input-user{
  width: 20px;
  height: 20px;
}
.check {
  width: 255px;
  margin-right: 30px;
}
.input-check{
  float: left;
}
/deep/.el-input__prefix{
  top: 5px;
}
.el-button{
  border-radius: 0;
  color: #fff;
}

/*.code-btn{*/
/*  border-color: #0364CD;*/
/*  background: #0364CD;*/
/*  width: 112px;*/
/*  height: 40px;*/
/*}*/
/*.code-btn-click{*/
/*  border-color: #7f7f7f;*/
/*  background: #7f7f7f;*/
/*}*/
/*.code-btn:hover{*/
/*  border-color: #00499a;*/
/*  background: #00499a;*/
/*}*/
.login-btn{
  width: 100%;
  height: 100%;
  margin-top: 25px;
  margin-bottom: 25px;
  border-color: #0091FF;
  background: #0091FF;
}
.login-btn:hover{
  border-color: #0071c7;
  background: #0071c7;
}
.box-top-img:after{
  content: '';
  width: 50px;
  height: 50px;
  background: url("../images/login-top-left.png") no-repeat;
  position: absolute;
  top: -1px;
  left: -1px;
  z-index: 1;
}
.box-top-img:before{
  content: '';
  width: 50px;
  height: 50px;
  background: url("../images/login-top-right.png") no-repeat;
  position: absolute;
  top:-1px;
  left: 452px;
  z-index: 1;
}
.box-bottom-img:after{
  content: '';
  width: 50px;
  height: 50px;
  background: url("../images/login-bottom-left.png") no-repeat;
  position: absolute;
  top: 280px;
  left: -1px;
  z-index: 1;
}
.box-bottom-img:before{
  content: '';
  width: 50px;
  height: 50px;
  background: url("../images/login-bottom-right.png") no-repeat;
  position: absolute;
  top: 280px;
  left: 452px;
  z-index: 1;
}
.checkImg{
  /*display: inline-block;*/
  width: 115px;
  height: 40px;
  line-height: 40px;
}
/deep/.el-form-item .el-form-item__content{
  display: flex;
}
@media screen and (max-width: 1600px)  {
  .left-box{
    display: none;
  }
  .right-box{
    width: 100%;
    justify-content: center;
  }
  .login-box{

    margin-right: 0;
  }
}

</style>
