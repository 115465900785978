<!--  设备统计  -->
<template>
  <div class="right-state">
<!--    <el-scrollbar :style="{height: (scrollHeight +'px !important')}"  style="overflow-x: hidden !important;" class="my-scrollbar">-->
      <el-container class="shining-bg" style="overflow-x: hidden !important;">
        <el-row class="comm-bg2 right-content">
          <div class="comm-title-container">设备统计</div>
          <div class="content">
            <stat-device></stat-device>
          </div>
        </el-row>
      </el-container>

      <el-container class="shining-bg" style="overflow-x: hidden !important;">
        <el-row class="comm-bg2 right-content content2">
          <div class="comm-title-container">监管单位统计</div>
          <div class="content">
            <stat-supervision-unit></stat-supervision-unit>
          </div>
        </el-row>
      </el-container>

      <el-container class="shining-bg" style="margin-bottom:50px;overflow-x: hidden !important;">
        <el-row class="comm-bg2 right-content content3">
          <div class="comm-title-container">设备类型统计</div>
          <div class="content">
            <stat-device-type :device-type="deviceType"></stat-device-type>
          </div>
        </el-row>
      </el-container>
<!--    </el-scrollbar>-->
  </div>
</template>

<script>
import StatDevice from './components/StatDevice'
import StatSupervisionUnit from './components/StatSupervisionUnit'
import StatDeviceType from './components/StatDeviceType'
import {getRegionCount, getRegionCountComUser, getRegionSysCount} from "@/api/impl/device";
import bus from '@/utils/eventBus/bus'
import {axiosServer} from "@/api/judge";
export default {
  name: 'RightStat',
  components: {
    StatDevice, StatSupervisionUnit, StatDeviceType
  },
  data() {
    return {
      // 设备统计
      region:{id: 0, actualRegionLevel: 0},
      comId: 0,
      createType: 0,
      deviceNum: {total: 0, normal: 0, warning: 0, fault: 0, sleep: 0, offline: 0, unknown: 0},
      // 设备类型统计
      deviceType: {
        all: 0,
        charts: [],
        nameArr: [],
        numArr: []
      },
      interval: null
    }
  },
  methods:{
    // 获取统计信息
    deviceStatisticalData(){
      if (localStorage.getItem('createType') == 1){
        getRegionSysCount({ regionId: this.region.id, regionLevel:  this.region.actualRegionLevel }).then(res => {
          console.log('getRegionSysCount:', res)
          if (res.code == 0){
            res.list.forEach(item => {
              this.deviceType.all += item.count;
              // this.deviceType.charts[item.sysName] = item.count;
              this.deviceType.charts.push({name: item.sysName, num: item.count});
            })
            this.deviceType.charts.sort((a, b) => {
              return b.num - a.num;
            })
            this.deviceType.charts.map(item => {
              this.deviceType.numArr.push(item.num)
              this.deviceType.nameArr.push(item.name)
            })
          }

        })
        // 获取设备数量接口
        getRegionCount({regionId: this.region.id, regionLevel: this.region.actualRegionLevel}).then(res => {
          console.log('getRegionCount:',res)
          if (res.code == 0){
            res.list.forEach(item => {
              this.deviceNum.total += item.count;
              if (item.status == 1) this.deviceNum.normal = item.count;
              else if (item.status == 2) this.deviceNum.offline = item.count;
              else if (item.status == 3) this.deviceNum.fault = item.count;
              else if (item.status == 4) this.deviceNum.warning = item.count;
              else if (item.status == 5) this.deviceNum.sleep = item.count;
              else if (item.status == 0) this.deviceNum.unknown = item.count;
            })
            this.$store.commit('SET_DEVICE_NUM', this.deviceNum);
          }

        })
      }else {
        getRegionCountComUser({comId: localStorage.getItem('comId')}).then(res => {
          console.log('getRegionCountComUser:',res)
          if (res.code == 0){
            res.list.forEach(item => {
              this.deviceNum.total += item.count;
              if (item.status == 1) this.deviceNum.normal = item.count;
              else if (item.status == 2) this.deviceNum.offline = item.count;
              else if (item.status == 3) this.deviceNum.fault = item.count;
              else if (item.status == 4) this.deviceNum.warning = item.count;
              else if (item.status == 5) this.deviceNum.sleep = item.count;
            })
          }
          this.$store.commit('SET_DEVICE_NUM', this.deviceNum);

        })


      }
    },

    // 获取监管单位统计
    monitorStatisticalData(){

    },

  },
  created() {
    // this.comId = localStorage.getItem('comId')
    // this.createType = localStorage.getItem('createType')
    this.deviceStatisticalData();
    this.$store.dispatch('GET_MONITOR_DATA', localStorage.getItem('userId'))
    // 清空数组
    // this.deviceNum.total = 0;
    // this.deviceType.charts = [];
    // this.deviceType.nameArr = [];
    // this.deviceType.numArr = [];

    this.interval = setInterval(()=>{
      console.log('res', this.deviceNum)
      this.deviceNum.total = 0;
      this.deviceType.charts = [];
      this.deviceType.nameArr = [];
      this.deviceType.numArr = [];
      this.deviceStatisticalData();
      this.$store.dispatch('GET_MONITOR_DATA', localStorage.getItem('userId'))
    },  15 * 60 * 1000)
  },
  mounted() {
    this.scrollHeight = document.documentElement.clientHeight - 70
    window.onresize = () => {
      this.scrollHeight = document.documentElement.clientHeight - 70
      console.log("height " + this.scrollHeight)
    }
    // bus.$emit('setDeviceNum', this.deviceNum);
  },
  watch:{
  },

}
</script>

<style scoped>
.right-state{
  width: 25%;
  height: calc(100% - 70px);
  /*margin-top: 70px;*/
  background: transparent;
  position: absolute;
  bottom: 0;
  right: 0;
  /*background: #000;*/
  overflow: auto;
  z-index: 10;

}
.right-state::-webkit-scrollbar{
  width: 0;
  height: 0;
}
/deep/.el-scrollbar__wrap{
  overflow-x: hidden;
  overflow-y: auto;
  margin-right: 0!important;

}

/*1*/
.shining-bg {
  background:  url("../../../../images/comm-shining.png") no-repeat !important;
  background-position: -30px -40px !important;
}
.comm-bg2 {
  width: 478px;
  opacity: 1;
  background: #1b2c52;
  border: 1px solid #5ba3ed;
  border-radius: 8px;
}
.right-content {
  width: 96% !important;
  margin-top: 15px !important;
  margin-left: 2% !important;
  margin-right: 2% !important;
  float: left;
}
.content {
  width: 100%;
  text-align: left;
  padding: 23px;
  overflow-x: hidden;
}


</style>
