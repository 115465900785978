import Vue from 'vue'
import Vuex from 'vuex'
import getters from "@/store/getters";
import user from '@/store/modules/user';
import device from '@/store/modules/device';
import table from '@/store/modules/table';
Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        user,
        device,
        table
    },
    getters
})
export default store
