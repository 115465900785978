<template>
  <el-dialog
      :visible.sync="show"
      width="565px"
      custom-class="setting"
      :close-on-click-modal="false"

      :before-close="close"
  >
    <div class="setting-text">
      安全退出
    </div>
    <div class="logout-text">
      确定进行[退出]操作?
    </div>
    <div class="setting-btn">
      <el-button type="primary" @click="cancel">取消</el-button>
      <el-button type="primary" @click="confirm">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>

import {clearLoginInfo} from "@/utils";

export default {
  name: "LogoutDialog",
  props:{
    show: Boolean,
  },
  data() {
    return {
      // 弹框显示隐藏初始化
      dialogVisible: false,
      // 表单初始化
      phoneForm: {
        name: null,
        imgUrl: null,
      }
    }
  },

  methods: {

    // 关闭按钮
    close() {
      this.$emit('changeShow', 6)
    },

    // 取消按钮
    cancel() {
      // 弹框隐藏
      this.$emit('changeShow', 6)
    },

    // 确定按钮
    confirm() {
      // 清除localstorage
      // localStorage.removeItem('token');
      // localStorage.removeItem('comId');
      // localStorage.removeItem('userId');
      // localStorage.removeItem('createType');
      // localStorage.removeItem('expire');
      clearLoginInfo();
      // proxy.$root.$router.push('login')
      this.$router.push("login")
      // 弹框隐藏
      this.$emit('changeShow', 6)
    },
  }
}
</script>

<style scoped>
.el-dialog__wrapper{
  width: 100vw;
  height: 100vh;

}
/deep/.el-dialog{
  margin:0 !important;
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  height: 250px;
}
/deep/.el-dialog, .el-pager li{
  background: #17264c;
}
/* 顶部文字区域 */
.setting-text {
  padding-top: 40px;
  text-align: center;
  font-size: 18px;
  color: #fff;
}

.logout-text {
  padding-top: 40px;
  text-align: center;
  font-size: 18px;
  color: #fff;
}

/* 按钮区域 */
.setting-btn {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.setting-btn > .el-button:nth-child(1) {
  width: 128px;
  height: 47px;
  opacity: 1;
  border-color: #3e4f77;
  background: #3e4f77;
  border-radius: 4px;
}

.setting-btn > .el-button:nth-child(2) {
  width: 128px;
  height: 47px;
  opacity: 1;
  border-color: #0364cd;
  background: #0364cd;
  border-radius: 4px;
  margin-left: 50px;
}
</style>
<style>
.logout {
  height: 250px;
  border-radius: 7px;
  background: #17264C;
  border: 1px solid #5BA3ED4D;
  margin-top: 0vh !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logout > div:nth-child(1) {
  padding: 0;
}

.logout > div:nth-child(1) .el-dialog__headerbtn {
  font-size: 22px;
}

.logout > div:nth-child(2) {
  padding: 0;
}

.logout > div:nth-child(3) {
  padding: 0;
}

/*弹窗自定义 动画*/
.el-dialog__wrapper {
  transition-duration: 0s;
}

.dialog-fade-enter-active {
  animation: none !important;
}

.dialog-fade-leave-active {
  /*定义 退出时的 动画 过渡时间*/
  transition-duration: 0s !important;
  animation: none !important;
}

.dialog-fade-enter-active .el-dialog,
.dialog-fade-leave-active .el-dialog {
  /*设置 填充模式   此处的值为 当动画完成后，保持最后一个属性值（在最后一个关键帧中定义）*/
  animation-fill-mode: none;
}

.dialog-fade-enter-active .el-dialog {
  /* 进入自定义动画*/
  animation-name: none;
}

.dialog-fade-leave-active .el-dialog {
  /* 退出 自定义动画*/
  animation-name: none;
}

</style>
