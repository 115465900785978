// warn
export const recordColumn = [
    {
        name: 'jcsj',
        prop: 'jcsj',
        label: '告警时间',
        headerAlign: 'left',
        align: 'left',
        width: 130,
    },
    {
        name: 'regionName',
        prop: 'regionName',
        label: '告警单位',
        headerAlign: 'center',
        align: 'center',
    },
    {
        name: 'sensorName',
        prop: 'sensorName',
        label: '告警传感',
        headerAlign: 'center',
        align: 'center'
    },
    {
        name: 'statusValue',
        prop: 'statusValue',
        label: '告警类型',
        headerAlign: 'right',
        align: 'right',
        width: 110
    },
]

export const recordCellStyle = function (){
    return {
        background: "#17264c",
        color: "#ffffff",
        border: "none",
        fontSize: "18px",
        padding: "5px 0 5px 0"
    };
}

export const recordHeadStyle = function (){
    return {
        //rgba(130,36,36,0.91)
        background: "#17264c",
        color: "#ffffff",
        fontSize: "18px",
        paddingTop: "0",
        borderBottom: '1px solid #979797'
    };
}
