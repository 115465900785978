import { axiosServer } from "../judge";
import { method } from "../method";
import {parserResult} from '../parser'
import { apis } from "../urls";

export function getRegionAlarm(params) {
  return axiosServer({
    url: apis.regionAlarm,
    type: method.GET,
    params: params
  })
    .then(resp => {
      return Promise.resolve(parserResult(resp));
    })
    .catch(e => {
      return Promise.reject(e);
    });
}
export function getUserAlarmCount() {
  return axiosServer({
    url: apis.alarmUserCount,
    type: method.GET
  })
    .then(resp => {
      return Promise.resolve(parserResult(resp));
    })
    .catch(e => {
      return Promise.reject(e);
    });
}

/**
 * 关闭告警
 */
export function closeAlarm(param) {
  return axiosServer({
    url: apis.alarmClose,
    type: method.POST,
    params: param
  })
    .then(resp => {
      return Promise.resolve(parserResult(resp));
    })
    .catch(e => {
      return Promise.reject(e);
    });
}

/**
 * 通过设备获取所有的告警信息
 * @param param  {deviceId:"",page:1,pageSize:10}
 */
export function getAlarm(param) {
  return axiosServer({
    url: apis.alarmDevice,
    type: method.GET,
    params: param
  })
    .then(resp => {
      return Promise.resolve(parserResult(resp));
    })
    .catch(e => {
      return Promise.reject(e);
    });
}
export function getAlarm2(param) {
  return axiosServer({
    url: apis.alarmDevice2,
    type: method.GET,
    params: param
  })
      .then(resp => {
        return Promise.resolve(parserResult(resp));
      })
      .catch(e => {
        return Promise.reject(e);
      });
}
/**
 * 获取用户的告警信息
 * @param param
 */
export function getUserAlarm(param) {
  return axiosServer({
    url: apis.alarmUser,
    type: method.GET,
    params: param
  })
    .then(resp => {
      return Promise.resolve(parserResult(resp));
    })
    .catch(e => {
      return Promise.reject(e);
    });
}
/**
 * 获取告警/故障原因的分页
 * @param param
 */
export function getAlarmCause(param) {
  return axiosServer({
    url: apis.alarmCause,
    type: method.GET,
    params: param
  })
    .then(resp => {
      return Promise.resolve(parserResult(resp));
    })
    .catch(e => {
      return Promise.reject(e);
    });
}
/**
 * 获取告警/故障原因的分页
 * @param param
 */
export function saveAlarmCause(param) {
  return axiosServer({
    url: apis.alarmCause,
    type: method.POST,
    params: param
  })
    .then(resp => {
      return Promise.resolve(parserResult(resp));
    })
    .catch(e => {
      return Promise.reject(e);
    });
}
/**
 * 删除告警/故障原因
 * @param param
 */
export function delAlarmCause(param) {
  return axiosServer({
    url: apis.alarmCause,
    type: method.DELETE,
    params: param
  })
    .then(resp => {
      return Promise.resolve(parserResult(resp));
    })
    .catch(e => {
      return Promise.reject(e);
    });
}
/**
 * 审核告警
 * @param param
 */
export function verifyAlarm(param) {
  return axiosServer({
    url: apis.verifyAlarm,
    type: method.POST,
    params: param
  })
    .then(resp => {
      return Promise.resolve(parserResult(resp));
    })
    .catch(e => {
      return Promise.reject(e);
    });
}

// export function deleteAlarm(param) {
//   return axiosServer({
//     url: apis.alarmUser,
//     type: method.DELETE,
//     params: param
//   }).then(resp => {
//     return Promise.resolve(parserResult(resp))
//   }).catch(e => {
//     return Promise.reject(e)
//   })
// }
/**
 * 获取告警详情
 * @param param
 */
export function getAlarmDetail(param) {
  return axiosServer({
    url: "/alarm/detail",
    type: method.GET,
    params: param
  })
      .then(resp => {
        return Promise.resolve(parserResult(resp));
      })
      .catch(e => {
        return Promise.reject(e);
      });
}
