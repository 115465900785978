import Vue from 'vue'
import XEUtils from 'xe-utils'

import {
    // 核心
    VXETable,

    // 功能模块
    // Icon,
    // Filter,
    // Menu,
    // Edit,
    // Export,
    // Keyboard,
    // Validator,
    Header,
    // Footer,

    // 可选组件
    Column,
    // Colgroup,
    // Grid,
    // Toolbar,
    // Pager,
    // Checkbox,
    // CheckboxGroup,
    // Radio,
    // RadioGroup,
    // RadioButton,
    // Input,
    // Textarea,
    // Button,
    // Modal,
    // Tooltip,
    // Form,
    // FormItem,
    // FormGather,
    // Select,
    // Optgroup,
    // Option,
    // Switch,
    // List,
    // Pulldown,

    // 表格
    Table
} from 'vxe-table'


const vxeTable = {
    install: function (Vue){
        Vue.use(VXETable)
        Vue.use(Table)
        Vue.use(Header)
        Vue.use(Column)
    },
    VXETable: VXETable
}



export default vxeTable
